<template>
    <div class="contact-bar">
        <div class="contact-bar__main">
            <div class="contact-bar__part contact-bar__part--message">
                <div class="contact-bar__bubble">
                    <p class="contact-bar__bubble-text" v-html="$t('TEXTS.contact-bubble-text')"></p>
                </div>
            </div>
            <div class="contact-bar__part contact-bar__part--contact">
                <div class="contact-bar__contact">
                    <h3 class="contact-bar__name">{{LANGUAGE === 'cs' ? 'David Martínek' : 'Denisa Malá'}}</h3>
                    <h4 class="contact-bar__department">{{$t('LABELS.sales-department')}}</h4>
                    <div class="contact-bar__contact-row">
                        <svg class="contact-bar__contact-icon">
                            <use href="/assets/imgs/icons/sprite.svg#email-2"></use>
                        </svg>
                        <a class="contact-bar__link" :href="`mailto:${$globalContact.cruises[LANGUAGE].email}`">{{$globalContact.cruises[LANGUAGE].email}}</a>
                    </div>
                    <div class="contact-bar__contact-row">
                        <svg class="contact-bar__contact-icon">
                            <use href="/assets/imgs/icons/sprite.svg#tel"></use>
                        </svg>
                        <a class="contact-bar__link" :href="`tel:${$globalContact.cruises[LANGUAGE].phone}`">{{ $globalContact.cruises[LANGUAGE].phone }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { LANGUAGE } from '@/env'

export default {
    data() {
        return {
            LANGUAGE: LANGUAGE,
        }
    }
}
</script>