<template>
    <Head>
        <title v-if='type === "yacht"'>
            YACHTA'S
        </title>
        <title v-else>
            COSTA Cruise's
        </title>
    </Head>
    <header class="header-detail" ref="header-detail">
        <Main-nav :type="type"></Main-nav>
    </header>
    <main :class="`main main--${type} login-form__container`">
        <Notification :text="error.text[LANGUAGE]" v-show="error.show" :initConfig="{small: true}" @closed="closeNotification" :type="error.type"></Notification>
        <form class="login-form" @submit.prevent="recoverPassword">
            <h1 :class="`login-form__heading login-form__heading--${type}`">{{ $t('LABELS.forgotten-password') }}</h1>
            <div class="login-form__input-container">
                <label for="email" class="login-form__label">{{ $t('LABELS.email') }}</label>
                <input type="email" name="email" id="email" class="login-form__input" required v-model="email">
            </div>
            <input type="submit" value="Obnovit heslo" class="login-form__submit-btn btn btn--full-width btn--orange">
            <div class="login-form__links">
                <router-link to="/prihlaseni/" class="login-form__link">{{ $t('LABELS.you-login') }}</router-link>
                <router-link to="/registrace/" class="login-form__link">{{ $t('LABELS.you-register') }}</router-link>
            </div>
        </form>
    </main>
    <Footer></Footer>
    <Loading :is-loading="isLoading" :label="$t('STATUSES.sending-instruction-email')"></Loading>
</template>

<script>
import Footer from '../components/Footer';
import MainNav from '../components/MainNav';
import { Api } from '../modules/Api'
import Notification from '../components/Notification.vue'
import Loading from '../components/Loading.vue';

import { Head } from '@vueuse/head'

import { TYPE, LANGUAGE } from '@/env'

export default {
    components: {
        Footer,
        MainNav,
        Notification,
        Loading,
        Head
    },
    data() {
        return {
            isLoading: false,
            api: new Api(this.$t),
            LANGUAGE: LANGUAGE,
            email: '',
            error: {
                show: false,
                type: '',
                text: {
                    cs: [],
                    sk: []
                }
            },
            type: TYPE === 'yachtas' ? 'yacht' : 'cruiser'
        }
    },
    methods: {
        async recoverPassword() {
            this.closeNotification();

            if (this.email === '') {
                this.error.show = true;
                this.error.type = 'error';
                this.error.text = {
                    cs: [this.$t('ERRORS.fill-required')],
                    sk: [this.$t('ERRORS.fill-required')]
                }
                return false;
            }

            this.isLoading = true;
            const status = await this.api.recoverPassword(this.email);
            this.isLoading = false;

            if (status) {
                this.error.show = true;
                this.error.type = 'success';
                this.error.text = {
                    cs: [this.$t('STATUSES.instructions-sent')],
                    sk: [this.$t('STATUSES.instructions-sent')]
                }

                this.email = '';
                return true;
            } else {
                this.error.show = true;
                this.error.type = 'error';
                this.error.text = {
                    cs: [this.$t('ERRORS.cannot-recover-password')],
                    sk: [this.$t('ERRORS.cannot-recover-password')]
                }

                return false;
            }
        },
        closeNotification() {
            this.error.show = false;
            this.error.type = '';
            this.error.text = {
                cs: [],
                sk: []
            }
        }
    },
    async mounted() {
        if (this.$store.user.isLogged) {
            this.$router.push('/uzivatel/');
            return;
        }
    },
    computed: {
        
    },
    watch: {
        
    }
};
</script>