<template>
    <Head>
        <title>{{ $t('TITLES.content-page', {pageName: $t('LABELS.yachtas-coffee') }) }}</title>
        <meta name="description" :content="$t('META-DESCRIPTIONS.content-page', {pageName: $t('LABELS.yachtas-coffee') })" />
    </Head>
    <header :style="{display: 'flex', flexDirection: 'column', minHeight: '100vh'}">
        <Main-nav></Main-nav>
        <Content-hero
            :style="{flex: 1}"
            background-img-url="/assets/imgs/content-headers/yachtas-coffee.jpg"
            :title="$t('LABELS.yachtas-coffee')"
            :sub-title="$t('LABELS.stylish-yacht-coffee-shop')"
        />
        <Content-under-hero :text="$t('TEXTS.content-yachtas-coffee-under-hero')" />
    </header>
    
    <main class="main content-pages__main">
        <ContentHalves
            :title="$t('LABELS.selling-point-cc-and-yachtas')"
            :text="[
                $t('TEXTS.yachtas-coffee-detail-1'),
                $t('TEXTS.yachtas-coffee-detail-2'),
                $t('TEXTS.yachtas-coffee-detail-3'),
            ]"
            :note-text="$t('TEXTS.yachtas-coffee-note')"
            image-side="right"
            widerText
        >
            <NewVideoYT type="yachtas" yt-url="https://www.youtube-nocookie.com/embed/oLqdE1CrWvs" preview-url="/assets/imgs/posters/yachtas-coffee.jpg" class="content-pages__yachtas-coffee-video" />
        </ContentHalves>
    </main>
    <YachtasCoffeeGallery />
    <MapContact class="content-pages__yachtas-coffee-contact" />
    <ContactBar></ContactBar>
    <Footer></Footer>
</template>
<script>
import { Head } from '@vueuse/head'
import MainNav from '../../components/MainNav';
import ContentHero from '../../components/ContentHero';
import ContentUnderHero from '../../components/ContentUnderHero';
import ContentHalves from '../../components/ContentHalves';
import ContactBar from '../../components/ContactBar';
import Footer from '../../components/Footer';
import { TYPE, LANGUAGE } from '@/env';
import NewVideoYT from '../../components/NewVideoYT.vue';
import YachtasCoffeeGallery from '../../components/YachtasCoffeeGallery.vue';
import MapContact from '../../components/MapContact.vue';

export default {
    components: {
    Head,
    MainNav,
    ContentHero,
    ContentUnderHero,
    Footer,
    ContentHalves,
    ContactBar,
    NewVideoYT,
    YachtasCoffeeGallery,
    MapContact
},
    data() {
        return {
            LANGUAGE: LANGUAGE,
            TYPE: TYPE,
            cabinsDescription: [
                this.$t('TEXTS.about-cabin-text-1'),
                this.$t('TEXTS.about-cabin-text-2'),
                this.$t('TEXTS.about-cabin-text-3'),
                `${this.$t('TEXTS.about-cabin-text-4')}<br>
                ${this.$t('LABELS.inner-cabin')}<br>
                ${this.$t('LABELS.sea-view-cabin')}<br>
                ${this.$t('LABELS.terrace-cabin')}<br>
                ${this.$t('LABELS.apartman-cabin')}<br>
                ${this.$t('LABELS.luxury-apartman-cabin')}`
            ]
        }
    }
}
</script>