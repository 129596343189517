<template>
    <div class="search-filters__paging" v-show="maxPage > 1">
        <button
            class="search-filters__page-btn search-filters__page-btn--cruiser search-filters__page-btn--long"
            :class="{ 'search-filters__page-btn--disabled': currentPage === 1 }"
            v-show="maxPage > 1"
            @click="prevPage"
        >{{ $t('LABELS.prev') }}</button>
        <div v-if="maxPage <= 5">
            <button
                class="search-filters__page-btn search-filters__page-btn--cruiser"
                v-for="i in maxPage"
                :class="{ 'search-filters__page-btn--current': i === currentPage }"
                :key="`page-${i}`"
                @click="changePage(i)"
            >{{ i }}</button>
        </div>
        <div v-else>
            <div v-for="(pageIndex, i) in shortenPages" :key="`page-${i}`" class="search-filters__page-btn-container">
                <button
                    class="search-filters__page-btn search-filters__page-btn--cruiser"
                    :class="{ 'search-filters__page-btn--current': pageIndex === currentPage }"
                    @click="changePage(pageIndex)"
                >{{ pageIndex }}</button>
                <p class="search-filters__page-dots" v-show="i < shortenPages.length - 1 && (shortenPages[i + 1] - 1 !== pageIndex)">...</p>
            </div>
        </div>
        <button
            class="search-filters__page-btn search-filters__page-btn--cruiser search-filters__page-btn--long"
            :class="{ 'search-filters__page-btn--disabled': currentPage === maxPage }"
            @click="nextPage"
        >{{ $t('LABELS.next') }}</button>
    </div>
</template>

<script>
export default {
    props: [
        'maxPage',
        'currentPage',
    ],
    data() {
        return {
        }
    },
    methods: {
        changePage(i) {
            this.$emit('changedPage', i)
        },
        nextPage() {
            if (this.currentPage + 1 <= this.maxPage) {
                this.changePage(this.currentPage + 1);
            }
        },
        prevPage() {
            if (this.currentPage - 1 > 0) {
                this.changePage(this.currentPage - 1);
            }
        },
    },
    computed: {
        shortenPages() {
            const pages = [1];
            
            if (this.currentPage === 1) {
                pages.push(2);
            } else if (this.currentPage === this.maxPage) {
                pages.push(this.maxPage - 1);
            } else {
                if (this.currentPage > 2) {
                    pages.push(this.currentPage - 1);
                }

                pages.push(this.currentPage);

                if (this.currentPage < this.maxPage - 1) {
                    pages.push(this.currentPage + 1);
                }
            }

            pages.push(this.maxPage)


            return pages;
        }
    }
}
</script>