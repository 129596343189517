<template>
    <div class="video-player">
        <video :src="videoUrl" :poster="posterUrl" class="video-player__video" ref="video" :controls="wasPlayed"></video>
        <button class="video-player__play-btn" @click="play" v-if="!wasPlayed">
            <svg class="video-player__play-icon">
                <use href="/assets/imgs/icons/sprite.svg#play"></use>
            </svg>
        </button>
    </div>
</template>

<script>
export default {
    props: ['videoUrl', 'posterUrl'],
    data() {
        return {
            wasPlayed: false
        }
    },
    methods: {
        play() {
            this.wasPlayed = true
            this.$refs.video.play()
        }
    }
}
</script>