<template>
    <Head>
        <title>{{ $t('TITLES.content-page', {pageName: $t('LABELS.costa-cruises') }) }}</title>
        <meta name="description" :content="$t('META-DESCRIPTIONS.content-page', {pageName: $t('LABELS.costa-cruises') })" />
    </Head>
    <header :style="{display: 'flex', flexDirection: 'column', minHeight: '100vh'}">
        <Main-nav></Main-nav>
        <Content-hero
            :style="{flex: 1}"
            background-img-url="/assets/imgs/content-headers/costa-cruises.jpg"
            :title="$t('LABELS.costa-cruises')"
            :sub-title="$t('LABELS.believe-your-eyes')"
        />
        <Content-under-hero :text="$t('TEXTS.content-costa-cruises-under-hero')" />
    </header>
    
    <NewVideoYT type="cruises" yt-url="https://www.youtube-nocookie.com/embed/bTpNji7OJiM" preview-url="/assets/imgs/content/costa-ship.jpg" class="costa-cruises-content__video" />
    
    <main class="main content-pages__main content-pages__main--costa-cruises">
        <ContentHalves
            :title="$t('LABELS.70-years-of-tradition')"
            :text="[
                $t('TEXTS.costa-cruises-tradition-1'),
                $t('TEXTS.costa-cruises-tradition-2'),
                $t('TEXTS.costa-cruises-tradition-3'),
            ]"
            :button-text="$t('LABELS.order-cruise')"
            button-url="/vyhledat/"
            image-side="left"
            waves-location="bottom-left-inner"
            bigger-btn
            widerText
        >
            <div class="costa-cruises-content__gallery">
                <img src="/assets/imgs/content/da5c339a-7abc-4198-ac66-b30f46e47841 1.jpg" class="costa-cruises-content__gallery-main-img" />
                <img src="/assets/imgs/content/40e8f807-bd51-4993-9e42-c6a8a28188f4 1.jpg" class="costa-cruises-content__gallery-second-img costa-cruises-content__gallery-second-img--smaller" />
            </div>
        </ContentHalves>
        <ContentHalves
            :title="$t('LABELS.costa-cruises-today')"
            :text="[
                $t('TEXTS.costa-cruises-now-1'),
                $t('TEXTS.costa-cruises-now-2'),
                $t('TEXTS.costa-cruises-now-3'),
            ]"
            :button-text="$t('LABELS.order-cruise')"
            button-url="/vyhledat/"
            image-side="right"
            waves-location="lower-left"
            bigger-btn
            widerText
        >
        <div class="costa-cruises-content__gallery">
            <img src="/assets/imgs/content/VE_Balcony 1.jpg" class="costa-cruises-content__gallery-main-img" />
            <img src="/assets/imgs/content/9a14d42a-d448-4381-9d49-cfac9bfdfcb4 1.jpg" class="costa-cruises-content__gallery-second-img costa-cruises-content__gallery-second-img--larger" />
        </div>
        </ContentHalves>
    </main>
    <ContactBar></ContactBar>
    <Footer></Footer>
</template>
<script>
import { Head } from '@vueuse/head'
import MainNav from '../../components/MainNav';
import ContentHero from '../../components/ContentHero';
import ContentUnderHero from '../../components/ContentUnderHero';
import ContentHalves from '../../components/ContentHalves';
import ContactBar from '../../components/ContactBar';
import Footer from '../../components/Footer';
import { TYPE, LANGUAGE } from '@/env';
import NewVideoYT from '../../components/NewVideoYT.vue';

export default {
    components: {
        Head,
        MainNav,
        ContentHero,
        ContentUnderHero,
        Footer,
        ContentHalves,
        ContactBar,
        NewVideoYT,
    },
    data() {
        return {
            LANGUAGE: LANGUAGE,
            TYPE: TYPE,
            cabinsDescription: [
                this.$t('TEXTS.about-cabin-text-1'),
                this.$t('TEXTS.about-cabin-text-2'),
                this.$t('TEXTS.about-cabin-text-3'),
                `${this.$t('TEXTS.about-cabin-text-4')}<br>
                ${this.$t('LABELS.inner-cabin')}<br>
                ${this.$t('LABELS.sea-view-cabin')}<br>
                ${this.$t('LABELS.terrace-cabin')}<br>
                ${this.$t('LABELS.apartman-cabin')}<br>
                ${this.$t('LABELS.luxury-apartman-cabin')}`
            ]
        }
    }
}
</script>