<template>
    <div class="itinerary">
        <h2 class="itinerary__heading">{{ $t('LABELS.itinerary') }}</h2>
        <table class="itinerary__table">
            <thead>
                <tr class="itinerary__table--header">
                    <th class="itinerary__table--heading">
                        {{ $t('LABELS.day') }}
                    </th>
                    <th class="itinerary__table--heading">
                        {{ $t('LABELS.description') }}
                    </th>
                    <th class="itinerary__table--heading">
                        {{ $t('LABELS.arrival-time') }}
                    </th>
                    <th class="itinerary__table--heading">
                        {{ $t('LABELS.departure-time') }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="entry in sortedItinerary"
                    :key="entry.id"
                    class="itinerary__table--row"
                >
                    <td class="itinerary__table--cell">{{ entry.day }}</td>
                    <td
                        class="
                            itinerary__table--cell
                            itinerary__table--cell--description
                        "
                    >
                        {{ entry.description[LANGUAGE] }}
                    </td>
                    <td class="itinerary__table--cell">
                        {{ formatTime(entry.arrival_time) }}
                    </td>
                    <td class="itinerary__table--cell">
                        {{ formatTime(entry.departure_time) }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { LANGUAGE } from '@/env';

export default {
    props: {
        itinerary: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            LANGUAGE,
        };
    },
    methods: {
        formatTime(time) {
            if (!time) return '';
            const date = new Date(time.date);
            return date.toLocaleTimeString(
                LANGUAGE === 'cs' ? 'cs-CZ' : 'sk-SK',
                {
                    hour: '2-digit',
                    minute: '2-digit',
                }
            );
        },
    },
    computed: {
        sortedItinerary() {
            return [...this.itinerary].sort((a, b) => {
                // Sort by day first
                if (a.day !== b.day) {
                    return a.day - b.day;
                }

                // If days are the same, sort by arrival time (null values last)
                if (a.arrival_time && b.arrival_time) {
                    const arrivalComparison =
                        new Date(a.arrival_time.date) -
                        new Date(b.arrival_time.date);
                    if (arrivalComparison !== 0) {
                        return arrivalComparison;
                    }
                } else if (a.arrival_time) {
                    return -1;
                } else if (b.arrival_time) {
                    return 1;
                }

                // If arrival times are the same, sort by departure time (null values last)
                if (a.departure_time && b.departure_time) {
                    return (
                        new Date(a.departure_time.date) -
                        new Date(b.departure_time.date)
                    );
                } else if (a.departure_time) {
                    return -1;
                } else if (b.departure_time) {
                    return 1;
                }

                return 0; // If all are equal, maintain original order
            });
        },
    },
};
</script>
