<template>
    <Head>
        <title>COSTA Cruise's</title>
    </Head>

    <header class='header-detail'>
        <Main-nav></Main-nav>
    </header>
    <main class='main reservation'>
        <div class='reservation__inner'>
            <Reservation-nav :currentLevel='3'></Reservation-nav>
            <div class='reservation__content reservation__content--summary'>
                <h2 class='reservation__heading'>{{ $t('LABELS.order-summary') }}</h2>
                <div class='summary'>
                    <ReservationSide
                        class='summary__summary-side'
                        :destination='overview.destination'
                        :departurePort='overview.departurePortName[LANGUAGE]'
                        :departureDate='overview.departureDate'
                        :arrivalDate='overview.arrivalDate'
                        :duration='overview.duration'
                        :itinerary='overview.itinerary[LANGUAGE]'
                        :adultsNum='5'
                        :childrenNum='5'
                        :crewSummary='true'
                        :fullPrice='overview.fullPrice[$store.currency]'
                        :additionalCost='overview.additionalCost[$store.currency]'
                        :summaryList='overview.summaryList'
                        :summaryPage='true'
                        :cruiserName='workflow.cruise?.ship_name[LANGUAGE]'
                        :portFee='workflow.portFee?.[$store.currency]'
                    ></ReservationSide>
                    <div class='summary__part summary__part--cabin'>
                        <div class='summary__mini-part'>
                            <h3 class='summary__heading-3'>{{ $t('LABELS.travellers-count') }}</h3>
                            <h4 class='summary__heading-4'>{{ workflow.guest_count_adult }}x
                                {{ $t('LABELS.adult-with-age') }}</h4>
                            <h4 v-if='workflow.guest_count_child > 0' class='summary__heading-4'>
                                {{ workflow.guest_count_child }}x {{ $t('LABELS.child-with-age') }}</h4>
                        </div>
                        <hr class='summary__hr'>
                        <div class='summary__mini-part'>
                            <h3 class='summary__heading-3'>{{ $t('LABELS.accomodation-package') }}</h3>
                            <div class='summary__wysiwyg' v-html='packageNote'>
                            </div>
                        </div>
                        <hr class='summary__hr' v-if='flights[workflow.flight]'>
                        <div class='summary__mini-part' v-if='flights[workflow.flight]'>
                            <h3 class='summary__heading-3'>{{ $t('LABELS.transport') }}</h3>
                            <h4 class='summary__heading-4'>{{ $t('LABELS.air-ticket') }}</h4>
                            <p class='summary__text'>
                                {{ $t('TEXTS.air-ticket-note-with-city', { city: flights[workflow.flight] }) }}
                            </p>
                        </div>
                        <hr class='summary__hr show-tablet'>
                        <div class='summary__mini-part summary__mini-part--cabin'>
                            <h3 class='summary__heading-3'>{{ $t('LABELS.cabin') }}</h3>
                            <img :src='overview.cabinImageUrl' class='summary__cabin-img'>
                            <p class='summary__sub-heading'>{{ overview.cabinLocation[LANGUAGE] }}</p>
                            <h4 class='summary__heading-4'>{{ overview.cabinName[LANGUAGE] }}</h4>
                            <p class='summary__cabin-info'>{{ $t('LABELS.cabin-type') }}:
                                <strong>{{ workflow.category_code?.split(';')[0] }}</strong></p>
                            <p class='summary__cabin-info'>{{ $t('LABELS.cabin-number') }}:
                                <strong>{{ workflow.cabin_number }}</strong></p>
                            <p class='summary__cabin-info'>{{ $t('LABELS.accomodation-package') }}:
                                <strong>{{ workflow.fare_code }}</strong></p>
                        </div>
                    </div>
                    <div class='summary__part'>
                        <router-link :to='`/rezervace/${overview.cruiseCode}/platba/`'
                                     class='btn btn--full-width btn--orange'>{{ $t('LABELS.to-payment-and-reservation')
                            }}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <Footer></Footer>
    <Loading :is-loading='isLoading' :showBar="true" :progress="loadingProgress" :label="loadingMessages[loadingMessageIndex]"></Loading>
</template>

<script>
import camelCase from 'camelcase';

import Footer from '../../components/Footer';
import MainNav from '../../components/MainNav';
import ReservationNav from '../../components/ReservationNav.vue';
import ReservationSide from '../../components/ReservationSide.vue';
import formatPriceUtil from '../../utilities/formatPrice';
import { Api } from '../../modules/Api';
import Loading from '../../components/Loading.vue';

import { Head } from '@vueuse/head';

import { LANGUAGE } from '@/env';

import * as _ from 'lodash';

import cabinsImages from '@/storage/cabins-imgs.json';

export default {
    data() {
        return {
            isLoading: false,
            api: new Api(this.$t),
            LANGUAGE: LANGUAGE,
            workflow: {},
            flights: {},
            overview: {
                cruiseCode: this.$route.params.cruise_code,
                destination: '',
                departurePortName: {},
                departureDate: '',
                arrivalDate: '',
                duration: 0,
                cabinName: { cs: '', sk: '' },
                cabinLocation: { cs: '', sk: '' },
                itinerary: {
                    cs: [],
                    sk: []
                },
                adultsNum: 0,
                childrenNum: 0,
                fullPrice: {
                    czk: {},
                    eur: {}
                },
                summaryList: [],
                additionalCost: {
                    czk: {},
                    eur: {}
                }
            },
            packageNote: '',
            cabinImageUrl: '',
            loadingMessages: [
                this.$t('STATUSES.loading-order-summary-1'),
                this.$t('STATUSES.loading-order-summary-2'),
                this.$t('STATUSES.loading-order-summary-3')
            ],
            loadingMessageIndex: 0,
            loadingInterval: null,
            loadingProgress: 0
        };
    },
    methods: {
        formatPrice(price) {
            return formatPriceUtil(price);
        },
        async handleWorkflow() {
            const workflows = localStorage.getItem('workflows') ? JSON.parse(localStorage.getItem('workflows')) : {};
            const currentWorkflowId = workflows[this.overview.cruiseCode] ? workflows[this.overview.cruiseCode] : this.$router.push({ path: `/rezervace/${this.overview.cruiseCode}/kajuta-a-ubytovaci-balicek/` });

            this.overview.workflowId = currentWorkflowId;
        },
        async loadSummary() {
            await this.api.updateBooking(this.overview.workflowId, {
                currency: this.$store.currency,
                supplementList: []
            })
            const { workflow, cruise, flights } = await this.api.getSummary(this.overview.workflowId);

            if (!workflow || !cruise) {
                this.$router.push({
                    path: `/rezervace/${this.overview.cruiseCode}/kajuta-a-ubytovaci-balicek/`,
                    hash: '#generic-error'
                });
                return;
            }

            this.workflow = workflow;
            this.flights = flights;

            Object.keys(cruise).map(key => {
                if (key === 'days') {
                    this.overview.duration = cruise[key];
                } else {
                    this.overview[camelCase(key)] = cruise[key];
                }
            });
        },
        async loadPackageNote() {
            this.packageNote = (await this.api.getSnippet(`${this.workflow.fare_code?.toLowerCase()}-info`))[LANGUAGE];
        },
        async handleHoldCabin() {
            let resp = await this.api.holdCabin(this.overview.workflowId);
            if (resp.data.error) {
                this.$router.push({
                    path: `/rezervace/${this.overview.cruiseCode}/kajuta-a-ubytovaci-balicek/`,
                    hash: '#generic-error'
                });
            }
        },
        async getCabinInfo() {

        },
        findCabinImageUrl() {
            const shipCode = this.workflow?.cruise.ship_code
            const cabinCode = this.workflow?.category_code;
            const cabin = _.find(this.workflow?.categories, ({ code }) => code === cabinCode);

            const imageList = cabinsImages[shipCode][cabinCode];
            const image = _.first(imageList)

            if(image) this.overview.cabinImageUrl = `/assets/imgs/cabins/${shipCode}/${cabinCode}/${image}`

            this.overview.cabinName = cabin?.name[LANGUAGE];
            this.overview.cabinLocation = cabin?.cabinLocation;
        },
        startLoading() {
            this.isLoading = true;
            this.loadingInterval = setInterval(() => {
                if (this.loadingProgress < 80) {
                    this.loadingProgress++;
                    this.loadingMessageIndex = Math.floor(this.loadingProgress / 27);
                }
            }, 200)
        },
        stopLoading() {
            clearInterval(this.loadingInterval);
            this.loadingInterval = null;
            this.loadingProgress = 100;

            setTimeout(() => {
                this.isLoading = false;
                this.loadingProgress = 0;
                this.loadingMessageIndex = 0;
            }, 250)
        }
    },
    components: {
        Footer,
        MainNav,
        ReservationNav,
        ReservationSide,
        Loading,
        Head
    },
    computed: {},
    async mounted() {
        this.startLoading();
        await this.handleWorkflow();
        await this.handleHoldCabin();
        await this.loadSummary();
        await this.loadPackageNote();
        this.stopLoading();

        this.findCabinImageUrl();
    }
};
</script>
