<template>
    <div class="contact-dash">
        <div class="contact-dash__part contact-dash__part--contact">
            <div class="contact-dash__person-container">
                <div class="contact-dash__img-container">
                    <img :src="LANGUAGE === 'cs' ? '/assets/imgs/content/david-martinek-profile.jpg' : '/assets/imgs/content/denisa-mala-profile.jpg'" class="contact-dash__img">
                </div>
                <div class="contact-dash__person-info">
                    <h3 class="contact-dash__name">{{LANGUAGE === 'cs' ? 'David Martínek' : 'Denisa Malá'}}</h3>
                    <h4 class="contact-dash__department">{{$t('LABELS.sales-department')}}</h4>
                </div>
            </div>
            <div class="contact-dash__contact-row">
                <svg class="contact-dash__contact-icon">
                    <use href="/assets/imgs/icons/sprite.svg#email-2"></use>
                </svg>
                <a class="contact-dash__link" :href="`mailto:${$globalContact.cruises[LANGUAGE].email}`">{{$globalContact.cruises[LANGUAGE].email}}</a>
            </div>
            <div class="contact-dash__contact-row">
                <svg class="contact-dash__contact-icon">
                    <use href="/assets/imgs/icons/sprite.svg#tel"></use>
                </svg>
                <a class="contact-dash__link" :href="`tel:${$globalContact.cruises[LANGUAGE].phone}`">{{ $globalContact.cruises[LANGUAGE].phone }}</a>
            </div>
            <img src="/assets/imgs/waves.png" class="contact-dash__waves">
        </div>
        <div class="contact-dash__part contact-dash__part--text">
            <div class="contact-dash__info">
                <h3 class="contact-dash__title">{{$t('LABELS.contact-us')}}</h3>
                <p class="contact-dash__info-text">{{$t('TEXTS.contact-us-text')}}</p>
            </div>
        </div>
    </div>
</template>
<script>
import { LANGUAGE } from '@/env'

export default {
    data() {
        return {
            LANGUAGE: LANGUAGE,
        }
    }
}
</script>