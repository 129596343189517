<template>
    <Head>
        <title v-if='type === "yacht"'>
            YACHTA'S
        </title>
        <title v-else>
            COSTA Cruise's
        </title>
    </Head>
    <div :class='{"fullwindow": fullWindow}'>
        <header class="header-detail" ref="header-detail">
            <Main-nav :type="type"></Main-nav>
        </header>
        <main :class="`main main--${type} login-form__container`">
            <Notification :text="error.text[LANGUAGE]" :type="error.type" v-show="error.show" :initConfig="{small: true}" @closed="closeNotification"></Notification>
            <form class="login-form" @submit.prevent="loginUser">
                <h1 :class="`login-form__heading login-form__heading--${type}`">{{ $t('LABELS.login-to-account') }}</h1>
                <div class="login-form__input-container">
                    <label for="email" class="login-form__label">{{ $t('LABELS.email') }}</label>
                    <input type="email" name="email" id="email" class="login-form__input" required v-model="email">
                </div>
                <div class="login-form__input-container">
                    <label for="password" class="login-form__label">{{ $t('LABELS.password') }}</label>
                    <input type="password" name="password" id="password" class="login-form__input" required v-model="password">
                </div>
                <input type="submit" value="Přihlásit" class="login-form__submit-btn btn btn--full-width btn--orange">
                <div class="login-form__links">
                    <router-link v-if="!isPopup" to="/registrace/" class="login-form__link">{{ $t('LABELS.new-registration') }}</router-link>
                    <router-link to="/zapomenute-heslo/" class="login-form__link" :target="linkTarget">{{ $t('LABELS.fogotten-password-q') }}</router-link>
                </div>
            </form>
        </main>
        <Footer></Footer>
        <Loading :is-loading="isLoading" :label="$t('STATUSES.logging-in')"></Loading>
    </div>
</template>

<script>
import Footer from '../components/Footer';
import MainNav from '../components/MainNav';
import Notification from '../components/Notification.vue'
import randomToken from 'random-token'
import Loading from '../components/Loading.vue';

import { Head } from '@vueuse/head'

import { TYPE, LANGUAGE } from '@/env'

export default {
    components: {
        Footer,
        MainNav,
        Notification,
        Loading,
        Head
    },
    props: {
        fullWindow: Boolean,
        isPopup: {
            type: Boolean,
            default: false
        },
        extraMessage: String,
    },
    emits: [ 'logged' ],
    data() {
        return {
            isLoading: false,
            LANGUAGE: LANGUAGE,
            email: '',
            password: '',
            error: {
                show: false,
                type: 'erorr',
                text: {
                    cs: [],
                    sk: []
                }
            },
            type: TYPE === 'yachtas' ? 'yacht' : 'cruiser',
        }
    },
    methods: {
        async loginUser() {
            this.closeNotification()

            if (this.email === '' || this.password === '') {
                this.error.show = true;
                this.error.text = {
                    cs: [this.$t('ERRORS.fill-required')],
                    sk: [this.$t('ERRORS.fill-required')]
                }
                return false;
            }

            this.isLoading = true;
            await this.$store.user.login(this.email, this.password)

            if (!this.$store.user.isLogged) {
                this.error.show = true;
                this.error.text = {
                    cs: [this.$t('ERRORS.bad-email-or-password')],
                    sk: [this.$t('ERRORS.bad-email-or-password')]
                }
                this.password = '';
                this.isLoading = false;
                return false;
            }

            if (!this.$store.user.details.loginToken) {
                const token = randomToken(64);
                await this.$store.user.update({ login_token: token }, this.password);
            }
            this.isLoading = false;

            if (this.isPopup) {
                this.$emit('logged');
                return;
            }

            const afterLoginUrl = sessionStorage.getItem('yachtas-afterlogin-url')
            if (afterLoginUrl) {
                const [ url, timestamp ] = afterLoginUrl.split('|');

                if (url && timestamp) {
                    const currentTime = (new Date).getTime();
                    const timeDifference = currentTime - Number(timestamp);

                    // Afterlogin url was created in less than 30 minutes
                    if (timeDifference < 1800000) {
                        this.$router.push(url);
                        return true;
                    }
                }
            }

            this.$router.push('/uzivatel/')

        },
        closeNotification() {
            this.error.show = false;
            this.error.text = {
                cs: [],
                sk: []
            },
            this.error.type = 'error';
        },
        handleExtraMessage() {
            if (this.extraMessage) {
                this.error.text.cs = [this.extraMessage];
                this.error.text.sk = [this.extraMessage];
                this.error.type = 'note';
                this.error.show = true;
            }
        }
    },
    async mounted() {
        if (this.$store.user.isLogged) {
            if (this.isPopup) {
                this.$emit('logged');
            } else {
                this.$router.push('/uzivatel/');
                return;
            }
        }
        this.handleExtraMessage();
    },
    computed: {
        linkTarget() {
            return this.isPopup ? '_blank' : '_self';
        }
    },
    watch: {
    }
};
</script>