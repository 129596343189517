<template>
    <Head>
        <title>COSTA Cruise's</title>
    </Head>

    <header class='header-detail'>
        <Main-nav></Main-nav>
    </header>
    <main class='main reservation reservation--done'>
        <div class='reservation__inner'>
            <div class='popup popup--unpoped'>
                <h1 class='popup__heading popup__heading--yacht'>{{ $t('TEXTS.cruise-delegate-contanct-form-send-success') }}</h1>
                <router-link to='/plavby-s-delegatem/' class='popup__btn btn btn--orange btn--standalone'>
                    {{ $t('LABELS.to-cruise-search') }}
                </router-link>
            </div>
        </div>
    </main>
    <Footer></Footer>
    <Loading :is-loading='isLoading' :label="$t('STATUSES.loading-trip-info')"></Loading>
</template>

<script>
import Footer from '../../components/Footer';
import MainNav from '../../components/MainNav';
import Loading from '../../components/Loading.vue';

import { Head } from '@vueuse/head'

export default {
    components: {
        Footer,
        MainNav,
        Loading,
        Head
    },
    data() {

    },
    computed: {

    },
    methods: {

    },
    async mounted() {

    }
};
</script>