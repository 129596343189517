<template>
    <Head>
        <title>
            {{
                $t('TITLES.content-page', {
                    pageName: $t('LABELS.how-it-works'),
                })
            }}
        </title>
        <meta
            name="description"
            :content="
                $t('META-DESCRIPTIONS.content-page', {
                    pageName: $t('LABELS.how-it-works'),
                })
            "
        />
    </Head>
    <header
        :style="{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
        }"
    >
        <Main-nav></Main-nav>
        <Content-hero
            :style="{ flex: 1 }"
            background-img-url="/assets/imgs/content-headers/for-firms.jpg"
            :title="$t('LABELS.how-it-works')"
            :sub-title="$t('LABELS.info-about-msc-cruises')"
        />
        <Content-under-hero
            :text="$t('TEXTS.content-how-it-works-under-hero')"
        />
    </header>
    <main class="main content-pages__main">
        <div class="content-pages__how-it-works">
            <h2 class="content-pages__how-it-works__title">
                Proč a jak si objednat Plavbu s MSC Cruises
            </h2>
            <section>
                <h3 class="content-pages__how-it-works__subtitle">
                    <strong>Jednoduchá a Pohodlná Rezervace</strong>
                </h3>
                <p class="content-pages__how-it-works__text">
                    U nás máte možnost objednat svou plavbu s Costa Cruises tak,
                    jak vám to nejvíce vyhovuje. Máte na výběr mezi dvěma
                    jednoduchými způsoby rezervace, které vám umožní plánovat
                    svou dokonalou dovolenou na moři. Můžete objednat online a
                    zaplatit kartou anebo se spojit s našimi kolegy, kteří vám
                    rádi poradí a vše vysvětlí.
                </p>
            </section>
            <section>
                <h3 class="content-pages__how-it-works__subtitle">
                    <strong>Online Rezervace a Platební Možnosti</strong>
                </h3>
                <p class="content-pages__how-it-works__text">
                    Jsme jediní v české republice, u koho můžete plavbu objednat
                    jednoduše a rychle online z pohodlí domova. Dostupnost kajut
                    a všechny ceny na našem webu jsou aktuální. Nemusíte tak
                    vyplňovat poptávkový formulář a poté čekat, zda je nabízená
                    plavba stále volná nebo zda je opravdu za cenu, za kterou je
                    inzerována. Vaše rezervace je automaticky ihned po zaplacení
                    potvrzena a vy se již můžete těšit na plavbu. Další služby
                    je možné dokoupit i po vytvoření rezervace.
                </p>
            </section>
            <section>
                <h3 class="content-pages__how-it-works__subtitle">
                    <strong>Rezervace s expertem na plavby</strong>
                </h3>
                <p class="content-pages__how-it-works__text">
                    Máte speciální požadavky, nebo jste na výletní lodi ještě
                    nebyli a máte jakékoliv dotazy? Stačí nám zavolat nebo
                    napsat na e-mail. Probereme s vámi veškeré detaily a
                    vytvoříme nabídku podle vašich představ. Zároveň vám
                    vysvětlíme, jak vše funguje.
                </p>
            </section>
            <section>
                <h3 class="content-pages__how-it-works__subtitle">
                    <strong>Všechny Výhody Costa Cruises u Nás</strong>
                </h3>
                <p class="content-pages__how-it-works__text">
                    Jako autorizovaný zástupce Costa Cruises si k cenám
                    nepřidáváme žádné přirážky a nabízíme našim zákazníkům
                    všechny výhody, které tato společnost poskytuje. Můžete
                    využít všech výhod členství v Costa Clubu a získat tak až
                    20% slevu na vybrané plavby nebo upgrade kajuty zdarma. V
                    případě zájmu o členství C-Club nás kontaktujte. V případě
                    jakýchkoliv problémů nebo speciálních žádostí komunikujete
                    přímo s námi, takže nemusíte ztrácet čas čekáním na
                    infolince. A hlavně vše řešíte česky.
                </p>
            </section>
        </div>

        <div class="content-pages__how-it-works__tabs">
            <button
                class="content-pages__how-it-works__tabs-links"
                @click="openTab($event, 'cabins')"
            >
                {{ $t('LABELS.cabins') }}
            </button>
            <button
                class="content-pages__how-it-works__tabs-links"
                @click="openTab($event, 'food')"
            >
                {{ $t('LABELS.food') }}
            </button>
            <button
                class="content-pages__how-it-works__tabs-links"
                @click="openTab($event, 'transport-to-port')"
            >
                {{ $t('LABELS.transport-to-port') }}
            </button>
            <button
                class="content-pages__how-it-works__tabs-links"
                @click="openTab($event, 'what-to-bring')"
            >
                {{ $t('LABELS.what-to-bring') }}
            </button>
            <button
                class="content-pages__how-it-works__tabs-links"
                @click="openTab($event, 'luggage')"
            >
                {{ $t('LABELS.luggage') }}
            </button>
            <button
                class="content-pages__how-it-works__tabs-links"
                @click="openTab($event, 'restrictions')"
            >
                {{ $t('LABELS.restrictions') }}
            </button>
            <button
                class="content-pages__how-it-works__tabs-links"
                @click="openTab($event, 'fun-on-ship')"
            >
                {{ $t('LABELS.fun-on-ship') }}
            </button>
            <button
                class="content-pages__how-it-works__tabs-links"
                @click="openTab($event, 'excursions')"
            >
                {{ $t('LABELS.excursions') }}
            </button>
            <button
                class="content-pages__how-it-works__tabs-links"
                @click="openTab($event, 'portal-mycosta')"
            >
                {{ $t('LABELS.portal-mycosta') }}
            </button>
            <button
                class="content-pages__how-it-works__tabs-links"
                @click="openTab($event, 'app-costa')"
            >
                {{ $t('LABELS.app-costa') }}
            </button>
        </div>

        <!--<section class="content-pages__main--how-it-works">-->
        <section>
            <div id="cabins" class="content-pages__how-it-works__tabs-content">
                <ContentHalves
                    :title="$t('LABELS.cabins')"
                    :text="[
                        $t('TEXTS.about-cabin-text-1'),
                        $t('TEXTS.about-cabin-text-2'),
                        `${$t('TEXTS.about-cabin-text-3')}<br>
                    ${$t('LABELS.inner-cabin')}<br>
                    ${$t('LABELS.sea-view-cabin')}<br>
                    ${$t('LABELS.balcony-cabin')}<br>
                    ${$t('LABELS.terrace-cabin')}<br>
                    ${$t('LABELS.mini-apartman')}<br>
                    ${$t('LABELS.apartman-cabin')}<br>
                    ${$t('LABELS.luxury-apartman-cabin')}`,
                        $t('TEXTS.about-cabin-text-4'),
                        $t('TEXTS.about-cabin-text-5'),
                        $t('TEXTS.about-cabin-text-6'),
                        $t('TEXTS.about-cabin-text-7'),
                        $t('TEXTS.about-cabin-text-8'),
                    ]"
                    :button-text="$t('LABELS.order-cruise')"
                    button-url="/vyhledat/"
                    image-side="left"
                    waves-location="bottom-right"
                    bigger-btn
                    widerText
                >
                    <GridGallery
                        variant="1"
                        :imgsUrls="
                            [
                                'VE_Suite 1.jpg',
                                'DI_Balcony 2 1.jpg',
                                'VE_Bathroom 1.jpg',
                                'SM_Suite 5 1.jpg',
                                'DE_Balcony 2 2.jpg',
                                'SM_Suite 4 1.jpg',
                                'FI_Balcony 2 1.jpg',
                                'FO_Outside Window 1.jpg',
                                'DI_Suite 1.jpg',
                            ].map((file) => `/assets/imgs/content/${file}`)
                        "
                    ></GridGallery>
                </ContentHalves>
            </div>

            <div id="food" class="content-pages__how-it-works__tabs-content">
                <ContentHalves
                    :title="$t('LABELS.food')"
                    :text="[
                        $t('TEXTS.about-food-text-1'),
                        $t('TEXTS.about-food-text-2'),
                        $t('TEXTS.about-food-text-3'),
                        $t('TEXTS.about-food-text-4'),
                        $t('TEXTS.about-food-text-5'),
                        $t('TEXTS.about-food-text-6'),
                        $t('TEXTS.about-food-text-7'),
                        $t('TEXTS.about-food-text-8'),
                    ]"
                    imageUrl="/assets/imgs/content/d8d5f60d-7956-4f68-9228-5e737d825597 1.jpg"
                    :button-text="$t('LABELS.order-cruise')"
                    button-url="/vyhledat/"
                    bigger-btn
                    image-side="right"
                    waves-location="bottom-left"
                    widerText
                />
                <h2 class="content-pages__heading-2">
                    <b class="content-pages__bold">{{
                        $t('TEXTS.how-it-works-heading-1')
                    }}</b>
                </h2>
                <div
                    class="
                        features-dash__container features-dash__container--grid
                    "
                >
                    <FeaturesDash
                        :subHeading="$t('LABELS.catering-package')"
                        :heading="$t('LABELS.my-cruise')"
                        :features="[
                            $t('TEXTS.my-cruise-feature-1'),
                            $t('TEXTS.my-cruise-feature-2'),
                            $t('TEXTS.my-cruise-feature-3'),
                            $t('TEXTS.my-cruise-feature-4'),
                        ]"
                        type="info"
                    ></FeaturesDash>
                    <FeaturesDash
                        :subHeading="$t('LABELS.catering-package')"
                        :heading="$t('LABELS.all-inclusive')"
                        :features="[
                            $t('TEXTS.all-inclusive-feature-1'),
                            $t('TEXTS.all-inclusive-feature-2'),
                            $t('TEXTS.all-inclusive-feature-3'),
                            $t('TEXTS.all-inclusive-feature-4'),
                        ]"
                        type="info"
                    ></FeaturesDash>
                    <FeaturesDash
                        :subHeading="$t('LABELS.catering-package')"
                        :heading="$t('LABELS.super-all-inclusive')"
                        :features="[
                            $t('TEXTS.super-all-inclusive-feature-1'),
                            $t('TEXTS.super-all-inclusive-feature-2'),
                        ]"
                        type="info"
                    ></FeaturesDash>
                    <FeaturesDash
                        :subHeading="$t('LABELS.catering-package')"
                        :heading="$t('LABELS.my-drinks-plus')"
                        :features="[$t('TEXTS.my-drinks-plus-feature-1')]"
                        type="info"
                    ></FeaturesDash>
                    <FeaturesDash
                        :subHeading="$t('LABELS.catering-package')"
                        :heading="$t('LABELS.my-soft-drinks')"
                        :features="[$t('TEXTS.my-soft-drinks-feature-1')]"
                        type="info"
                        wavesLocation="bottom-left"
                    >
                    </FeaturesDash>
                    <FeaturesDash
                        :subHeading="$t('LABELS.catering-package')"
                        :heading="$t('LABELS.my-drinks-young')"
                        :features="[$t('TEXTS.my-drinks-young-feature-1')]"
                        type="info"
                    ></FeaturesDash>
                </div>
            </div>

            <div
                id="transport-to-port"
                class="content-pages__how-it-works__tabs-content"
            >
                <ContentHalves
                    :title="$t('LABELS.transport-to-port')"
                    :text="[
                        $t('TEXTS.transport-to-port-text-1'),
                        $t('TEXTS.transport-to-port-text-2'),
                        $t('TEXTS.transport-to-port-text-3'),
                        $t('TEXTS.transport-to-port-text-4'),
                    ]"
                    imageUrl="/assets/imgs/content/travel.webp"
                    :button-text="$t('LABELS.order-cruise')"
                    button-url="/vyhledat/"
                    bigger-btn
                    image-side="left"
                    waves-location="bottom-right"
                    widerText
                />
            </div>

            <div
                id="what-to-bring"
                class="content-pages__how-it-works__tabs-content"
            >
                <ContentHalves
                    :title="$t('LABELS.what-to-bring')"
                    :text="[
                        $t('TEXTS.what-to-bring-text-1'),
                        $t('TEXTS.what-to-bring-text-2'),
                        $t('TEXTS.what-to-bring-text-3'),
                    ]"
                    imageUrl="/assets/imgs/content/custom-transport.jpg"
                    :button-text="$t('LABELS.order-cruise')"
                    button-url="/vyhledat/"
                    bigger-btn
                    image-side="right"
                    waves-location="bottom-left"
                    widerText
                />
            </div>

            <div id="luggage" class="content-pages__how-it-works__tabs-content">
                <ContentHalves
                    :title="$t('LABELS.luggage')"
                    :text="[
                        $t('TEXTS.luggage-text-1'),
                        $t('TEXTS.luggage-text-2'),
                        $t('TEXTS.luggage-text-3'),
                        $t('TEXTS.luggage-text-4'),
                    ]"
                    imageUrl="/assets/imgs/content/luggage.webp"
                    :button-text="$t('LABELS.order-cruise')"
                    button-url="/vyhledat/"
                    bigger-btn
                    image-side="left"
                    waves-location="bottom-right"
                    widerText
                />
            </div>

            <div
                id="restrictions"
                class="content-pages__how-it-works__tabs-content"
            >
                <ContentHalves
                    :title="$t('LABELS.restrictions')"
                    :text="[
                        $t('TEXTS.restrictions-text-1'),
                        $t('TEXTS.restrictions-text-2'),
                        $t('TEXTS.restrictions-text-3'),
                        $t('TEXTS.restrictions-text-4'),
                    ]"
                    imageUrl="/assets/imgs/content/restrictions.webp"
                    :button-text="$t('LABELS.order-cruise')"
                    button-url="/vyhledat/"
                    bigger-btn
                    image-side="right"
                    waves-location="bottom-left"
                    widerText
                />
            </div>

            <div
                id="fun-on-ship"
                class="content-pages__how-it-works__tabs-content"
            >
                <ContentHalves
                    :title="$t('LABELS.fun-on-ship')"
                    :text="[
                        $t('TEXTS.fun-on-ship-text-1'),
                        $t('TEXTS.fun-on-ship-text-2'),
                        $t('TEXTS.fun-on-ship-text-3'),
                        $t('TEXTS.fun-on-ship-text-4'),
                        $t('TEXTS.fun-on-ship-text-5'),
                    ]"
                    :button-text="$t('LABELS.order-cruise')"
                    button-url="/vyhledat/"
                    bigger-btn
                    image-side="left"
                    waves-location="bottom-right"
                    widerText
                >
                    <GridGallery
                        variant="1"
                        :imgsUrls="
                            [
                                'individual-price.jpg',
                                'individual-price-2.jpg',
                                'dream-program-2.jpg',
                                'team-building.jpg',
                                '9a14d42a-d448-4381-9d49-cfac9bfdfcb4 1.jpg',
                                'dream-program.jpg',
                                'group-of-friends.jpg',
                                'DI_Suite 1.jpg',
                            ].map((file) => `/assets/imgs/content/${file}`)
                        "
                    >
                    </GridGallery>
                </ContentHalves>
            </div>

            <div
                id="excursions"
                class="content-pages__how-it-works__tabs-content"
            >
                <ContentHalves
                    :title="$t('LABELS.excursions')"
                    :text="[
                        $t('TEXTS.excursions-text-1'),
                        $t('TEXTS.excursions-text-2'),
                    ]"
                    imageUrl="/assets/imgs/content/port-excursions.jpg"
                    :button-text="$t('LABELS.order-cruise')"
                    button-url="/vyhledat/"
                    bigger-btn
                    image-side="right"
                    waves-location="bottom-left"
                    widerText
                />
            </div>

            <div
                id="portal-mycosta"
                class="content-pages__how-it-works__tabs-content"
            >
                <ContentHalves
                    :title="$t('LABELS.portal-mycosta')"
                    :text="[
                        $t('TEXTS.portal-mycosta-text-1'),
                        $t('TEXTS.portal-mycosta-text-2'),
                    ]"
                    imageUrl="/assets/imgs/content/Costa-App-login.png"
                    :button-text="$t('LABELS.order-cruise')"
                    button-url="/vyhledat/"
                    bigger-btn
                    image-side="left"
                    waves-location="bottom-right"
                    widerText
                />
            </div>

            <div
                id="app-costa"
                class="content-pages__how-it-works__tabs-content"
            >
                <ContentHalves
                    :title="$t('LABELS.app-costa')"
                    :text="[$t('TEXTS.app-costa-text-1')]"
                    imageUrl="/assets/imgs/content/Visual-Costa-App.webp"
                    :button-text="$t('LABELS.order-cruise')"
                    button-url="/vyhledat/"
                    bigger-btn
                    image-side="right"
                    waves-location="bottom-left"
                    widerText
                />
            </div>

            <h2 class="content-pages__heading-2">
                <b class="content-pages__bold">{{
                    $t('LABELS.next-information')
                }}</b>
            </h2>
            <div
                class="features-dash__container features-dash__container--grid"
            >
                <FeaturesDash
                    :heading="$t('LABELS.online-check-in')"
                    :features="[
                        $t('TEXTS.online-check-in-feature-1'),
                        $t('TEXTS.online-check-in-feature-2'),
                        $t('TEXTS.online-check-in-feature-3'),
                        $t('TEXTS.online-check-in-feature-4'),
                    ]"
                    type="info"
                ></FeaturesDash>
                <FeaturesDash
                    :heading="$t('LABELS.payments-on-ship')"
                    :features="[
                        $t('TEXTS.payments-on-ship-feature-1'),
                        $t('TEXTS.payments-on-ship-feature-2'),
                        $t('TEXTS.payments-on-ship-feature-3'),
                        $t('TEXTS.payments-on-ship-feature-4'),
                    ]"
                    type="info"
                ></FeaturesDash>
                <FeaturesDash
                    :heading="$t('LABELS.internet-connection')"
                    :features="[
                        $t('TEXTS.internet-connection-feature-1'),
                        $t('TEXTS.internet-connection-feature-2'),
                        $t('TEXTS.internet-connection-feature-3'),
                        $t('TEXTS.internet-connection-feature-4'),
                        $t('TEXTS.internet-connection-feature-5'),
                        $t('TEXTS.internet-connection-feature-6'),
                    ]"
                    type="info"
                ></FeaturesDash>
                <FeaturesDash
                    :heading="$t('LABELS.medical-care')"
                    :features="[
                        $t('TEXTS.medical-care-feature-1'),
                        $t('TEXTS.medical-care-feature-2'),
                        $t('TEXTS.medical-care-feature-3'),
                    ]"
                    type="info"
                ></FeaturesDash>
                <FeaturesDash
                    :heading="$t('LABELS.travel-documents')"
                    :features="[
                        $t('TEXTS.travel-documents-feature-1'),
                        $t('TEXTS.travel-documents-feature-2'),
                        $t('TEXTS.travel-documents-feature-3'),
                        $t('TEXTS.travel-documents-feature-4'),
                    ]"
                    type="info"
                ></FeaturesDash>
                <FeaturesDash
                    :heading="$t('LABELS.cancellation-terms')"
                    :features="[
                        $t('TEXTS.cancellation-note-5'),
                        $t('TEXTS.cancellation-note-6'),
                        $t('TEXTS.cancellation-note-7'),
                        $t('TEXTS.cancellation-note-8'),
                        $t('TEXTS.cancellation-note-9'),
                    ]"
                    type="info"
                ></FeaturesDash>
            </div>

            <div
                class="features-dash__container features-dash__container--grid"
            >
                <FeaturesDash
                    :subHeading="$t('LABELS.services-basic-price-includes')"
                    :heading="$t('LABELS.basic-price-includes')"
                    :features="[
                        $t('TEXTS.basic-price-includes-1'),
                        $t('TEXTS.basic-price-includes-2'),
                        $t('TEXTS.basic-price-includes-3'),
                        $t('TEXTS.basic-price-includes-4'),
                        $t('TEXTS.basic-price-includes-5'),
                        $t('TEXTS.basic-price-includes-6'),
                        $t('TEXTS.basic-price-includes-7'),
                        $t('TEXTS.basic-price-includes-8'),
                        $t('TEXTS.basic-price-includes-9'),
                    ]"
                    :footerText="$t('TEXTS.basic-price-includes-footer')"
                    type="includes"
                ></FeaturesDash>
                <FeaturesDash
                    :subHeading="$t('LABELS.additional-services')"
                    :heading="$t('LABELS.basic-price-excludes')"
                    :features="[
                        $t('TEXTS.basic-price-excludes-1'),
                        $t('TEXTS.basic-price-excludes-2'),
                        $t('TEXTS.basic-price-excludes-3'),
                        $t('TEXTS.basic-price-excludes-4'),
                        $t('TEXTS.basic-price-excludes-5'),
                        $t('TEXTS.basic-price-excludes-6'),
                        $t('TEXTS.basic-price-excludes-7'),
                        $t('TEXTS.basic-price-excludes-8'),
                        $t('TEXTS.basic-price-excludes-9'),
                        $t('TEXTS.basic-price-excludes-10'),
                    ]"
                    :footerText="
                        $t('TEXTS.basic-price-excludes-footer', {
                            email: `${$globalContact[TYPE][LANGUAGE].email}`,
                        })
                    "
                    type="additional"
                    wavesLocation="bottom-right"
                ></FeaturesDash>
            </div>

            <div
                class="features-dash__container features-dash__container--grid"
            >
                <FeaturesDash
                    :subHeading="$t('LABELS.cclub-features')"
                    :heading="$t('LABELS.cclub')"
                    :features="[
                        $t('TEXTS.cclub-features-1'),
                        $t('TEXTS.cclub-features-2'),
                        $t('TEXTS.cclub-features-3'),
                        $t('TEXTS.cclub-features-4'),
                        $t('TEXTS.cclub-features-5'),
                        $t('TEXTS.cclub-features-6'),
                    ]"
                    :footerText="
                        $t('TEXTS.cclub-features-footer', {
                            email: `${$globalContact[TYPE][LANGUAGE].email}`,
                        })
                    "
                    type="info"
                    size="fullwidth"
                ></FeaturesDash>
            </div>
        </section>
    </main>
    <ContactBar></ContactBar>
    <Footer></Footer>
</template>
<script>
import { Head } from '@vueuse/head';
import MainNav from '../../components/MainNav';
import ContentHero from '../../components/ContentHero';
import ContentUnderHero from '../../components/ContentUnderHero';
import ContentHalves from '../../components/ContentHalves';
import ContactBar from '../../components/ContactBar';
import GridGallery from '../../components/GridGallery';
import Footer from '../../components/Footer';
import FeaturesDash from '../../components/FeaturesDash.vue';
import { TYPE, LANGUAGE } from '@/env';

export default {
    components: {
        Head,
        MainNav,
        ContentHero,
        ContentUnderHero,
        Footer,
        ContentHalves,
        ContactBar,
        GridGallery,
        FeaturesDash,
    },
    data() {
        return {
            LANGUAGE: LANGUAGE,
            TYPE: TYPE,
            cabinsDescription: [
                this.$t('TEXTS.about-cabin-text-1'),
                this.$t('TEXTS.about-cabin-text-2'),
                this.$t('TEXTS.about-cabin-text-3'),
                `${this.$t('TEXTS.about-cabin-text-4')}<br>
                ${this.$t('LABELS.inner-cabin')}<br>
                ${this.$t('LABELS.sea-view-cabin')}<br>
                ${this.$t('LABELS.terrace-cabin')}<br>
                ${this.$t('LABELS.apartman-cabin')}<br>
                ${this.$t('LABELS.luxury-apartman-cabin')}`,
            ],
        };
    },
    methods: {
        openTab(evt, tabName) {
            // Hide all tab content by default
            const tabcontent = document.getElementsByClassName(
                'content-pages__how-it-works__tabs-content'
            );
            for (let i = 0; i < tabcontent.length; i++) {
                tabcontent[i].classList.remove('active');
            }

            // Remove the active class from all tab links
            const tablinks = document.getElementsByClassName(
                'content-pages__how-it-works__tabs-links'
            );
            for (let i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(
                    ' active',
                    ''
                );
            }

            // Show the current tab content and add the "active" class
            const currentTabContent = document.getElementById(tabName);
            if (currentTabContent) {
                currentTabContent.classList.add('active');
            }

            // If evt is not null, add the "active" class to the clicked tab link
            if (evt) {
                evt.currentTarget.className += ' active';
            } else {
                // If evt is null (e.g., on initial load), set the first tab as active
                tablinks[0].className += ' active';
            }
        },
    },
    mounted() {
        this.openTab(null, 'cabins');
    },
};
</script>
