<template>
    <div class="yachtas-coffee-gallery">
        <div class="yachtas-coffee-gallery__img-container" v-for="filename, i in galleryItems" :key="i">
            <img :src="`/assets/imgs/yachtas-coffee-gallery/${filename}`" class="yachtas-coffee-gallery__img" />
            <p :class="`yachtas-coffee-gallery__label yachtas-coffee-gallery__label--${specialItems[`i${i}`].type}`" v-if="specialItems[`i${i}`]">{{ specialItems[`i${i}`].text }}</p>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            galleryItems: [
                '_MG_8223 1.png',
                '_MG_8486.png',
                '_MG_8234-2 1.png',
                '_MG_8545.png',
                '_MG_8390 1.png',
                '_MG_8475 1.png',
                '_MG_8332 1.png',
                '_MG_8667.png',
                '_MG_8681.png',
                '_MG_8738.png',
                '_MG_8762.png',
                '_MG_9429.png',
                '_MG_8861 1.png',
                '_MG_8466.png',
                '_MG_8891.png',
                '_MG_8482 1.png',
                '_MG_9285.png',
                '_MG_8765.png',
                '_MG_8833.png',
                '_MG_8545.png',
                '_MG_8851.png',
                '_MG_8738.png',
                '_MG_8610.png',
                '_MG_8486.png',
            ],
            specialItems: {
                i1: {
                    type: 'yachtas',
                    text: this.$t('TEXTS.book-cruise-in-coffeeshop')
                },
                i6: {
                    type: 'cruises',
                    text: this.$t('LABELS.exotic-rums')
                },
                i12: {
                    type: 'yachtas',
                    text: this.$t('LABELS.quality-coffee')
                },
                i19: {
                    type: 'cruises',
                    text: this.$t('LABELS.yachting-lectures')
                },
            }
        }
    }
}
</script>