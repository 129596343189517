<template>
    <Head>
        <title>YACHTA'S</title>
    </Head>

    <header class='header-list header-list--yacht'>
        <Main-nav :fixed-allowed='true' type='yacht'></Main-nav>
    </header>
    <main class='main main--yacht'>
        <div class='search-filters search-filters--yacht'>
            <div class='search-filters__filters'>
                <section class='search-filters__main-filter'>
                    <nav class='search-filters__nav'>
                        <router-link to='/vyhledat/' class='search-filters__nav-item search-filters__nav-item--yacht'>
                            <svg class='search-filters__nav-icon search-filters__nav-icon--yacht'>
                                <use href='/assets/imgs/icons/sprite.svg#yacht'></use>
                            </svg>
                            <span class='search-filters__nav-name'>{{ $t('LABELS.yacht') }}</span>
                        </router-link>
                    </nav>
                    <main class='search-filters__filter-container search-filters__filter-container--yacht'>
                        <div class='search-filters__filter-wrapper'>
                            <svg
                                class='search-filters__filter-icon search-filters__filter-icon--yacht search-filters__filter-icon--pinpoint'>
                                <use href='/assets/imgs/icons/sprite.svg#pinpoint'></use>
                            </svg>
                            <v-select
                                class='search-filters__iconed-select search-filters__iconed-select--yacht'
                                :options='langCountries'
                                :reduce='country => country.code'
                                label='langName'
                                v-model='country'
                                @close='countryChanged'
                            ></v-select>
                        </div>
                        <div class='search-filters__filter-wrapper'>
                            <svg
                                class='search-filters__filter-icon search-filters__filter-icon--yacht search-filters__filter-icon--calendar'>
                                <use href='/assets/imgs/icons/sprite.svg#calendar'></use>
                            </svg>
                            <input
                                type='date'
                                id='start-date'
                                class='search-filters__input search-filters__input--clickable'
                                v-model='startDate'
                            >
                        </div>
                        <div class='search-filters__filter-wrapper'>
                            <svg
                                class='search-filters__filter-icon search-filters__filter-icon--yacht search-filters__filter-icon--calendar'>
                                <use href='/assets/imgs/icons/sprite.svg#calendar'></use>
                            </svg>
                            <input
                                type='date'
                                id='end-date'
                                class='search-filters__input search-filters__input--clickable'
                                v-model='endDate'
                            >
                        </div>
                        <div class='search-filters__filter-wrapper' v-click-outside='hideCrewPopup'
                             @click='showCrewPopup'>
                            <svg
                                class='search-filters__filter-icon search-filters__filter-icon--yacht search-filters__filter-icon--people'>
                                <use href='/assets/imgs/icons/sprite.svg#people'></use>
                            </svg>
                            <input type='text'
                                   class='search-filters__input search-filters__input--readonly search-filters__input--clickable'
                                   :value='`${adultsNum} ${adultsInflected}`' readonly>
                            <div class='search-filters__popup search-filters__popup--crew' v-show='isCrewPopupShown'>
                                <Counter :min='1' :max='adultsLimit' :initial='adultsNum' :new-val='newAdultsNum'
                                         :title="$t('LABELS.crew-members-count')" @changed='updateAdultsNum'></Counter>
                            </div>
                        </div>
                        <div class='search-filters__filter-wrapper'>
                            <svg
                                class='search-filters__filter-icon search-filters__filter-icon--yacht search-filters__filter-icon--sun'>
                                <use href='/assets/imgs/icons/sprite.svg#sun'></use>
                            </svg>
                            <v-select
                                class='search-filters__iconed-select search-filters__iconed-select--yacht'
                                :options='langYachtThemes'
                                :reduce='theme => theme.code'
                                label='langName'
                                v-model='themeCode'
                                @close='themeChanged'
                            ></v-select>
                        </div>
                        <!-- <div class="search-filters__filter-wrapper search-filters__filter-wrapper--self">
                            <input type="checkbox" class="search-filters__checkbox search-filters__checkbox--large" id="transport-included" v-model="transportIncluded">
                            <label class="search-filters__checkbox-label" for="transport-included">{{ $t('LABELS.includes-transport) }}</label>
                        </div> -->
                    </main>
                </section>
                <section
                    class='search-filters__optional-filters'
                    :class="{
                        'search-filters__optional-filters--show-advanced': showAdvanced,
                        'search-filters__optional-filters--shown': isShownMobileFiltering,
                    }"
                >
                    <div class='search-filters__modal-header search-filters__modal-header--yacht'>
                        <svg class='search-filters__modal-close-icon' @click='hideMobileFiltering'>
                            <use href='/assets/imgs/icons/sprite.svg#simple-arrow'></use>
                        </svg>
                        <p class='search-filters__modal-header-title'>{{ $t('LABELS.filter') }}</p>
                    </div>
                    <div class='search-filters__filter-container search-filters__filter-container--bordered'>
                        <h3 class='search-filters__heading search-filters__heading--yacht search-filters__heading--optional-filters'>
                            {{ $t('LABELS.ship-type') }}</h3>
                        <v-select
                            class='search-filters__select search-filters__select--yacht'
                            :options='langYachtTypes'
                            :reduce='type => type.code'
                            label='langName'
                            v-model='yachtType'
                        ></v-select>
                    </div>
                    <div class='search-filters__filter-container search-filters__filter-container--bordered'
                         v-if='false'>
                        <h3 class='search-filters__heading search-filters__heading--yacht search-filters__heading--optional-filters'>
                            {{ $t('LABELS.sail-duration') }}</h3>
                        <v-select
                            class='search-filters__select search-filters__select--yacht'
                            :options='langDaysMax'
                            :reduce='dayMax => dayMax.duration'
                            label='langName'
                            v-model='yachtDaysMax'
                        ></v-select>
                    </div>
                    <div class='search-filters__filter-container search-filters__filter-container--bordered'>
                        <h3 class='search-filters__heading search-filters__heading--yacht search-filters__heading--optional-filters search-filters__heading--price'>
                            {{ $t('LABELS.price-for-cabin') }}</h3>
                        <slider
                            v-model='price'
                            :min='0'
                            :max='priceLimit[$store.currency]'
                            :step='100'
                            :tooltips='false'
                            @change='priceChanged'
                        ></slider>
                        <div class='search-filters__notes search-filters__notes--yacht'>
                            <span class='search-filters__price search-filters__price--min'>{{ formatPrice(price[0]) }} {{ $store.currency === 'czk' ? 'Kč' : '€'
                                }}</span>
                            <span
                                class='search-filters__price search-filters__price--max'>{{ formatPrice(price[1]) }}{{ price[1] >= priceLimit[$store.currency] ? '+' : '' }} {{ $store.currency === 'czk' ? 'Kč' : '€'
                                }}</span>
                        </div>
                    </div>
                    <button
                        class='search-filters__sort-btn search-filters__sort-btn--filtering btn btn--blue btn--standalone'
                        @click='hideMobileFiltering'>{{ $t('LABELS.save') }}
                    </button>
                </section>
                <section class='search-filters__banners'>
                    <Banner
                        class='search-filters__banner'
                        v-for='banner in banners'
                        :key='banner'
                        :type='banner.desktop.type'
                        :source='banner.desktop.source'
                        :url='banner.url'
                    >
                    </Banner>
                </section>
            </div>
            <div class='search-filters__main'>
                <div
                    class='search-filters__sortings'
                    :class="{
                        'search-filters__sortings--show-advanced': showAdvanced,
                        'search-filters__sortings--shown': isShownMobileSorting,
                    }"
                >
                    <div class='search-filters__modal-header search-filters__modal-header--yacht'>
                        <svg class='search-filters__modal-close-icon' @click='hideMobileSorting'>
                            <use href='/assets/imgs/icons/sprite.svg#simple-arrow'></use>
                        </svg>
                        <p class='search-filters__modal-header-title'>{{ $t('LABELS.sort') }}</p>
                    </div>
                    <h3 class='search-filters__sort-heading search-filters__sort-heading--yacht'>{{ $t('LABELS.sort-by')
                        }}</h3>
                    <div class='search-filters__sorting-wrapper'>
                        <input type='radio' name='sort' id='soon' value='soon' class='search-filters__sorting-radio'
                               v-model='sortBy'>
                        <label for='soon'
                               class='search-filters__sorting-label search-filters__sorting-label--yacht'>{{ $t('LABELS.soonest-departure')
                            }}</label>
                    </div>
                    <div class='search-filters__sorting-wrapper'>
                        <input type='radio' name='sort' id='cheapest' value='cheapest'
                               class='search-filters__sorting-radio' v-model='sortBy'>
                        <label for='cheapest'
                               class='search-filters__sorting-label search-filters__sorting-label--yacht'>{{ $t('LABELS.from-cheapest')
                            }}</label>
                    </div>
                    <div class='search-filters__sorting-wrapper'>
                        <input type='radio' name='sort' id='shortest' value='shortest'
                               class='search-filters__sorting-radio' v-model='sortBy'>
                        <label for='shortest'
                               class='search-filters__sorting-label search-filters__sorting-label--yacht'>{{ $t('LABELS.shortest')
                            }}</label>
                    </div>
                    <div class='search-filters__sorting-wrapper'>
                        <input type='radio' name='sort' id='longest' value='longest'
                               class='search-filters__sorting-radio' v-model='sortBy'>
                        <label for='longest' class='search-filters__sorting-label search-filters__sorting-label--yacht'>{{ $t('LABELS.longest')
                            }}</label>
                    </div>
                    <hr class='search-filters__sort-hr'>
                    <button class='search-filters__sort-btn btn btn--blue btn--standalone' @click='hideMobileSorting'>
                        {{ $t('LABELS.save') }}
                    </button>
                </div>
                <div class='search-filters__show-advanced search-filters__show-advanced--large' @click='toggleAdvanced'>
                    <p class='search-filters__show-advanced-text'>
                        {{ showAdvanced ? $t('LABELS.hide') : $t('LABELS.show') }}
                        {{ $t('LABELS.advanced-filtering').toLowerCase() }}</p>
                </div>
                <div class='search-filters__show-advanced search-filters__show-advanced--small'>
                    <button class='search-filters__show-advanced-option' @click='showMobileSorting'>
                        <svg class='search-filters__show-advanced-icon search-filters__show-advanced-icon--sort'>
                            <use href='/assets/imgs/icons/sprite.svg#sort'></use>
                        </svg>
                        {{ $t('LABELS.sort') }}
                    </button>
                    <button class='search-filters__show-advanced-option' @click='showMobileFiltering'>
                        <svg class='search-filters__show-advanced-icon search-filters__show-advanced-icon--filter'>
                            <use href='/assets/imgs/icons/sprite.svg#filter'></use>
                        </svg>
                        {{ $t('LABELS.filter') }}
                    </button>
                </div>
                <div class='search-filters__content search-filters__content--yachts'>
                    <showcase-item
                        class='search-filters__item'
                        v-for='yacht in searchedYachts'
                        :key='yacht.code'
                        type='yacht'
                        :country='yacht.country.name[LANGUAGE]'
                        :free-units='yacht.freeUnits'
                        :car='yacht.transports?.car?.[LANGUAGE]'
                        :plane='yacht.transports?.plane?.[LANGUAGE]'
                        :duration='yacht.duration'
                        :transport-included='yacht.transportIncluded'
                        :image-url='yacht.image'
                        :departure-city='yacht.sea[LANGUAGE]'
                        :destination='yacht.destinationName[LANGUAGE]'
                        :departure-date='yacht.departureDate'
                        :departure-name='yacht.departureName[LANGUAGE]'
                        :arrival-name='yacht.arrivalName[LANGUAGE]'
                        :arrival-date='yacht.arrivalDate'
                        :price='
                            yacht.freeUnits.cabin > 0 && yacht.pricesHalf["cabin"][$store.currency].amount_int !== 0 ?
                            yacht.pricesHalf["cabin"][$store.currency] :
                            yacht.prices["single"][$store.currency]
                        '
                        :price-full='
                            yacht.freeUnits.cabin > 0 && yacht.pricesFullHalf["cabin"][$store.currency].amount_int !== 0 ?
                            yacht.pricesFullHalf["cabin"][$store.currency] :
                            yacht.pricesFull["single"][$store.currency]
                        '
                        :code='yacht.code'
                        :yacht-name='yacht.yachtName[LANGUAGE]'
                        :yacht-type='yacht.typeName[LANGUAGE]'
                        :theme='yacht.theme'
                        :theme-name='yacht.themeName[LANGUAGE]'
                        :url-suffix='`adultsNum=${adultsNum}`'
                        :notice-text='yacht.label[LANGUAGE]'
                        :default-price-label="$t('LABELS.price-for-one-person-from')"
                    ></showcase-item>
                    <p class='search-filters__no-results' v-if='!searching && searchedYachts.length === 0'>
                        {{ $t('TEXTS.no-searched-cruises') }}</p>
                </div>
                <Paging :max-page='maxPage' :current-page='currentPage' @changed-page='changePage'></Paging>
            </div>
        </div>
    </main>
    <Footer type='yachtas'></Footer>
    <Loading :is-loading='isLoading' :label="$t('STATUSES.loading-page')"></Loading>
    <Loading :is-loading='!isLoading && searching' :label="$t('STATUSES.searching-cruises')"></Loading>
</template>

<script>
import Slider from '@vueform/slider';
import moment from 'moment';
import vSelect from 'vue-select';

import ClickOutside from '../../modules/vendor/vue-click-outside';
import Counter from '../../components/Counter.vue';
import Footer from '../../components/Footer';
import MainNav from '../../components/MainNav';
import ShowcaseItem from '../../components/ShowcaseItem.vue';
import Paging from '../../components/Paging';
import { Dictionary } from '../../modules/Dictionary';
import { Api } from '../../modules/Api';
import utilFormatPrice from '../../utilities/formatPrice';
import Loading from '../../components/Loading.vue';
import Banner from '../../components/Banner.vue';

import { Head } from '@vueuse/head';

import { TYPE, LANGUAGE } from '@/env';

const dict = new Dictionary;

export default {
    url: {
        currentPages: 'page'
    },
    components: {
        Loading,
        Footer,
        MainNav,
        Slider,
        Counter,
        vSelect,
        Paging,
        ShowcaseItem,
        Banner,
        Head
    },
    data() {
        return {
            api: new Api(this.$t),
            LANGUAGE: LANGUAGE,
            showAdvanced: false,
            isShownMobileSorting: false,
            isShownMobileFiltering: false,
            priceLimit: {
                czk: 100000,
                eur: 4000
            },
            price: [0, 0],
            searchedYachts: [],
            adultsNum: 2,
            adultsLimit: 100,
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment(new Date(new Date().setYear(new Date().getFullYear() + 1))).format('YYYY-MM-DD'),
            transportIncluded: false,
            isCrewPopupShown: false,
            isDatePopupShown: false,
            country: 'Kamkoliv',
            countries: [],
            currentPage: 1,
            maxPage: 1,
            sortBy: 'soon',
            totalComfort: false,
            yachtDaysMax: 'Libovolná délka',
            newAdultsNum: 0,
            newChildrenNum: 0,
            priceType: 'cabin',
            searching: false,
            canSearch: true,
            themeCode: 'Všechna témata',
            yachtType: 'Jakákoliv loď',
            itemPriceLabel: {
                single: 'Cena pro 1. osobu',
                cabin: 'Cena za kajutu',
                yacht: 'Cena za celou loď'
            },
            isLoading: false,
            banners: []
        };
    },
    computed: {
        adultsInflected() {
            return dict.getWord('person', this.adultsNum, LANGUAGE);
        },
        langCountries() {
            const langCountries = this.countries.map(country => {
                country.langName = country.name[LANGUAGE];
                return country;
            });
            return langCountries;
        },
        langDaysMax() {
            const options = [
                {
                    duration: this.$t('LABELS.any-duration'),
                    name: {
                        cs: this.$t('LABELS.any-duration'),
                        sk: this.$t('LABELS.any-duration')
                    }
                },
                {
                    duration: 30,
                    name: {
                        cs: this.$t('LABELS.max-30-days'),
                        sk: this.$t('LABELS.max-30-days')
                    }
                },
                {
                    duration: 60,
                    name: {
                        cs: this.$t('LABELS.max-60-days'),
                        sk: this.$t('LABELS.max-60-days')
                    }
                },
                {
                    duration: 90,
                    name: {
                        cs: this.$t('LABELS.max-90-days'),
                        sk: this.$t('LABELS.max-90-days')
                    }
                },
                {
                    duration: 120,
                    name: {
                        cs: this.$t('LABELS.max-120-days'),
                        sk: this.$t('LABELS.max-120-days')
                    }
                }
            ];

            const langMaxDaysOptions = options.map(option => {
                option.langName = option.name[LANGUAGE];
                return option;
            });

            return langMaxDaysOptions;
        },
        langYachtThemes() {
            const options = [
                {
                    code: this.$t('LABELS.all-themes'),
                    added: true,
                    name: {
                        cs: this.$t('LABELS.all-themes'),
                        sk: this.$t('LABELS.all-themes')
                    }
                },
                {
                    code: 'exotic',
                    name: {
                        cs: this.$t('LABELS.exotic'),
                        sk: this.$t('LABELS.exotic')
                    }
                },
                {
                    code: 'fitness',
                    name: {
                        cs: this.$t('LABELS.fitness'),
                        sk: this.$t('LABELS.fitness')
                    }
                },
                {
                    code: 'lamour',
                    name: {
                        cs: this.$t('LABELS.lamour'),
                        sk: this.$t('LABELS.lamour')
                    }
                },
                {
                    code: 'adventurous',
                    name: {
                        cs: this.$t('LABELS.adventurous'),
                        sk: this.$t('LABELS.adventurous')
                    }
                },
                {
                    code: 'family-friendly',
                    name: {
                        cs: this.$t('LABELS.family-friendly'),
                        sk: this.$t('LABELS.family-friendly')
                    }
                },
                {
                    code: 'for-captains',
                    name: {
                        cs: this.$t('LABELS.for-captains'),
                        sk: this.$t('LABELS.for-captains')
                    }
                }
            ];

            const langThemes = options.map(theme => {
                theme.langName = theme.name[LANGUAGE];
                return theme;
            });

            return langThemes;
        },
        langYachtTypes() {
            const options = [
                {
                    code: this.$t('LABELS.any-ship'),
                    added: true,
                    name: {
                        cs: this.$t('LABELS.any-ship'),
                        sk: this.$t('LABELS.any-ship')
                    }
                },
                {
                    code: 'sailboat',
                    name: {
                        cs: this.$t('LABELS.yacht'),
                        sk: this.$t('LABELS.yacht')
                    }
                },
                {
                    code: 'catamaran',
                    name: {
                        cs: this.$t('LABELS.catamaran'),
                        sk: this.$t('LABELS.catamaran')
                    }
                }
            ];

            const langThemes = options.map(theme => {
                theme.langName = theme.name[LANGUAGE];
                return theme;
            });

            return langThemes;
        }
    },
    methods: {
        showMobileSorting() {
            this.isShownMobileSorting = true;
        },
        hideMobileSorting() {
            this.isShownMobileSorting = false;
        },
        showMobileFiltering() {
            this.isShownMobileFiltering = true;
        },
        hideMobileFiltering() {
            this.isShownMobileFiltering = false;
        },
        toggleAdvanced() {
            this.showAdvanced = !this.showAdvanced;
        },
        hideCrewPopup() {
            if (this.isCrewPopupShown === true) {
                this.isCrewPopupShown = false;

                this.search();
                this.saveUrlFilters({ adultsNum: this.adultsNum });
            }
        },
        showCrewPopup() {
            if (this.isCrewPopupShown === false) {
                this.isCrewPopupShown = true;
            }
        },
        hideDatePopup() {
            if (this.isDatePopupShown === true) {
                this.isDatePopupShown = false;
            }
        },
        showDatePopup() {
            if (this.isDatePopupShown === false) {
                this.isDatePopupShown = true;
            }
        },
        updateAdultsNum(num) {
            this.adultsNum = num;
        },
        updateChildrenNum(num) {
            this.childrenNum = num;
        },
        async search(page = 1) {
            if (this.canSearch === false) {
                return null;
            }

            this.searching = true;

            const filters = {
                countryCode: !this.langCountries.find(country => country.code === this.country)?.added ? this.country : null,
                departureDayFrom: this.startDate,
                departureDayTo: this.endDate,
                adultCount: this.adultsNum,
                transportIncluded: this.transportIncluded,
                page,
                orderBy: this.sortBy,
                yachtDaysMax: !this.langDaysMax.find(maxDays => maxDays.code === this.yachtDaysMax)?.added ? this.priceMin : this.price[0],
                priceMax: this.price[1] >= this.priceLimit[this.$store.currency] ? null : this.price[1],
                priceCurrency: this.$store.currency,
                themeCode: !this.langYachtThemes.find(theme => theme.code === this.themeCode)?.added ? this.themeCode : null,
                typeCode: !this.langYachtTypes.find(type => type.code === this.yachtType)?.added ? this.yachtType : null
            };

            const { meta: searchMeta, data: searchData } = await this.api.searchYachts(filters, 4);

            searchData.forEach(data => {
                // TODO: Improved that hell
                data.pricesHalf = JSON.parse(JSON.stringify(data.prices));
                Object.keys(data.pricesHalf).forEach(type => {
                    Object.keys(data.pricesHalf[type]).forEach(currency => {
                        data.pricesHalf[type][currency].amount_decimal /= 2;
                        data.pricesHalf[type][currency].amount_int /= 2;
                    });
                });

                data.pricesFullHalf = JSON.parse(JSON.stringify(data.pricesFull));
                Object.keys(data.pricesFullHalf).forEach(type => {
                    Object.keys(data.pricesFullHalf[type]).forEach(currency => {
                        data.pricesFullHalf[type][currency].amount_decimal /= 2;
                        data.pricesFullHalf[type][currency].amount_int /= 2;
                    });
                });
            });

            this.handleSearchResults(searchMeta, searchData);

            this.searching = false;
        },
        handleSearchResults(meta, results) {
            this.searchedYachts = results;
            this.maxPage = meta.maxPage;
            this.currentPage = meta.currentPage;
        },
        changePage(newPage) {
            this.currentPage = newPage;
            this.search(this.currentPage);
            window.scrollTo(0, 0);
        },
        fillPrice() {
            this.price = [0, this.priceLimit[this.$store.currency]];
        },
        priceChanged() {
            this.search();
            this.saveUrlFilters({ priceMin: this.price[0] });
            setTimeout(() => {
                this.saveUrlFilters({ priceMax: this.price[1] });
            }, 1);
        },
        countryChanged() {
            this.search();
            this.saveUrlFilters({ countryCode: this.country });
        },
        yachtTypeChanged() {
            this.search();
            this.saveUrlFilters({ yachtType: this.yachtType });
        },
        themeChanged() {
            this.search();
            this.saveUrlFilters({ themeCode: this.themeCode });
        },
        loadUrlFilters() {
            const allowedFilters = [
                'priceMin',
                'priceMax',
                'adultsNum',
                'childrenNum',
                'startDate',
                'endDate',
                'transportIncluded',
                'countryCode',
                'sortBy',
                'totalComfort',
                'yachtDaysMax',
                'priceType',
                'themeCode',
                'yachtType'
            ];
            const hash = this.$route.hash.slice(1);

            this.canSearch = false;
            hash.split('&').forEach(param => {
                const [key, val] = param.split('=');

                if (allowedFilters.indexOf(key) !== -1) {
                    if (key === 'priceMin') {
                        this.price = [val, this.price[1]];
                    } else if (key === 'priceMax') {
                        this.price = [this.price[0], val];
                    } else if (key === 'adultsNum') {
                        if (val > this.childrenLimit) {
                            this.adultsNum = this.adultsLimit;
                            this.newAdultsNum = this.adultsLimit;
                        } else {
                            this.adultsNum = isNaN(Number(val)) ? 0 : Number(val);
                            this.newAdultsNum = isNaN(Number(val)) ? 0 : Number(val);
                        }
                    } else if (key === 'childrenNum') {
                        if (val > this.childrenLimit) {
                            this.childrenNum = this.childrenLimit;
                            this.newChildrenNum = this.childrenLimit;
                        } else {
                            this.childrenNum = isNaN(Number(val)) ? 0 : Number(val);
                            this.newChildrenNum = isNaN(Number(val)) ? 0 : Number(val);
                        }
                    } else if (key === 'countryCode') {
                        this.country = val;
                    } else {
                        if (!isNaN(Number(val))) {
                            this[key] = Number(val);
                        } else if (val === 'true' || val === 'false') {
                            this[key] = val === 'true' ? true : false;
                        } else {
                            if (val !== undefined || val?.length === 0) {
                                this[key] = val;
                            }
                        }
                    }
                }
            });
            this.canSearch = true;
        },
        saveUrlFilters(filters) {
            const prevScroll = document.documentElement.scrollTop;
            const hash = this.$route.hash.slice(1);
            const hashParams = {};

            if (hash.length > 0) {
                hash.split('&').forEach(param => {
                    const [key, val] = param.split('=');
                    hashParams[key] = val;
                });
            }

            Object.keys(filters).forEach(filterKey => {
                hashParams[filterKey] = filters[filterKey];
            });

            let finalUrlHash = '#';
            Object.keys(hashParams).forEach((hashKey, i) => {
                if (i !== 0) {
                    finalUrlHash += '&';
                }
                if (!hashParams[hashKey]) {
                    finalUrlHash += `${hashKey}`;
                } else {
                    finalUrlHash += `${hashKey}=${hashParams[hashKey]}`;
                }
            });

            if (Object.keys(hashParams).length > 0) {
                this.$router.push({ hash: finalUrlHash });
            }

            setTimeout(() => {
                window.scrollTo(0, prevScroll);
            }, 1);
        },
        formatPrice(price) {
            return utilFormatPrice(price);
        },
        async loadCountries() {
            this.countries = await this.api.getYachtCountries();
        },
        setTranslatedDefaults() {
            this.country = this.$t('LABELS.anywhere');
            this.yachtType = this.$t('LABELS.any-ship');
            this.themeCode = this.$t('LABELS.all-themes');
            this.yachtDaysMax = this.$t('LABELS.any-duration');
            this.itemPriceLabel.single = this.$t('LABELS.price-first-person');
            this.itemPriceLabel.cabin = this.$t('LABELS.price-for-cabin');
            this.itemPriceLabel.yacht = this.$t('LABELS.price-for-whole-ship');
        },
        async loadBanners() {
            this.banners = await this.api.getBanner(TYPE, LANGUAGE, 'search', true) || this.banners;
        }
    },
    beforeMount() {
        this.setTranslatedDefaults();
        this.loadUrlFilters();
    },
    async mounted() {
        this.isLoading = true;
        this.loadBanners();
        await this.loadCountries();
        this.fillPrice();
        this.isLoading = false;
        this.search();
    },
    watch: {
        sortBy() {
            this.search();
            this.saveUrlFilters({ sortBy: this.sortBy });
        },
        // adultsNum() {
        //     this.search();
        //     this.saveUrlFilters({adultsNum: this.adultsNum});
        // },
        startDate() {
            this.search();
            this.saveUrlFilters({ startDate: this.startDate });
        },
        endDate() {
            this.search();
            this.saveUrlFilters({ endDate: this.endDate });
        },
        totalComfort() {
            this.search();
            this.saveUrlFilters({ totalComfort: this.totalComfort });
        },
        yachtDaysMax() {
            this.search();
            this.saveUrlFilters({ yachtDaysMax: this.yachtDaysMax });
        },
        transportIncluded() {
            this.search();
            this.saveUrlFilters({ transportIncluded: this.transportIncluded });
        },
        yachtType() {
            this.search();
            this.saveUrlFilters({ yachtType: this.yachtType });
        }
    },
    directives: {
        ClickOutside
    },
};


</script>

<style src='@vueform/slider/themes/default.css'></style>