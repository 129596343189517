<template>
    <div class="cookies-bar" v-if="showCookiesBar">
        <div class="cookies-bar__header">{{ $t('LABELS.cookies-header') }}</div>
        <div class="cookies-bar__body">
            <div class="cookies-bar__content">
                {{ $t('TEXTS.cookies-content') }}
            </div>
            <div class="cookies-bar__preferences" v-if="showPreferences">
                <CookiesBarAccordion 
                    :name="$t('LABELS.cookies-functional-header')"
                    :text="$t('TEXTS.cookies-functional-text')"
                    v-bind:isDisabled="true"
                    v-bind:isChecked="preferencesCookies.functional"
                    @change-switch="onFunctionalCookiesChange"
                />
                <CookiesBarAccordion 
                    :name="$t('LABELS.cookies-preferential-header')"
                    :text="$t('TEXTS.cookies-preferential-text')"
                    v-bind:isDisabled="false"
                    v-bind:isChecked="preferencesCookies.preferential"
                    @change-switch="onPreferentialCookiesChange"
                />
                <CookiesBarAccordion 
                    :name="$t('LABELS.cookies-analytic-header')"
                    :text="$t('TEXTS.cookies-analytic-text')"
                    v-bind:isDisabled="false"
                    v-bind:isChecked="preferencesCookies.analytic"
                    @change-switch="onAnalyticCookiesChange"
                />
                <CookiesBarAccordion 
                    :name="$t('LABELS.cookies-marketing-header')"
                    :text="$t('TEXTS.cookies-marketing-text')"
                    v-bind:isDisabled="false"
                    v-bind:isChecked="preferencesCookies.marketing"
                    @change-switch="onMarketingCookiesChange"
                />
            </div>
        </div>
        <div class="cookies-bar__buttons">
            <button class="cookies-bar__btn btn btn--standalone-small btn--blue" @click="saveAllowCookies()">{{ $t('LABELS.cookies-allow-all') }}</button>
            <button class="cookies-bar__btn btn btn--standalone-small btn--gray" @click="saveRejectCookies()">{{ $t('LABELS.cookies-reject') }}</button>
            <button class="cookies-bar__btn btn btn--standalone-small btn--gray" v-if="!showPreferences" @click="setShowPreferences(true)">{{ $t('LABELS.cookies-show-preferences') }}</button>
            <button class="cookies-bar__btn btn btn--standalone-small btn--gray" v-if="showPreferences" @click="savePreferencesCookies()">{{ $t('LABELS.cookies-save-preferences') }}</button>
        </div>
        <a href="https://info.nautyy.cz/ochrana-soukromi/" target="_blank" class="cookies-bar__link">{{ $t('LABELS.cookies-link') }}</a>
    </div>
    <div class="cookies-manage-consent" @click="setShowCookiesBar(true)" v-else>
        {{ $t('LABELS.cookies-manage') }}
    </div>
</template>

<script>
import { inject } from 'vue';
import CookiesBarAccordion from './CookiesBarAccordion.vue';

export default {
    props: [],
    components: {
        CookiesBarAccordion,
    },
    data() {
        return {
            showCookiesBar: true,
            showPreferences: false,
            preferencesCookies: {
                functional: true,
                preferential: true,
                analytic: true,
                marketing: true,
            },
            $cookies: inject('$cookies'),
        }
    },
    methods: {
        setShowCookiesBar(show) {
            this.$cookies.set('cookies-bar-show', show);
            this.showCookiesBar = show;
        },
        setShowPreferences(show) {
            this.showPreferences = show;
        },
        onFunctionalCookiesChange(value) {
            this.preferencesCookies.functional = value;
        },
        onPreferentialCookiesChange(value) {
            this.preferencesCookies.preferential = value;
        },
        onAnalyticCookiesChange(value) {
            this.preferencesCookies.analytic = value;
        },
        onMarketingCookiesChange(value) {
            this.preferencesCookies.marketing = value;
        },
        savePreferencesCookies() {
            this.save();
        },
        saveAllowCookies() {
            this.preferencesCookies = {
                functional: true,
                preferential: true,
                analytic: true,
                marketing: true,
            };
            this.save();
        },
        saveRejectCookies() {
            this.preferencesCookies = {
                functional: true,
                preferential: false,
                analytic: false,
                marketing: false,
            };
            this.save();
        },
        save() {
            this.setShowCookiesBar(false);
            this.setShowPreferences(false);
            this.$cookies.set('cookies-bar-consent-functional', this.preferencesCookies.functional);
            this.$cookies.set('cookies-bar-consent-preferential', this.preferencesCookies.preferential);
            this.$cookies.set('cookies-bar-consent-analytic', this.preferencesCookies.analytic);
            this.$cookies.set('cookies-bar-consent-marketing', this.preferencesCookies.marketing);
            
            this.$gtag.query('consent', 'update', {
                'ad_storage': this.preferencesCookies.marketing ? 'granted' : 'denied',
                'ad_user_data': this.preferencesCookies.marketing ? 'granted' : 'denied',
                'ad_personalization': this.preferencesCookies.marketing ? 'granted' : 'denied',
                'analytics_storage': this.preferencesCookies.analytic ? 'granted' : 'denied',
                'personalization_storage': this.preferencesCookies.preferential ? 'granted' : 'denied'
            });
        }
    },
    mounted() {
        if (this.$cookies.get('cookies-bar-show') === 'false') {
            this.showCookiesBar = false;
        }
        this.preferencesCookies = {
                functional: this.$cookies.get('cookies-bar-consent-functional') !== 'false',
                preferential: this.$cookies.get('cookies-bar-consent-preferential') !== 'false',
                analytic: this.$cookies.get('cookies-bar-consent-analytic') !== 'false',
                marketing: this.$cookies.get('cookies-bar-consent-marketing') !== 'false',
            };
    },
}
</script>
