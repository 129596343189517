<template>
    <div v-if="false"></div>
</template>
<script>
import { Fancybox } from '@fancyapps/ui/src/Fancybox';
import * as _ from 'lodash';

export default {
    props: ['visible', 'data', 'selectedId'],
    emits: ['closed'],
    computed: {
        gallery() {
            const gallery = [];

            const parseVideoType = (item) => {
                return {
                    id: item.id,
                    src: `<video autoplay controls><source src='${item.src}' type='video/mp4' /></video>`,
                    type: 'html'
                };
            };

            const parseImageType = (item) => {
                return {
                    id: item.id,
                    src: `<div style='width: 100%'><img style='width: 100%' src='${item.src}' /></div>`,
                    type: 'html'
                };
            };

            for (const item of this.data || []) {
                if (item.type.includes("video")) gallery.push(parseVideoType(item));
                else if (item.type.includes("image")) gallery.push(parseImageType(item));
            }

            return gallery;
        }
    },
    methods: {
        showFancy() {
            if (_.size(this.gallery) === 0) return;

            Fancybox.show(this.gallery, {
                startIndex: _.findIndex(this.gallery, ["id", this.selectedId]) || 0,
                on: {
                    'destroy': () => this.$emit('closed')
                }
            });
        }
    },
    watch: {
        visible() {
            if (this.visible) this.showFancy();
        }
    },
    mounted() {
        if (this.visible) this.showFancy();
    }
};
</script>