<template>
    <a :href="url" target="_blank" class="banner" v-if="(type || typeMobile) && (source || sourceMobile)">
        <!-- Image banner -->
        <img
            class="banner__content"
            :class="{ 'banner__content--desktop': sourceMobile }"
            v-if="type == 'image'"
            :src="source"
        >
        <img
            class="banner__content banner__content--mobile"
            v-if="typeMobile == 'image' && sourceMobile"
            :src="sourceMobile"
        >
        
        <!-- Video banner -->
        <video
            class="banner__content"
            :class="{ 'banner__content--desktop': sourceMobile }"
            ref="video"
            v-if="type == 'video'"
            :src="source"
            muted autoplay loop
        ></video>
        <video
            class="banner__content banner__content--mobile"
            ref="video"
            v-if="typeMobile == 'video' && sourceMobile"
            :src="sourceMobile"
            muted autoplay loop
        ></video>
    </a>
</template>

<script>

export default {
    props: {
        type: String,
        typeMobile: String,
        source: String,
        sourceMobile: String,
        url: String,
    }
}
</script>