<template>
    <div class="cabin-showcase">
        <div class="cabin-showcase__not-available" v-if="!availability">
            {{ $t('LABELS.soldout') }}
        </div>
        <Swiper
            :slides-per-view="1"
            class="cabin-showcase__slider"
            :autoplay="swiperDelay"
            @swiper="saveSwiper"
        >
            <button
                class="
                    cabin-showcase__slider-btn cabin-showcase__slider-btn--prev
                "
                :class="{
                    'cabin-showcase__slider-btn--disabled': swiper?.isBeginning,
                    'cabin-showcase__slider-btn--hidden':
                        swiper?.isBeginning && swiper?.isEnd,
                }"
                @click="prevSlide"
            >
                <svg
                    class="
                        cabin-showcase__slider-icon
                        cabin-showcase__slider-icon--left
                    "
                >
                    <use
                        href="/assets/imgs/icons/sprite.svg#simple-arrow"
                    ></use>
                </svg>
            </button>
            <Swiper-slide v-for="(imgUrl, i) in images" :key="i"
                ><img :src="imgUrl" class="cabin-showcase__slide"
            /></Swiper-slide>
            <button
                class="
                    cabin-showcase__slider-btn cabin-showcase__slider-btn--next
                "
                :class="{
                    'cabin-showcase__slider-btn--disabled': swiper?.isEnd,
                    'cabin-showcase__slider-btn--hidden':
                        swiper?.isEnd && swiper?.isBeginning,
                }"
                @click="nextSlide()"
            >
                <svg
                    class="
                        cabin-showcase__slider-icon
                        cabin-showcase__slider-icon--right
                    "
                >
                    <use
                        href="/assets/imgs/icons/sprite.svg#simple-arrow"
                    ></use>
                </svg>
            </button>
        </Swiper>
        <div class="cabin-showcase__content">
            <div class="cabin-showcase__part">
                <!-- <h4 class="cabin-showcase__subheading">{{ location }}</h4> -->
                <h3 class="cabin-showcase__heading">{{ name }}</h3>
            </div>
            <div class="cabin-showcase__part">
                <p class="cabin-showcase__prices">
                    <button
                        class="cabin-showcase__price-btn"
                        v-for="option in availablePackages"
                        :key="option.code"
                        @click="chooseCabin(option.code)"
                        :disabled="!availability"
                    >
                        <p class="cabin-showcase__price">
                            {{
                                `${formatPrice(
                                    prices?.[option.code][$store.currency]
                                        .amount_decimal
                                )} ${
                                    prices?.[option.code][$store.currency]
                                        .currency
                                }`
                            }}
                        </p>
                        <p class="cabin-showcase__price-label">
                            {{
                                option.label
                                    ? $t(option.label)
                                    : `${$t('TEXTS.with-package')} "${
                                          option.code
                                      }"`
                            }}
                        </p>
                    </button>
                </p>
                <p class="cabin-showcase__price-note">
                    {{ $t('LABELS.prices-shown-for') }}
                    <strong
                        >{{ adultsNum }} {{ inflectWord('adults', adultsNum)
                        }}{{
                            childrenNum > 0
                                ? ` a ${childrenNum} ${inflectWord(
                                      'children',
                                      childrenNum
                                  )}`
                                : ''
                        }}</strong
                    >
                </p>
            </div>
            <div class="cabin-showcase__part">
                <p class="cabin-showcase__note">
                    {{ $t('TEXTS.service-fee-for-guest') }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from '../../node_modules/swiper/vue/swiper-vue';

import { Dictionary } from '../modules/Dictionary';
import formatPriceUtil from '../utilities/formatPrice';
import { LANGUAGE } from '@/env';

const dict = new Dictionary();

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    props: [
        'code',
        'images',
        'name',
        'location',
        'prices',
        'adultsNum',
        'childrenNum',
        'availability',
    ],
    emits: ['choseCabin'],
    data() {
        return {
            swiper: null,
            packageOptions: {
                MYCRUISE: 'TEXTS.with-my-cruise-package',
                PROMO: 'TEXTS.with-promo-package',
                PIND: 'TEXTS.with-last-minute-package',
                MYALLINC: 'TEXTS.with-all-inclusive-package',
            },
        };
    },
    methods: {
        saveSwiper(swiper) {
            this.swiper = swiper;
        },
        nextSlide() {
            this.swiper.slideNext();
        },
        prevSlide() {
            this.swiper.slidePrev();
        },
        formatPrice(price) {
            return formatPriceUtil(price);
        },
        inflectWord(word, num) {
            return dict.getWord(word, num, LANGUAGE);
        },
        chooseCabin(packageCode) {
            this.$emit('choseCabin', { code: this.code, package: packageCode });
        },
    },
    computed: {
        swiperDelay() {
            return {
                delay: 3000,
            };
        },
        availablePackages() {
            // Iterate over prices and create package entries with their labels
            return Object.keys(this.prices)
                .filter((packageCode) => {
                    return this.prices[packageCode]?.czk?.amount_decimal > 0;
                })
                .map((packageCode) => ({
                    code: packageCode,
                    label: this.packageOptions[packageCode], // Fallback to package code if no predefined label exists
                }));
        },
    },
};
</script>
