<template>
    <div
        class="notification"
        :class="{
            'notification--yachtas': TYPE === 'yachtas',
            'notification--cruises': TYPE === 'cruises',
            'notification--small': config.small,
            'notification--error': !type || type === 'error',
            'notification--success': type === 'success',
            'notification--note': type === 'note'
        }"
    >
        <p v-show="config.isHtml" class="notification__text" v-for="textItem in text" :key="textItem">{{ textItem }}</p>
        <p class="notification__text" v-for="textItem in text" :key="textItem">{{ textItem }}</p>
        <button class="notification__close-btn" @click="close">
            <svg
                class="notification__icon"
                :class="{
                    'notification__icon--error': !type || type === 'error',
                    'notification__icon--success': type === 'success'
                }"
            ><use href="/assets/imgs/icons/sprite.svg#cross"></use></svg>
        </button>
    </div>
</template>
<script>

import { TYPE } from '@/env';

export default {
    props: [ 'text', 'initConfig', 'type' ],
    data() {
        return {
            config: this.initConfig ? this.initConfig : {},
            TYPE: TYPE
        }
    },
    methods: {
        close() {
            this.$emit('closed')
        }
    },
};
</script>