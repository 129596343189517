<template>
    <header class="sticky-header-yacht__header" v-show="showStickyHeader">
        <div class="sticky-header-yacht__header-part">
            <h3 class="sticky-header-yacht__header-heading">
                {{ $t('LABELS.sail-date') }}
            </h3>
            <p class="sticky-header-yacht__header-text">
                <span class="sticky-header-yacht__arrow">→</span
                >{{ formatDate(details.departureDate) }}
            </p>
            <p class="sticky-header-yacht__header-text">
                <span class="sticky-header-yacht__arrow">←</span
                >{{ formatDate(details.arrivalDate) }}
            </p>
        </div>

        <div class="sticky-header-yacht__header-part">
            <h3 class="sticky-header-yacht__header-heading">
                {{ $t('LABELS.total-time') }}
            </h3>
            <p class="sticky-header-yacht__header-text">
                {{ details.days }} {{ daysInflected(details.duration) }}
            </p>
        </div>

        <div
            class="
                sticky-header-cruise__header-part
                sticky-header-cruise__header-part--iconed
            "
        >
            <p class="sticky-header-cruise__header-text">
                {{ details.ship_name?.[LANGUAGE] }}
            </p>
        </div>

        <div class="sticky-header-yacht__prices">
            <div class="sticky-header-yacht__price-wrapper">
                <div class="sticky-header-yacht__price">
                    {{ this.price }}
                </div>
                <button
                    @click="emitSubmit()"
                    class="sticky-header-yacht__price-btn"
                >
                    {{ $t('LABELS.cruises-delegate-interested-button') }}
                </button>
            </div>
        </div>
    </header>
</template>
<script>
import formatDateUtil from '../utilities/formatDate';
import formatPriceUtil from '../utilities/formatPrice';
import findRefsComponent from '../utilities/findRefsComponent';
import { Dictionary } from '../modules/Dictionary';

import { LANGUAGE } from '@/env';

const dict = new Dictionary();

export default {
    props: {
        details: Object,
        urlCrewSearch: String,
        price: String,
    },
    data() {
        return {
            showStickyHeader: false,
            LANGUAGE: LANGUAGE,
        };
    },
    methods: {
        daysInflected(days_num) {
            return dict.getWord('days', days_num, LANGUAGE);
        },
        formatDate(date) {
            return formatDateUtil(date);
        },
        formatPrice(price) {
            return formatPriceUtil(price);
        },
        discountPriceMarkup(type) {
            return `
                <span class="sticky-header-yacht__price-discounted">${this.formatPriceText(
                    this.details?.prices[type][this.$store.currency]
                )}</span>
                <span class="sticky-header-yacht__price-original">${this.formatPriceText(
                    this.details?.pricesFull[type][this.$store.currency]
                )}</span>
            `;
        },
        formatPriceText(price) {
            return `${this.formatPrice(price.amount_decimal)} ${
                price.currency
            }`;
        },
        priceText(type) {
            if (this.details.prices && this.details.freeUnits) {
                if (this.details.isSoldOut?.[type]) {
                    return this.$t('LABELS.soldout');
                }
                return `${this.formatPrice(
                    this.details.prices[type][this.$store.currency]
                        .amount_decimal
                )} ${this.details.prices[type][this.$store.currency].currency}`;
            }
            return '';
        },
        redirectToOrderForm(type) {
            if (this.details.isSoldOut?.[type]) {
                return false;
            }

            this.$router.push(
                `/plavba-s-delegatem-objednavka/${this.details.code}/`
            );
        },
        emitSubmit() {
            this.$emit('submit');
        },
    },
    mounted() {
        document.addEventListener('scroll', () => {
            const triggerRefsComponent = findRefsComponent(
                this,
                'sticky-header-trigger'
            );
            const offsetRefsComponent = findRefsComponent(
                this,
                'sticky-header-offset'
            );

            if (triggerRefsComponent && offsetRefsComponent) {
                const triggerHeight =
                    triggerRefsComponent.$refs['sticky-header-trigger']
                        .scrollHeight;
                const offset =
                    offsetRefsComponent.$refs['sticky-header-offset']
                        .scrollHeight;

                this.showStickyHeader = window.scrollY > triggerHeight + offset;
            }
        });
    },
};
</script>
