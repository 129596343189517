<template>
    <div>
        <div>
            <p class="cabin-delegate__text">
                {{ $t('LABELS.adults-with-age') }}
            </p>
            <Counter
                :min="0"
                :max="maxAdults"
                :initial="numAdults"
                @changed="setAdultsNum"
            ></Counter>
        </div>
        <div>
            <p class="cabin-delegate__text">
                {{ $t('LABELS.children-with-age') }}
            </p>
            <Counter
                :min="0"
                :max="maxChildren"
                :initial="numChildren"
                @changed="setChildrenNum"
            ></Counter>
        </div>
        <div v-if="this.price.length > 1" class="cabin-delegate__text">
            <v-select
                :options="this.price"
                :reduce="(price) => this.price.indexOf(price)"
                v-model="selectedFare"
                :clearable="false"
            >
                <template #option="option">
                    {{ option.fare[LANGUAGE] }}
                </template>
                <template #selected-option="option">
                    {{ option.fare[LANGUAGE] }}
                </template>
            </v-select>
        </div>
        <div class="cabin-delegate__text">
            {{ $t('LABELS.capacity') }}: {{ numAdults + numChildren }} /
            {{ maxPeople }}
        </div>
        <div class="cabin-delegate__text">
            {{ $t('LABELS.price-per-person') }}:
            {{ formatPriceText(avgPrice()) }}
        </div>
        <div class="cabin-delegate__text">
            {{ $t('LABELS.in-total') }}: {{ formatPriceText(totalPrice) }}
        </div>
        <div v-if="isAllInclusiveAvailable" class="cabin-delegate__text">
            <label class="cabin-delegate__label">
                <input
                    type="checkbox"
                    class="cabin-delegate__checkbox"
                    v-model="allInclusive"
                    @change="updateCabinInfo"
                />
                {{ $t('LABELS.all-inclusive') }}
            </label>
        </div>
    </div>
</template>
<script>
import Counter from '../components/Counter.vue';
import vSelect from 'vue-select';
import formatPriceUtil from '../utilities/formatPrice';

import { LANGUAGE } from '@/env';

export default {
    components: {
        Counter,
        vSelect,
    },
    props: {
        price: {
            type: Array,
            required: true,
            default: () => [
                {
                    adult: [],
                    child: [],
                },
            ],
        },
        maxPeople: {
            type: Number,
            default: 4,
        },
        currency: {
            type: String,
            reqzuired: true,
            default: 'czk',
        },
    },
    data() {
        return {
            LANGUAGE: LANGUAGE,
            numAdults: 0,
            numChildren: 0,
            totalPrice: 0,
            selectedFare: 0,
            allInclusive: false,
        };
    },
    methods: {
        calculateTotalPrice() {
            // Calculate the total price for selected adults
            let adultPrice = this.price[this.selectedFare].adult
                .slice(0, this.numAdults)
                .reduce(
                    (sum, price) => sum + price[this.currency].amount_decimal,
                    0
                );

            // Calculate the total price for selected children
            let childPrice = this.price[this.selectedFare].child
                .slice(0, this.numChildren)
                .reduce(
                    (sum, price) => sum + price[this.currency].amount_decimal,
                    0
                );

            // If allInclusive is true, add the all_inclusive_adult and all_inclusive_child prices
            if (this.allInclusive) {
                // For adults
                const allInclusiveAdultPrice =
                    this.price[this.selectedFare].all_inclusive_adult[
                        this.currency
                    ].amount_decimal;
                adultPrice += this.numAdults * allInclusiveAdultPrice;

                // For children
                const allInclusiveChildPrice =
                    this.price[this.selectedFare].all_inclusive_child[
                        this.currency
                    ].amount_decimal;
                childPrice += this.numChildren * allInclusiveChildPrice;
            }

            // Update total price
            this.totalPrice = adultPrice + childPrice;
        },
        formatPrice(price) {
            return formatPriceUtil(price);
        },
        formatPriceText(price) {
            return `${this.formatPrice(price)} ${
                this.$store.currency === 'czk'
                    ? 'Kč'
                    : this.$store.currency === 'eur'
                    ? '€'
                    : this.$store.currency
            }`;
        },
        avgPrice() {
            const totalPeople = this.numAdults + this.numChildren;
            return totalPeople ? this.totalPrice / totalPeople : 0;
        },
        updateCabinInfo() {
            this.calculateTotalPrice();
            this.emitCabinUpdate();
        },
        emitCabinUpdate() {
            this.$emit('update-cabin', {
                numAdults: this.numAdults,
                numChildren: this.numChildren,
                totalPrice: this.totalPrice,
                fare: this.price[this.selectedFare].fare,
                uuid: this.price[this.selectedFare].id,
                code: this.price[this.selectedFare].code,
                allInclusive: this.allInclusive,
            });
        },
        setAdultsNum(newAdultsNum) {
            this.numAdults = newAdultsNum;
            this.updateCabinInfo();
        },
        setChildrenNum(newChildrenNum) {
            this.numChildren = newChildrenNum;
            this.updateCabinInfo();
        },
    },
    computed: {
        maxAdults() {
            return Math.min(
                this.price[this.selectedFare].adult.length,
                this.maxPeople - this.numChildren
            );
        },
        maxChildren() {
            return Math.min(
                this.price[this.selectedFare].child.length,
                this.maxPeople - this.numAdults
            );
        },
        isAllInclusiveAvailable() {
            const fare = this.price[this.selectedFare];
            const allInclusiveAdultPrice =
                fare.all_inclusive_adult[this.currency].amount_decimal;
            const allInclusiveChildPrice =
                fare.all_inclusive_child[this.currency].amount_decimal;
            return allInclusiveAdultPrice > 0 || allInclusiveChildPrice > 0;
        },
    },
    watch: {
        selectedFare() {
            this.updateCabinInfo();
        },
        allInclusive() {
            this.updateCabinInfo();
        },
        isAllInclusiveAvailable(newVal) {
            if (!newVal && this.allInclusive) {
                this.allInclusive = false;
            }
        },
    },
};
</script>
