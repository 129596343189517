<template>
    <Modal
        :return-text="$t('LABELS.close-menu')"
        content-class="nav-mobile"
        :site-type="type"
        :is-opened="isOpened"
        :just-tablet="true"
        @closed="closed"
    >
        <ul class="nav-mobile__menu">
            <li class="nav-mobile__menu-item" v-if="newTypeName === 'yachtas'">
                <router-link class="nav-mobile__link" to="/">{{
                    $t('LINKS.yacht-trip')
                }}</router-link>
            </li>
            <li class="nav-mobile__menu-item" v-else>
                <router-link class="nav-mobile__link" to="/">{{
                    $t('LINKS.cruiser-trip')
                }}</router-link>
            </li>
            <li class="nav-mobile__menu-item" v-if="$store.user.isLogged">
                <router-link class="nav-mobile__link" to="/uzivatel/">{{
                    $t('LINKS.my-trips')
                }}</router-link>
            </li>
            <li class="nav-mobile__menu-item" v-if="$store.user.isLogged">
                <router-link class="nav-mobile__link" to="/nastaveni-uctu/">{{
                    $t('LINKS.account-settings')
                }}</router-link>
            </li>
            <li
                class="nav-mobile__menu-item"
                @click="logout"
                v-if="$store.user.isLogged"
            >
                {{ $t('LABELS.logout') }}
            </li>
            <li class="nav-mobile__menu-item" v-if="!$store.user.isLogged">
                <router-link class="nav-mobile__link" to="/prihlaseni/">{{
                    $t('LABELS.login')
                }}</router-link>
            </li>
            <li class="nav-mobile__menu-item" v-if="!$store.user.isLogged">
                <router-link class="nav-mobile__link" to="/registrace/">{{
                    $t('LABELS.register')
                }}</router-link>
            </li>
        </ul>

        <ul class="nav-mobile__sub-menu">
            <li class="nav-mobile__sub-menu-item">
                <strong>{{ $t('LABELS.more-information') }}</strong>
            </li>
            <li
                class="nav-mobile__sub-menu-item"
                v-for="(item, i) in subMenu"
                :key="i"
            >
                <a
                    :href="item.url"
                    class="nav-mobile__sub-menu-link"
                    v-if="!item.children || item.children.length === 0"
                >
                    {{ item.name[LANGUAGE] }}
                </a>
                <div
                    v-else
                    @click="toggleSubMenu(i)"
                    class="nav-mobile__menu-item-toggle"
                >
                    <span>{{ item.name[LANGUAGE] }}</span>
                    <!-- TODO: Add an icon or arrow to indicate expandable -->
                </div>
                <ul
                    v-if="item.children && expandedIndex === i"
                    class="nav-mobile__nested-sub-menu"
                >
                    <li
                        v-for="(child, j) in item.children"
                        :key="j"
                        class="nav-mobile__sub-menu-item"
                    >
                        <a :href="child.url" class="nav-mobile__sub-menu-link">
                            {{ child.name[LANGUAGE] }}
                        </a>
                    </li>
                </ul>
            </li>
        </ul>

        <div class="nav-mobile__contact">
            <h3 class="nav-mobile__heading">{{ $t('LABELS.contact-us') }}</h3>
            <ul class="nav-mobile__menu">
                <li
                    class="nav-mobile__menu-item nav-mobile__menu-item--contact"
                >
                    <a
                        class="nav-mobile__link"
                        :href="`mailto:${$globalContact[newTypeName][LANGUAGE].email}`"
                    >
                        <svg
                            :class="`nav-mobile__icon nav-mobile__icon--${type} nav-mobile__icon--email`"
                        >
                            <use
                                href="/assets/imgs/icons/sprite.svg#email"
                            ></use>
                        </svg>
                        <span>{{
                            $globalContact[newTypeName][LANGUAGE].email
                        }}</span>
                    </a>
                </li>
                <li
                    class="nav-mobile__menu-item nav-mobile__menu-item--contact"
                >
                    <a
                        class="nav-mobile__link"
                        :href="`tel:${$globalContact[newTypeName][LANGUAGE].phone}`"
                    >
                        <svg
                            :class="`nav-mobile__icon nav-mobile__icon--${type} nav-mobile__icon--tel`"
                        >
                            <use href="/assets/imgs/icons/sprite.svg#tel"></use>
                        </svg>
                        <span>{{
                            $globalContact[newTypeName][LANGUAGE].phone
                        }}</span>
                    </a>
                </li>
                <li
                    class="nav-mobile__menu-item nav-mobile__menu-item--contact"
                >
                    <a class="nav-mobile__link" :href="otherLangUrl">
                        <img
                            class="nav-mobile__flag-icon"
                            :src="otherLangFlagUrl"
                        />
                        <span>{{ otherLangText }}</span>
                    </a>
                </li>
            </ul>
        </div>
        <other-app-dash class="nav-mobile__other-app-dash"></other-app-dash>
    </Modal>
</template>

<script>
import Modal from './Modal.vue';
import OtherAppDash from './OtherAppDash.vue';

import { TYPE, LANGUAGE } from '@/env';

export default {
    emits: ['closed'],
    components: {
        Modal,
        OtherAppDash,
    },
    props: {
        type: {
            type: String,
            default: 'cruiser',
        },
        isOpened: Boolean,
        subMenu: Object,
    },
    data() {
        return {
            LANGUAGE: LANGUAGE,
            otherLang: LANGUAGE !== 'cs' ? 'cs' : 'sk',
            expandedIndex: null,
        };
    },
    computed: {
        newTypeName() {
            return TYPE;
        },
        otherLangText() {
            if (this.otherLang === 'cs') {
                return this.$t('LABELS.czech-version');
            } else {
                return this.$t('LABELS.slovak-version');
            }
        },
        otherLangUrl() {
            const otherLang = this.otherLang === 'cs' ? 'cz' : 'sk';
            const newDomain =
                location.host.slice(0, location.host.length - 2) + otherLang;
            const newUrl = location.href.replace(location.host, newDomain);
            return newUrl;
        },
        otherLangFlagUrl() {
            const otherLang = this.otherLang === 'cs' ? 'czech' : 'slovak';
            const otherFlagUrl = `/assets/imgs/flag-${otherLang}.png`;
            return otherFlagUrl;
        },
        otherApp() {
            //! TMP
            // return TYPE === 'cruises' ? 'yachtas' : 'cruises';
            return TYPE === 'cruises' ? 'cruises' : 'yachtas';
        },
    },
    methods: {
        closed() {
            this.$emit('closed');
        },
        logout() {
            this.$store.user.logout();
            this.$router.push('/prihlaseni/');
        },
        toggleSubMenu(index) {
            this.expandedIndex = this.expandedIndex === index ? null : index;
        },
    },
};
</script>
