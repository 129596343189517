import { getViewCountedFromStoreByCode, setViewCountedToStoreByCode } from './viewCount';
import {
    getPassengersFromStoreByCode,
    setPassengersToStoreByCode,
    createPassengerForStore,
    resetPassengersStore,
    updatePassengersWithLoggedUser
} from './passengers';


export const yachtasStoreKeys = {
    viewCounted: '_yachtas_view_counted',
    passengers: '_yachtas_reservations_passengers'
};

/**
 * Yachtas store for persist local data
 */
export const yachtasStore = {
    viewCounted: {
        get: (code) => getViewCountedFromStoreByCode(code),
        set: (code, num) => setViewCountedToStoreByCode(code, num)
    },
    passengers: {
        get: (code) => getPassengersFromStoreByCode(code),
        set: (code, passengers) => setPassengersToStoreByCode(code, passengers),
        create: (type) => createPassengerForStore(type),
        reset: () => resetPassengersStore(),
        updateWithLoggedUser: (passengers, data) => updatePassengersWithLoggedUser(passengers, data)
    }
};
