import { yachtasStoreKeys } from '@/utilities/store/yachtas/index';
import store from 'storejs';
import * as _ from 'lodash';

const getViewCountedFromStore = () => {
    return store.get(yachtasStoreKeys.viewCounted) || {};
};

export const getViewCountedFromStoreByCode = (code) => {
    return _.get(getViewCountedFromStore(), code);
};

export const setViewCountedToStoreByCode = (code, num) => {
    const updatedValue = _.set(getViewCountedFromStore(), code, {
        createdAt: new Date(),
        value: num
    });
    return store.set(yachtasStoreKeys.viewCounted, updatedValue);
};