<template>
    <Head>
        <title v-if='type === "yacht"'>
            YACHTA'S
        </title>
        <title v-else>
            COSTA Cruise's
        </title>
    </Head>
    <header class="header-detail">
        <Main-nav :type="type"></Main-nav>
    </header>
    <main class="main">
        <section class="section section--small-mb">
            <div class="section__inner">
                <div class="user-page__header">
                    <h1 :class="`user-page__heading-1 user-page__heading-1--${type}`">{{ $t('LABELS.my-trips') }}</h1>
                    <div class="user-page__heading-btns">
                        <button class="user-page__logout-btn" @click="logout">{{ $t('LABELS.logout') }}</button>
                        <router-link
                            to="/nastaveni-uctu/"
                            class="user-page__heading-btn btn btn--standalone"
                            :class="{
                                'btn--blue': type === 'cruiser',
                                'btn--orange': type === 'yacht'
                            }"
                        >{{ $t('LABELS.account-settings') }}</router-link>
                    </div>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="section__inner">
                <h2 :class="`user-page__heading-2 user-page__heading-2--${type}`">{{ $t('LABELS.upcoming-trips') }}</h2>
                <div class="user-page__cruises">
                    <HistoryItem
                        v-for="order in orders"
                        :key="order.orderNumber"
                        :type="order.type"
                        :adults="order.adults"
                        :children="order.children"
                        :departure-date="order.departureAt"
                        :departure-from="order.departureFrom?.[LANGUAGE]"
                        :arrival-date="order.arrivalAt"
                        :price="order.price"
                        :amount="order.amount"
                        :state="order.state"
                        :state-name="order.stateName"
                        :reservation-type="order.reservationType"
                        :passengers="order.passengers"
                        :dials="dials"
                        :paid="order.paid"
                        :to-be-paid="order.remaining"
                        :to-be-paid-date="order.paymentDeadlineAt"
                        :ticket-deposit='order.ticketDeposit'
                        :ticket-full='order.ticketFull'
                        :ticket-remaining='order.ticketRemaining'
                        :files="order.files"
                    ></HistoryItem>
                </div>
            </div>
        </section>
    </main>
    <Footer></Footer>
    <Loading :is-loading="isLoading" :label="$t('STATUSES.loading-your-cruises')"></Loading>
</template>

<script>
import Footer from '../components/Footer';
import MainNav from '../components/MainNav';
import HistoryItem from '../components/HistoryItem';
import { Api } from '../modules/Api';
import Loading from '../components/Loading.vue'

import { Head } from '@vueuse/head'

import { TYPE, LANGUAGE } from '@/env'

export default {
    components: {
        Footer,
        MainNav,
        HistoryItem,
        Loading,
        Head
    },
    data() {
        return {
            api: new Api(this.$t),
            LANGUAGE: LANGUAGE,
            orders: [],
            dials: {},
            type: TYPE === 'yachtas' ? 'yacht' : 'cruiser',
            isLoading: false,
        }
    },
    computed: {
        
    },
    methods: {
        async loadOrders() {
            const email = this.$store.user.details.email;
            const password = this.$store.user.details.loginToken;
            this.orders = await this.api.loadOrders(email, password);
        },
        logout() {
            this.$store.user.logout();
            this.$router.push('/prihlaseni/');
        }
    },
    async mounted() {
        if (!this.$store.user.isLogged) {
            this.$router.push('/prihlaseni/');
            return;
        }

        this.isLoading = true;
        await this.loadOrders();
        this.dials = await this.api.getDials();
        this.isLoading = false;
    },
    watch: {
        
    }
};
</script>