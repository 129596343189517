<template>
    <div class="reservation-side" :class="type">
        <div class="reservation-side__part">
            <h4 class="reservation-side__subheading">{{ destination }}</h4>
            <h3 class="reservation-side__heading" v-if="type === 'yacht'">
                {{ departurePort }}
            </h3>
            <h3 class="reservation-side__heading" v-else>
                {{ $t('LABELS.departure-from') }} {{ departurePort }}
            </h3>
            <div
                :class="`reservation-side__badges reservation-side__badges--${
                    type === 'yacht' ? 'yacht' : 'cruise'
                }`"
            >
                <div
                    class="reservation-side__badge"
                    :class="{
                        'reservation-side__badge--yacht': type === 'yacht',
                    }"
                >
                    <strong>{{ duration }}</strong
                    >&nbsp;{{ inflectWord('days', duration) }}
                </div>
                <div
                    :class="`reservation-side__badge reservation-side__badge--${theme}`"
                    v-if="type === 'yacht'"
                >
                    <svg
                        :class="`reservation-side__badge-icon reservation-side__badge-icon--${theme}`"
                    >
                        <use
                            :href="`/assets/imgs/icons/sprite.svg#${theme}`"
                        ></use>
                    </svg>
                    <strong>{{ themeName }}</strong>
                </div>
                <div class="reservation-side__cruiser" v-if="type !== 'yacht'">
                    <svg
                        v-if="cruiserName?.startsWith('Costa')"
                        class="reservation-side__cruiser-icon"
                    >
                        <use href="/assets/imgs/icons/sprite.svg#cruiser"></use>
                    </svg>
                    {{ cruiserName }}
                </div>
            </div>
            <div class="reservation-side__cruiser" v-if="type === 'yacht'">
                <svg
                    class="
                        reservation-side__cruiser-icon
                        reservation-side__cruiser-icon--yacht
                    "
                >
                    <use href="/assets/imgs/icons/sprite.svg#yacht"></use>
                </svg>
                {{ yachtType }}
            </div>
            <p
                class="reservation-side__itinerary"
                v-if="type !== 'yacht'"
                v-html="getItineraryMarkup(this.itinerary)"
            ></p>
        </div>
        <div class="reservation-side__part">
            <div class="reservation-side__date-part">
                <h3 class="reservation-side__date-heading">
                    {{ $t('LABELS.sail-date') }}
                </h3>
                <p class="yacht-reservation__date-text">
                    <span class="yacht-reservation__date-arrow">→</span>
                    {{ formatDate(departureDate) }}
                    {{ departureName }}
                </p>
                <p class="yacht-reservation__date-text">
                    <span class="yacht-reservation__date-arrow">←</span>
                    <span>
                        {{ formatDate(arrivalDate) }}
                        {{ arrivalName }}
                    </span>
                </p>
            </div>
        </div>
        <div class="reservation-side__part" v-show="!summaryPage">
            <h3 class="reservation-side__mini-heading">
                {{ $t('LABELS.cruise-crew-members-count') }}
            </h3>
            <div
                class="reservation-side__saved-info"
                v-show="crewSummary"
                v-for="(summaryItem, i) in summaryList"
                :key="i"
            >
                <span>{{ summaryItem[0] }}</span
                ><span class="nowrap">{{ summaryItem[1] }}</span>
            </div>
            <template v-if="!crewSummary">
                <div class="reservation-side__person">
                    <p class="reservation-side__person-text">
                        {{ $t('LABELS.adults-with-age') }}
                    </p>
                    <Counter
                        :min="1"
                        :max="maxAdults"
                        :initial="adultsNum"
                        @changed="setAdultsNum"
                    ></Counter>
                </div>

                <!-- For non-MSC, keep the original children counter -->
                <div class="reservation-side__person" v-if="!msc">
                    <p class="reservation-side__person-text">
                        {{ $t('LABELS.children-with-age') }}
                    </p>
                    <Counter
                        :min="0"
                        :max="maxChildren"
                        :initial="childrenNumInter"
                        @changed="setChildrenNum"
                    ></Counter>
                </div>
                <!-- For MSC, display counters for each age category -->
                <template v-else>
                    <div class="reservation-side__person">
                        <p class="reservation-side__person-text">
                            {{ $t('LABELS.children') }} (0-1)
                        </p>
                        <Counter
                            :min="0"
                            :max="maxChildren1"
                            :initial="children1NumInter"
                            @changed="setChildren1Num"
                        ></Counter>
                    </div>
                    <div class="reservation-side__person">
                        <p class="reservation-side__person-text">
                            {{ $t('LABELS.children') }} (2-11)
                        </p>
                        <Counter
                            :min="0"
                            :max="maxChildren11"
                            :initial="children11NumInter"
                            @changed="setChildren11Num"
                        ></Counter>
                    </div>
                    <div class="reservation-side__person">
                        <p class="reservation-side__person-text">
                            {{ $t('LABELS.children') }} (12-17)
                        </p>
                        <Counter
                            :min="0"
                            :max="maxChildren17"
                            :initial="children17NumInter"
                            @changed="setChildren17Num"
                        ></Counter>
                    </div>
                </template>
            </template>
            <a
                class="
                    wide-showcase-item__btn
                    btn
                    btn--blue
                    btn--standalone-small
                    btn--ml-auto
                    btn--w-fit
                    btn--block
                "
                @click="updatePeopleNum"
                >{{ $t('LABELS.save') }}</a
            >
        </div>
        <div
            class="reservation-side__part"
            v-if="crewSummary && !summaryPage && fullPrice"
        >
            <div class="reservation-side__final-price-part" v-if="fullPrice">
                <span class="reservation-side__final-price-label">{{
                    $t('LABELS.overall-price')
                }}</span>
                <span class="reservation-side__final-price"
                    >{{ formatPrice(finalPrice?.amount_decimal) }}
                    {{ finalPrice?.currency }}</span
                >
            </div>
        </div>
        <div class="reservation-side__part" v-if="summaryPage && fullPrice">
            <div
                class="
                    reservation-side__final-price-part
                    reservation-side__final-price-part--highlight
                "
            >
                <span class="reservation-side__final-price-label">{{
                    $t('LABELS.overall-price')
                }}</span>
                <span class="reservation-side__final-price"
                    >{{ formatPrice(finalPrice?.amount_decimal) }}
                    {{ finalPrice?.currency }}</span
                >
            </div>
        </div>
        <div class="reservation-side__part" v-if="summaryPage && portFee">
            <p class="reservation-side__note">
                {{
                    $t('TEXTS.port-fee-note', {
                        price: `${formatPrice(portFee?.amount_decimal)} ${
                            portFee?.currency
                        }`,
                    })
                }}
            </p>
        </div>
        <div class="reservation-side__part" v-if="summaryPage">
            <p class="reservation-side__note">
                {{ $t('TEXTS.mandatory-cruise-fee-note') }}
            </p>
        </div>
        <div class="reservation-side__part">
            <p class="reservation-side__more-info">
                {{ $t('TEXTS.info-about-not-included-in-price') }}
                <a href="/jak-to-funguje/">{{
                    $t('TEXTS.can-be-found-here')
                }}</a
                >.
            </p>
        </div>
    </div>
</template>

<script>
import Counter from '../components/Counter.vue';
import { Dictionary } from '../modules/Dictionary';
import formatPriceUtil from '../utilities/formatPrice';
import formatDateUtil from '../utilities/formatDate';
import formatItineraryMarkupUtil from '../utilities/formatItineraryMarkup';
import { LANGUAGE } from '@/env';

const dict = new Dictionary();

export default {
    components: {
        Counter,
    },
    props: [
        'destination',
        'departurePort',
        'departureName',
        'arrivalName',
        'duration',
        'itinerary',
        'departureDate',
        'arrivalDate',
        'adultsNum',
        'childrenNum',
        'crewSummary',
        'fullPrice',
        'portFee',
        'summaryList',
        'summaryPage',
        'type',
        'yachtType',
        'theme',
        'themeName',
        'cruiserName',
        'promo',
        'promoApplied',
        'msc',
    ],
    data() {
        return {
            adultsNumInter: this.adultsNum,
            childrenNumInter: this.childrenNum,
            children1NumInter: 0,
            children11NumInter: 0,
            children17NumInter: 0,
        };
    },
    methods: {
        formatPrice(price) {
            return formatPriceUtil(price);
        },
        formatDate(date) {
            return formatDateUtil(date);
        },
        inflectWord(word, num) {
            return dict.getWord(word, num, LANGUAGE);
        },
        getItineraryMarkup(itinerary) {
            return formatItineraryMarkupUtil(itinerary);
        },
        setAdultsNum(newAdultsNum) {
            this.adultsNumInter = newAdultsNum;
        },
        setChildrenNum(newChildrenNum) {
            this.childrenNumInter = newChildrenNum;
        },
        setChildren1Num(newChildren1Num) {
            this.children1NumInter = newChildren1Num;
        },
        setChildren11Num(newChildren11Num) {
            this.children11NumInter = newChildren11Num;
        },
        setChildren17Num(newChildren17Num) {
            this.children17NumInter = newChildren17Num;
        },
        updatePeopleNum() {
            const updatedData = {
                adultsNum: this.adultsNumInter,
            };
            if (this.msc) {
                updatedData.children1Num = this.children1NumInter;
                updatedData.children11Num = this.children11NumInter;
                updatedData.children17Num = this.children17NumInter;
            } else {
                updatedData.childrenNum = this.childrenNumInter;
            }
            this.$emit('updatedPeople', updatedData);
        },
    },
    computed: {
        maxAdults() {
            const totalChildren = this.msc
                ? this.children1NumInter +
                  this.children11NumInter +
                  this.children17NumInter
                : this.childrenNumInter;
            return 5 - totalChildren;
        },
        maxChildren() {
            return 5 - this.adultsNumInter;
        },
        maxChildren1() {
            const totalOthers =
                this.adultsNumInter +
                this.children11NumInter +
                this.children17NumInter;
            return 5 - totalOthers;
        },
        maxChildren11() {
            const totalOthers =
                this.adultsNumInter +
                this.children1NumInter +
                this.children17NumInter;
            return 5 - totalOthers;
        },
        maxChildren17() {
            const totalOthers =
                this.adultsNumInter +
                this.children1NumInter +
                this.children11NumInter;
            return 5 - totalOthers;
        },
        finalPrice() {
            if (this.promoApplied) {
                return {
                    amount_decimal:
                        this.fullPrice?.amount_decimal -
                        this.promo?.amount_decimal,
                    amount_int:
                        this.fullPrice?.amount_int - this.promo?.amount_int,
                    currency: this.fullPrice?.currency,
                    precision: this.fullPrice?.precision,
                };
            }
            return this.fullPrice;
        },
    },
};
</script>
