<template>
    <header class="sticky-header-yacht__header" v-show="showStickyHeader">
        <div class="sticky-header-yacht__header-part">
            <h3 class="sticky-header-yacht__header-heading">{{ $t('LABELS.sail-date') }}</h3>
            <p class="sticky-header-yacht__header-text"><span class="sticky-header-yacht__arrow">→</span>{{ formatDate(details.departureDate) }}</p>
            <p class="sticky-header-yacht__header-text"><span class="sticky-header-yacht__arrow">←</span>{{ formatDate(details.arrivalDate) }}</p>
        </div>

        <div class="sticky-header-yacht__header-part">
            <h3 class="sticky-header-yacht__header-heading">{{ $t('LABELS.total-time') }}</h3>
            <p class="sticky-header-yacht__header-text">{{ details.duration }} {{ daysInflected(details.duration) }}</p>
        </div>

        <div class="sticky-header-yacht__header-part sticky-header-yacht__header-part--type">
            <svg class="sticky-header-yacht__type-icon">
                <use href="/assets/imgs/icons/sprite.svg#yacht"></use>
            </svg>
            <p class="sticky-header-yacht__type-text">{{ langYachtTypes[details.type] }}</p>
        </div>

        <div class="sticky-header-yacht__header-part">
            <h3 class="sticky-header-yacht__header-heading">{{ $t('LABELS.cruise-theme') }}</h3>
            <div>
                <p :class="`sticky-header-yacht__theme sticky-header-yacht__theme--${details.theme}`"><svg :class="`sticky-header-yacht__theme-icon sticky-header-yacht__theme-icon--${details.theme}`"><use :href="`/assets/imgs/icons/sprite.svg#${details.theme}`"></use></svg>{{ details.themeName?.[LANGUAGE] }}</p>
            </div>
        </div>


        <div class="sticky-header-yacht__prices">
            <div class="sticky-header-yacht__price-wrapper" v-if="!details.isSoldOut?.cabin">
                <div
                    class="sticky-header-yacht__price sticky-header-yacht__price--discounted"
                    v-if="details.pricesFull?.cabin[this.$store.currency].amount_decimal > 0"
                    v-html="discountPriceMarkup('cabin')"
                ></div>
                <div class="sticky-header-yacht__price" v-else>{{ priceText('cabin') }}</div>
                <button
                    @click="redirectToReservation('cabin')"
                    class="sticky-header-yacht__price-btn"
                >{{ $t('LABELS.book-cabin') }}</button>
                <Tooltip :text="$t('TEXTS.cabin-booking-hint')" :triangle-in="true"></Tooltip>
            </div>
            <div class="sticky-header-yacht__price-wrapper" v-if="!details.isSoldOut?.single">
                <div
                    class="sticky-header-yacht__price sticky-header-yacht__price--discounted"
                    v-if="details.pricesFull?.single[this.$store.currency].amount_decimal > 0"
                    v-html="discountPriceMarkup('single')"
                ></div>
                <div class="sticky-header-yacht__price" v-else>{{ priceText('single') }}</div>
                <button
                    @click="redirectToReservation('single')"
                    class="sticky-header-yacht__price-btn"
                >{{ $t('LABELS.book-single-place') }}</button>
                <Tooltip :text="$t('TEXTS.single-place-booking-hint')" :triangle-in="true"></Tooltip>
            </div>
        </div>
    </header>
</template>
<script>

import formatDateUtil from '../utilities/formatDate';
import formatPriceUtil from '../utilities/formatPrice'
import findRefsComponent from '../utilities/findRefsComponent'
import Tooltip from '../components/Tooltip'
import { Dictionary } from '../modules/Dictionary'

import { LANGUAGE } from '@/env'

const dict = new Dictionary;

export default {
    components: {
        Tooltip
    },
    props: {
        details: Object,
        urlCrewSearch: String
    },
    data() {
        return {
            showStickyHeader: false,
            LANGUAGE: LANGUAGE,
        }
    },
    methods: {
        daysInflected(days_num) {
            return dict.getWord('days', days_num, LANGUAGE);
        },
        formatDate(date) {
            return formatDateUtil(date)
        },
        formatPrice(price) {
            return formatPriceUtil(price)
        },
        discountPriceMarkup(type) {
            return `
                <span class="sticky-header-yacht__price-discounted">${this.formatPriceText(this.details?.prices[type][this.$store.currency])}</span>
                <span class="sticky-header-yacht__price-original">${this.formatPriceText(this.details?.pricesFull[type][this.$store.currency])}</span>
            `;
        },
        formatPriceText(price) {
            return `${this.formatPrice(price.amount_decimal)} ${price.currency}`;
        },
        priceText(type) {
            if (this.details.prices && this.details.freeUnits) {
                if (this.details.isSoldOut?.[type]) {
                    return this.$t('LABELS.soldout');
                }
                return `${this.formatPrice(this.details.prices[type][this.$store.currency].amount_decimal)} ${this.details.prices[type][this.$store.currency].currency}`;
            }
            return '';
        },
        redirectToReservation(type) {
            if (this.details.isSoldOut?.[type]) {
                return false;
            }

            const urls = {
                single: `/rezervace/${this.details.code}/rezervace-mist/${this.urlCrewSearch}`,
                cabin: `/rezervace/${this.details.code}/rezervace-kajut/${this.urlCrewSearch}`,
                yacht: `/rezervace/${this.details.code}/rezervace-lodi/${this.urlCrewSearch}`
            }
            
            this.$router.push(urls[type])
        },
    },
    computed: {
        langYachtTypes() {
            return {
                sailboat: this.$t('LABELS.yacht'),
                catamaran: this.$t('LABELS.catamaran'),
            }
        }
    },
    mounted() {
        document.addEventListener('scroll', () => {
            const triggerRefsComponent = findRefsComponent(this, 'sticky-header-trigger');
            const offsetRefsComponent = findRefsComponent(this, 'sticky-header-offset');

            if (triggerRefsComponent && offsetRefsComponent) {
                const triggerHeight = triggerRefsComponent.$refs['sticky-header-trigger'].scrollHeight;
                const offset = offsetRefsComponent.$refs['sticky-header-offset'].scrollHeight;
                
                this.showStickyHeader = window.scrollY > triggerHeight + offset;
            }
        })
    }
}
</script>