import { createRouter, createWebHistory } from 'vue-router';
import { yachtasRoutes, cruisesRoutes } from './routes';

export const yachtas = createRouter({
  history: createWebHistory(),
  routes: yachtasRoutes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0 };
  },
});

export const cruises = createRouter({
  history: createWebHistory(),
  routes: cruisesRoutes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0 };
  },
});
