<template>
    <nav :class="`nav nav--${type} ${fixedNav && fixedAllowed ? 'fixed' : ''}`">
        <div class="nav__inner">
            <router-link to="/" class="nav__logo-container">
                <img
                    src="/assets/imgs/logo/nautyy_white_row.png"
                    alt="Nautyy"
                    class="nav__logo nav__logo--cruises"
                />
            </router-link>
            <MainSearch v-if="fixedNav && fixedAllowed"> </MainSearch>
            <ul class="nav__menu menu" v-else>
                <li class="menu__item menu__item--email">
                    <svg class="menu__icon menu__icon--email">
                        <use href="/assets/imgs/icons/sprite.svg#email"></use>
                    </svg>
                    <span class="menu__txt menu__txt--larger menu__txt--tel"
                        ><a
                            :href="`mailto:${$globalContact[newTypeName][LANGUAGE].email}`"
                            class="menu__link menu__link--hidden"
                            >{{
                                $globalContact[newTypeName][LANGUAGE].email
                            }}</a
                        ></span
                    >
                </li>
                <li class="menu__item menu__item--tel">
                    <svg class="menu__icon menu__icon--tel">
                        <use href="/assets/imgs/icons/sprite.svg#tel"></use>
                    </svg>
                    <span class="menu__txt menu__txt--larger menu__txt--tel"
                        ><a
                            :href="`tel:${$globalContact[newTypeName][LANGUAGE].phone}`"
                            class="menu__link menu__link--hidden"
                            >{{
                                $globalContact[newTypeName][LANGUAGE].phone
                            }}</a
                        ></span
                    >
                </li>
                <li class="menu__item">
                    <a :href="otherLangUrl" class="menu__link menu__link--flag">
                        <img
                            :src="otherLangFlagUrl"
                            alt=""
                            class="menu__flag"
                        />
                    </a>
                    <Tooltip
                        :text="
                            $t('LABELS.change-to-version', {
                                version: otherLang.toUpperCase(),
                            })
                        "
                    ></Tooltip>
                </li>
                <li class="menu__item">
                    <span class="menu__txt">
                        <router-link
                            to="/uzivatel/"
                            v-if="$store.user.isLogged"
                            :title="$store.user.getUsername()"
                            class="menu__link"
                        >
                            <svg class="menu__profile-icon">
                                <use
                                    href="/assets/imgs/icons/sprite.svg#person"
                                ></use>
                            </svg>
                            <span class="menu__profile-text">{{
                                $store.user.getUsername(true)
                            }}</span>
                        </router-link>
                        <router-link
                            to="/prihlaseni/"
                            v-else
                            class="menu__link"
                        >
                            <svg class="menu__profile-icon">
                                <use
                                    href="/assets/imgs/icons/sprite.svg#person"
                                ></use>
                            </svg>
                            <span class="menu__profile-text">{{
                                $t('LABELS.login')
                            }}</span>
                        </router-link>
                        <button
                            class="menu__hamburger-btn"
                            @click="toggleMobileMenu"
                        >
                            <svg class="menu__hamburger-icon">
                                <use
                                    href="/assets/imgs/icons/sprite.svg#hamburger"
                                ></use>
                            </svg>
                        </button>
                    </span>
                </li>
            </ul>
        </div>
        <div :class="`sub-menu sub-menu--${TYPE}`" v-if="subMenu?.length > 0">
            <div
                v-for="(item, i) in subMenu"
                :key="i"
                class="sub-menu__item-wrapper"
            >
                <a
                    :href="item.url"
                    class="sub-menu__item"
                    v-if="!item.children || item.children.length === 0"
                >
                    {{ item.name[LANGUAGE] }}
                </a>
                <div v-else class="sub-menu__dropdown">
                    <a v-if="item.url" :href="item.url" class="sub-menu__item">
                        {{ item.name[LANGUAGE] }}
                    </a>
                    <span v-else class="sub-menu__item">
                        {{ item.name[LANGUAGE] }}
                    </span>
                    <div class="sub-menu__dropdown-content">
                        <a
                            v-for="(child, j) in item.children"
                            :key="j"
                            :href="child.url"
                            class="sub-menu__dropdown-item"
                        >
                            {{ child.name[LANGUAGE] }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </nav>
    <MainNavMobile
        :type="type"
        :is-opened="isMobileMenuOpened"
        :sub-menu="subMenu"
        @closed="toggleMobileMenu"
    ></MainNavMobile>
</template>

<script>
import MainNavMobile from './MainNavMobile.vue';
import Tooltip from './Tooltip.vue';
import { Api } from '../modules/Api';

import MainSearch from '@/components/MainSearch';
import subMenuCruises from '@/storage/sub-menu-cruises.json';

import { TYPE, LANGUAGE } from '@/env';

export default {
    components: {
        MainNavMobile,
        Tooltip,
        MainSearch,
    },
    props: {
        fixedAllowed: {
            default: false,
        },
        type: {
            default: 'cruiser',
        },
    },
    data() {
        return {
            TYPE: TYPE,
            LANGUAGE: LANGUAGE,
            api: new Api(this.$t),
            isMobileMenuOpened: false,
            otherLang: LANGUAGE !== 'cs' ? 'cz' : 'sk',
            subMenu: [],
            fixedNav: false,
            dropdownIndex: null,
        };
    },
    computed: {
        newTypeName() {
            return TYPE;
        },
        otherLangUrl() {
            const newDomain =
                location.host.slice(0, location.host.length - 2) +
                this.otherLang;
            const newUrl = location.href.replace(location.host, newDomain);
            return newUrl;
        },
        otherLangFlagUrl() {
            const otherLangName = this.otherLang === 'cz' ? 'czech' : 'slovak';
            const otherFlagUrl = `/assets/imgs/flag-${otherLangName}.png`;
            return otherFlagUrl;
        },
    },
    methods: {
        handleScroll() {
            this.fixedNav = window.scrollY > 700;
        },
        toggleMobileMenu() {
            this.isMobileMenuOpened = !this.isMobileMenuOpened;
        },
        async loadSubMenu() {
            if (TYPE === 'cruises') {
                this.subMenu = subMenuCruises;
            } else {
                this.subMenu = await this.api.getMenu(TYPE, LANGUAGE);
            }
        },
        showDropdown(index) {
            this.dropdownIndex = index;
        },
        hideDropdown() {
            this.dropdownIndex = null;
        },
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    mounted() {
        this.loadSubMenu();
        window.addEventListener('scroll', this.handleScroll);
    },
};
</script>
