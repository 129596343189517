import { yachtasStoreKeys } from './index';
import store from 'storejs';
import * as _ from 'lodash';
import { getPhonePrefix } from '@/utilities/location';

/**
 * Create passenger for store
 * @param passengerType {"irresponsible"|"responsible"}
 */
export const createPassengerForStore = (passengerType = 'irresponsible') => {
    const passenger = {
        type: passengerType,
        firstName: '',
        lastName: '',
        transport: {
            car: false,
            plane: false
        }
    };

    if (passengerType !== 'responsible') return passenger;

    return {
        ...passenger,
        email: '',
        phone: '',
        prefix: getPhonePrefix()
    };
};

/**
 * Validate passengers by object keys
 * @param passengers {Object[]}
 * @return {Object[]}
 */
const validatePassengers = (passengers) => {
    const irresponsibleValidKeys = ['type', 'firstName', 'lastName', 'transport'].sort();
    const responsibleValidKeys = [...irresponsibleValidKeys, 'email', 'phone', 'prefix'].sort();

    const transportValidKeys = ['plane', 'car'].sort();

    return _.filter(passengers, passenger => {
        const passengerKeys = _.keys(passenger).sort();
        const passengerTransportKeys = _.keys(passenger.transport).sort();

        let isValid = false;

        if (passenger.type === 'irresponsible') {
            isValid = _.isEqual(passengerKeys, irresponsibleValidKeys);
        } else if (passenger.type === 'responsible') {
            isValid = _.isEqual(passengerKeys, responsibleValidKeys);
        }

        if (isValid && passenger.transport) {
            isValid = _.isEqual(passengerTransportKeys, transportValidKeys);
        }

        return isValid;
    });
};

/**
 * Return passengers from localstorage
 * @return {Object}
 */
export const getPassengersFromStore = () => {
    return store.get(yachtasStoreKeys.passengers) || {};
};

/**
 * Get passengers from localstorage by cruiseCode
 * @param cruiseCode {string}
 * @return Array
 */
export const getPassengersFromStoreByCode = (cruiseCode) => {
    const passengers = getPassengersFromStore()[cruiseCode] || [];
    return validatePassengers(passengers);
};

/**
 * Set passengers to localstorage for order completion
 * @param cruiseCode {string}
 * @param passengers {Object}
 * @return Array
 */
export const setPassengersToStoreByCode = (cruiseCode, passengers) => {
    const passengersStore = getPassengersFromStore();

    passengersStore[cruiseCode] = passengers;

    store.set(yachtasStoreKeys.passengers, passengersStore);
    return passengersStore[cruiseCode];
};

export const resetPassengersStore = () => {
    store.set(yachtasStoreKeys.passengers, {});
};

/**
 * Update passengers with logged user data
 * @param passengers Object
 * @param data {{firstName: string, lastName: string, email: string, phone: string}}
 * @return Object
 */
export const updatePassengersWithLoggedUser = (passengers = {}, data) => {
    const forcedPassenger = createPassengerForStore('responsible');

    _.forEach(passengers, (passenger, i) => {
        if (passenger.type === 'responsible') {
            passengers[i] = { ...forcedPassenger, ...passengers[i], ...data };
        }
    });

    return passengers;
};