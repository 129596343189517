<template>
    <div :class="`new-video-yt new-video-yt--${type}`">
        <div v-if="!showYT" class="new-video-yt__preview-container">
            <img :src="previewUrl" class="new-video-yt__preview-img">
            <div class="new-video-yt__play-container" @click="showVideo()">
                <svg class="new-video-yt__play-icon">
                    <use href="/assets/imgs/icons/sprite.svg#play-circle"></use>
                </svg>
            </div>
        </div>
        <iframe v-if="showYT || loadYT" class="new-video-yt__video" :class="{'new-video-yt__video--bg-load': loadYT && !showYT}" :src="`${ytUrl}?autoplay=1`" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
</template>
<script>
export default {
    props: { type: String, ytUrl: String, previewUrl: String },
    methods: {
        showVideo() {
            this.loadYT = true;
            setTimeout(() => {
                this.showYT = true;
            }, 200)
        }
    },
    data() {
        return {
            showYT: false,
            loadYT: false
        }
    }
}
</script>