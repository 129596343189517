<template>
    <Head>
        <title>YACHTA'S</title>
    </Head>

    <header class="header-detail">
        <Main-nav type="yacht"></Main-nav>
    </header>
    <main class="main reservation">
        <div class="reservation__inner">
            <Notification :text="error.text?.[LANGUAGE]" v-show="error.show" @closed="closeNotification" type="error"></Notification>
            <Reservation-nav :currentLevel="1" type="yacht" reservation-type="yacht"></Reservation-nav>
            <div class="reservation__content reservation__content--summary">
                <h2 class="reservation__heading reservation__heading--yacht">{{ $t('TEXTS.how-many-ships-to-reserve') }}</h2>
                <div class="yacht-reservation">
                    <ReservationSideYacht :yacht-details="yachtDetails"></ReservationSideYacht>
                    <div class="yacht-reservation__part yacht-reservation__part--overview">
                        <ReservationUnitsCounter
                            :title="$t('TEXTS.fill-how-many-ships-to-reserve')"
                            :counter-note="$t('LABELS.empty-ship-count')"
                            :price-note="$t('TEXTS.every-bed-is-in-2-room')"
                            :price="this.yachtDetails.prices?.yacht[this.$store.currency]"
                            :free-units="yachtDetails.freeUnits?.yacht"
                            @changed="updateBookedUnits"
                            @error="showError"
                        ></ReservationUnitsCounter>
                    </div>
                    <div class="yacht-reservation__part yacht-reservation__part--submit">
                        <router-link :to="`/rezervace/${cruise_code}/shrnuti/?type=yacht&amount=${bookedUnits}`" class="btn btn--orange btn-full-width yacht-reservation__submit-btn">{{ $t('LABELS.to-summary') }}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <Footer type="yachtas"></Footer>
    <Loading :is-loading="isLoading" :label="$t('STATUSES.loading-reservation-info')"></Loading>
</template>

<script>
import Footer from '../../components/Footer';
import MainNav from '../../components/MainNav';
import ReservationSideYacht from '../../components/ReservationSideYacht';
import ReservationUnitsCounter from '../../components/ReservationUnitsCounter';
import ReservationNav from '../../components/ReservationNav.vue';
import { Api } from '../../modules/Api'
import Loading from '../../components/Loading.vue';
import Notification from '../../components/Notification.vue';

import { Head } from '@vueuse/head'

import { LANGUAGE } from '@/env'

export default {
    props: [ 'cruise_code' ],
    data() {
        return {
            LANGUAGE: LANGUAGE,
            api: new Api(this.$t),
            bookedUnits: this.$route.query.amount ? this.$route.query.amount : 1,
            yachtDetails: {},
            isLoading: false,
            error: {
                show: false,
                text: {
                    cs: [],
                    sk: []
                }
            }
        }
    },
    methods: {
        async loadYachtDetail() {
            this.yachtDetails = await this.api.getYachtDetail(this.cruise_code);
        },
        updateBookedUnits(num) {
            this.bookedUnits = num;
        },
        closeNotification() {
            this.error.show = false;
            this.error.text = {
                cs: [],
                sk: []
            }
        },
        showError(err) {
            this.error.show = true;
            this.error.text = err.text;
        }
    },
    components: {
        Footer,
        MainNav,
        ReservationNav,
        ReservationUnitsCounter,
        ReservationSideYacht,
        Loading,
        Notification,
        Head
    },
    async mounted() {
        this.isLoading = true;
        await this.loadYachtDetail();
        this.isLoading = false;
    }
};
</script>