import { v4 as uuid } from 'uuid';
import * as _ from 'lodash';

/**
 * Add id in uuid format to array of objects
 * @param array {Array<object>}
 */
export const withGeneratedIds = (array) => {
    return _.map(array, obj => ({ ...obj, id: uuid() }));
};

/**
 * Check if property name exist in object
 * @param propertyName {string}
 * @param object {Object}
 * @return {boolean}
 */
export const isPropertyInObject = (propertyName, object) => {
    return propertyName in object;
};

/**
 * Check if email address is valid
 * @param email {string}
 * @return {boolean}
 */
export const isEmailValid = (email) => {
    const reg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return reg.test(email);
};