<template>
    <a
        :href="url"
        :class="{
            'showcase-item': itemType === 'vertical',
            'showcase-item--link': itemType === 'vertical',
            'wide-showcase-item': itemType === 'horizontal',
            'wide-showcase-item--link': itemType === 'horizontal'
        }"
        v-if="forceHtmlLink && type === 'yacht' || type === 'cruises-delegate'"
    >
        <slot></slot>
    </a>
    <router-link
        :to="url"
        :class="{
            'showcase-item': itemType === 'vertical',
            'showcase-item--link': itemType === 'vertical',
            'wide-showcase-item': itemType === 'horizontal',
            'wide-showcase-item--link': itemType === 'horizontal'
        }"
        v-else-if="type === 'yacht' || type === 'cruises-delegate'"
    >
        <slot></slot>
    </router-link>
    <article
        :class="{
            'showcase-item': itemType === 'vertical',
            'wide-showcase-item': itemType === 'horizontal'
        }"
        v-else
    >
        <slot></slot>
    </article>
</template>
<script>
export default {
    props: [ 'type', 'itemType', 'url', 'forceHtmlLink' ]
}
</script>