<template>
    <div :style="{background: `url('${backgroundImgUrl}') center`, backgroundSize: 'cover'}" class="content-hero">
        <div class="content-hero__dash">
            <h1 class="content-hero__title">{{title}}</h1>
            <h3 class="content-hero__sub-title">{{subTitle}}</h3>
        </div>
    </div>
</template>
<script>
export default {
    props: { backgroundImgUrl: String, title: String, subTitle: String }
}
</script>