<template>
    <Head>
        <title>COSTA Cruise's</title>
    </Head>

    <header class="header-detail">
        <Main-nav></Main-nav>
    </header>
    <main class="main reservation">
        <div class="reservation__inner">
            <Reservation-nav :current-level="2"></Reservation-nav>
            <div class="reservation__content">
                <div class="reservation__column">
                    <h2 class="reservation__heading">{{ $t('LABELS.you-choose-cabin') }}</h2>
                    <Cabin
                        v-for="cabin in cabins"
                        :key="cabin.code"
                        :code="cabin.code"
                        :images="cabin.images"
                        :name="cabin.name?.[LANGUAGE]"
                        :features="cabin.features[LANGUAGE]"
                        :price="cabin.price[$store.currency]"
                        :peoplePerPrice="workflow.guest_count_adult"
                        :availability="cabin.availability"
                        @choseCabin="chooseCabin"
                    ></Cabin>
                </div>
                <div class="reservation__column">
                    <h2 class="reservation__heading">{{ $t('LABELS.trip-info') }}</h2>
                    <ReservationSide
                        :destination="overview.destination"
                        :departurePort="overview.departurePortName[LANGUAGE]"
                        :departureDate="overview.departureDate"
                        :arrivalDate="overview.arrivalDate"
                        :duration="overview.duration"
                        :itinerary="overview.itinerary[LANGUAGE]"
                        :crewSummary="true"
                        :summaryList="overview.summaryList"
                        :cruiserName="workflow.cruise?.ship_name[LANGUAGE]"
                    ></ReservationSide>
                </div>
            </div>
        </div>
    </main>
    <Footer></Footer>
    <Loading :is-loading="isLoading" :label="$t('STATUSES.loading-cabins')"></Loading>
    <Loading :is-loading="!isLoading && saving" :label="$t('STATUSES.saving-cabin')"></Loading>
</template>

<script>
import camelCase from 'camelcase'

import Footer from '../../components/Footer';
import MainNav from '../../components/MainNav';
import ReservationNav from '../../components/ReservationNav.vue';
import Cabin from '../../components/Cabin.vue';
import ReservationSide from '../../components/ReservationSide.vue';
import { Api } from '../../modules/Api'
import Loading from '../../components/Loading.vue';

import { Head } from '@vueuse/head'

import { LANGUAGE } from '@/env';

export default {
    components: {
        Footer,
        MainNav,
        ReservationNav,
        Cabin,
        ReservationSide,
        Loading,
        Head
    },
    data() {
        return {
            isLoading: false,
            saving: false,
            api: new Api(this.$t),
            LANGUAGE: LANGUAGE,
            overview: {
                cruiseCode: this.$route.params.cruise_code,
                destination: '',
                departurePortName: {},
                departureDate: '',
                arrivalDate: '',
                duration: 0,
                itinerary: {
                    cs: [],
                    sk: []
                },
                adultsNum: 0,
                childrenNum: 0,
                summaryList: [],
                additionalCost: {
                    czk: {},
                    eur: {}
                },
            },
            cabins: [
                
            ],
            noCabins: false,
            workflow: {}
        }
    },
    methods: {
        async handleWorkflow() {
            const workflows = localStorage.getItem('workflows') ? JSON.parse(localStorage.getItem('workflows')) : {};
            const currentWorkflowId = workflows[this.overview.cruiseCode] ? workflows[this.overview.cruiseCode] : this.$router.push({path: `/rezervace/${this.overview.cruiseCode}/kajuta-a-ubytovaci-balicek/`})

            this.overview.workflowId = currentWorkflowId;
        },
        async loadCabins() {
            const {cabins, workflow, cruise} = await this.api.getCabins(this.overview.workflowId);

            if (!cabins || !workflow || !cruise) {
                this.$router.push({path: `/rezervace/${this.overview.cruiseCode}/kajuta-a-ubytovaci-balicek/`, hash: '#generic-error'})
            }

            this.cabins = cabins;
            this.workflow = workflow;

            Object.keys(cruise).map(key => {
                if (key === 'days') {
                this.overview.duration = cruise[key]
                } else {
                    this.overview[camelCase(key)] = cruise[key]
                }
            })
        },
        async chooseCabin(cabinCode) {
            const choseCabin = this.cabins.find(cabin => cabin.code === cabinCode);
            const cabinPrice = choseCabin.price.eur.amount_int;
            this.saving = true;
            await this.api.getOffers(this.overview.workflowId, cabinCode, cabinPrice);
            this.saving = false;
            this.$router.push({path: `/rezervace/${this.overview.cruiseCode}/shrnuti/`})
        }
    },
    async mounted() {
        this.isLoading = true;
        await this.handleWorkflow();
        await this.loadCabins();
        this.isLoading = false;

        if (this.cabins.length === 0) {
            this.noCabins = true;
            this.$router.push(`/rezervace/${this.overview.cruiseCode}/bez-kajut/`)
        }
    }
};
</script>