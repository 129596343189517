<template>
    <div class="counter" :class="{'counter--with-price': price}">
        <p class="counter__heading" v-if="title">{{ title }}</p>
        <div class="counter__container">
            <button
                class="counter__btn"
                :class="{'counter__btn--disabled': isDisabledDecr}"
                @click="remove()"
            >-</button>
            <span class="counter__num">{{counter}}</span>
            <button
                class="counter__btn"
                :class="{'counter__btn--disabled': isDisabledIncr}"
                @click="add()"
            >+</button>
        </div>
        <p class="counter__heading counter__heading--uppercase" v-show="price">{{ $t('LABELS.overall-price') }}</p>
        <div class="counter__container" v-show="price">
            <span class="counter__num">{{ formatPrice(price?.amount_decimal * counter) }} {{ price?.currency }}</span>
        </div>
    </div>
</template>

<script>
import formatPriceUtil from '../utilities/formatPrice'

export default {
    props: {
        initial: {
            type: Number,
            default: 0
        },
        min: {
            type: Number,
            default: -Infinity
        },
        max: {
            type: Number,
            default: Infinity
        },
        title: String,
        newVal: Number,
        price: Object
    },
    data() {
        return {
            counter: this.initial
        }
    },
    computed: {
        isDisabledDecr() {
            return this.min >= this.counter;
        },
        isDisabledIncr() {
            return this.max <= this.counter;
        }
    },
    methods: {
        add() {
            if (this.counter < this.max) {
                this.counter++;
            }
        },
        remove() {
            if (this.counter > this.min) {
                this.counter--;
            }
        },
        formatPrice(price) {
            return formatPriceUtil(price)
        },
    },
    watch: {
        counter(newVal) {
            this.$emit('changed', newVal);
        },
        newVal(newVal) {
            console.log("QWE");
            this.counter = newVal
        }
    }
}
</script>