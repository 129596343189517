<template>
    <section class="flights-dash">
        <header class="flights-dash__header">
            <img src="/assets/imgs/flights.jpg" class="flights-dash__header-img">
        </header>
        <main class="flights-dash__main">
            <div class="flights-dash__content">
                <h2 class="flights-dash__heading">
                    <span class="flights-dash__sub-heading">{{ $t('LABELS.no-worries-both-way') }}</span>
                    {{ $t('LABELS.return-air-ticket') }}
                </h2>
                <label class="flights-dash__airport-label">{{ $t('LABELS.choose-airport') }}</label>
                <v-select
                    class="flights-dash__select"
                    :options="airports"
                    :reduce="airport => airport.code"
                    label="text"
                    v-model="airport"
                ></v-select>
            </div>
            <div class="flights-dash__texts">
                <p class="flights-dash__text">{{ $t('TEXTS.no-worry-travel') }}</p>
                <p class="flights-dash__text">{{ $t('TEXTS.if-not-want-air-ticket') }}</p>
                <p class="flights-dash__text">{{ $t('TEXTS.air-ticket-handled-by-costa') }}</p>
                <p class="flights-dash__text"><strong>{{ $t('TEXTS.air-ticket-with-transfer') }}</strong></p>
            </div>
        </main>
    </section>
</template>
<script>
import vSelect from "vue-select";

export default {
    props: [ 'airports' ],
    emits: [ 'updated' ],
    data() {
        return {
            airport: null
        }
    },
    components: {
        vSelect,
    },
    watch: {
        airports(newAirports) {
            let updatedAirport = this.airport;
            
            const preselectedAirport = newAirports.find(airport => airport.selected);
            const currentAirport = newAirports.find(airport => airport.code === airport);
            if (preselectedAirport) {
                updatedAirport = preselectedAirport;
            } else if (currentAirport) {
                updatedAirport = currentAirport;
            } else {
                updatedAirport = newAirports[0];
            }

            this.airport = updatedAirport.code;
        },
        airport(newVal) {
            this.$emit('updated', newVal);
        }
    }
}
</script>