let templatesRouter = [
    // START OF TEMPLATE DEFINITION
    {
        filename: 'TemplatePage',
        path: '/testovaci-cesta/'
    },
    // END OF TEMPLATE DEFINITION
    //---------------------------
    // COPY NEW ROUTES UNDER ME
]





















// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !!! DO NOT TOUCH CODE BELOW !!!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
templatesRouter = templatesRouter.map(route => {
    try {
        const RoutePage = require(`./pages/templates/${ route.filename }.vue`);
    
        return {
            path: route.path,
            component: RoutePage.default
        }
    } catch {
        return null
    }
}).filter(route => route !== null)

export default templatesRouter