<template>
    <Head>
        <title>MSC Cruise's</title>
    </Head>
    <header class="header-detail">
        <Main-nav></Main-nav>
    </header>
    <main>{{ cruiseData }}</main>
    <Footer></Footer>
    <Loading
        :is-loading="isLoading"
        :label="$t('STATUSES.loading-trip-info')"
    ></Loading>
</template>

<script>
import Footer from '../../components/Footer';
import MainNav from '../../components/MainNav';
import { Api } from '../../modules/Api';
import Loading from '../../components/Loading.vue';

import { Head } from '@vueuse/head';

export default {
    components: {
        Footer,
        MainNav,
        Loading,
        Head,
    },
    data() {
        return {
            isLoading: false,
            api: new Api(this.$t),
            cruiseCode: this.$route.params.cruise_code,
            cruiseData: [],
        };
    },
    async mounted() {
        this.isLoading = true;
        this.cruiseData = await this.api.getMSCCruise(this.cruiseCode);
        this.isLoading = false;
    },
};
</script>
