<template>
    <div class="cookiesbar-accordion">
        <button class="cookiesbar-accordion__header" :class="{'cookiesbar-accordion__header__open': isOpen, 'cookiesbar-accordion__header__close': !isOpen}" @click="openCloseAccordion()">
            {{ name }}
            <label class="cookiesbar-accordion__switch">
                <input type="checkbox" class="cookiesbar-accordion__switch-checkbox" :disabled="isDisabled" :checked="isChecked" @click="setChecked()">
                <span class="cookiesbar-accordion__switch-slider" :class="{'cookiesbar-accordion__switch-slider__disabled': isDisabled}"></span>
            </label>
        </button>
        <div class="cookiesbar-accordion__panel" :class="{'cookiesbar-accordion__panel__hidden': !isOpen}">
            {{ text }}
        </div>
    </div>
</template>

<script>

export default {
    props: {
        name: String,
        text: String,
        isDisabled: Boolean,
        isChecked: Boolean,
    },
    data() {
        return {
            isOpen: false,
            allow: this.isChecked,
        }
    },
    methods: {
        openCloseAccordion() {
            this.isOpen = !this.isOpen;
        },
        setChecked() {
            this.allow = !this.allow;
            this.$emit('change-switch', this.allow);
        }
    }
}
</script>
