export default (currentComponent, ref) => {
    let refsParent = currentComponent;
    for (let i = 0; i < 10 && refsParent; i++) {
        if (refsParent.$refs[ref]) {
            return refsParent;
        }

        refsParent = refsParent.$parent;
    }

    return null;
}