<template>
    <Head>
        <title v-if='type === "yacht"'>
            YACHTA'S
        </title>
        <title v-else>
            COSTA Cruise's
        </title>
    </Head>
    <header class="header-detail" ref="header-detail">
        <Main-nav :type="type"></Main-nav>
    </header>
    <main :class="`main main--${type} login-form__container`">
        <Notification :text="error.text[LANGUAGE]" v-show="error.show" :initConfig="{small: true}" @closed="closeNotification" :type="error.type"></Notification>
        <form class="login-form" @submit.prevent="recoverPassword">
            <h1 :class="`login-form__heading login-form__heading--${type}`">{{ $t('LABELS.recover-password') }}</h1>
            <div class="login-form__input-container">
                <label for="email" class="login-form__label">{{ $t('LABELS.email') }}</label>
                <input type="email" name="email" id="email" class="login-form__input" required v-model="email">
            </div>
            <div class="login-form__input-container">
                <label for="email" class="login-form__label">{{ $t('LABELS.password') }}</label>
                <input type="password" name="password" id="password" class="login-form__input" required v-model="password" :disabled="passwordUpdated">
            </div>
            <div class="login-form__input-container">
                <label for="passwordAgain" class="login-form__label">{{ $t('LABELS.repeat-password') }}</label>
                <input type="password" name="passwordAgain" id="passwordAgain" class="login-form__input" required v-model="passwordAgain" :disabled="passwordUpdated">
            </div>
            <input type="submit" :value="$t('LABELS.recover-password')" class="login-form__submit-btn btn btn--full-width btn--orange">
            <div class="login-form__links">
                <router-link to="/prihlaseni/" class="login-form__link">{{ $t('LABELS.you-login') }}</router-link>
                <router-link to="/registrace/" class="login-form__link">{{ $t('LABELS.you-register') }}</router-link>
            </div>
        </form>
    </main>
    <Footer></Footer>
</template>

<script>
import Footer from '../components/Footer';
import MainNav from '../components/MainNav';
import { Api } from '../modules/Api'
import Notification from '../components/Notification.vue'

import { Head } from '@vueuse/head'

import { TYPE, LANGUAGE } from '@/env'

export default {
    components: {
        Footer,
        MainNav,
        Notification,
        Head
    },
    props: ['recovery_token'],
    data() {
        return {
            api: new Api(this.$t),
            LANGUAGE: LANGUAGE,
            passwordUpdated: false,
            password: '',
            passwordAgain: '',
            email: '',
            error: {
                show: false,
                type: '',
                text: {
                    cs: [],
                    sk: []
                }
            },
            type: TYPE === 'yachtas' ? 'yacht' : 'cruiser'
        }
    },
    methods: {
        async recoverPassword() {
            this.closeNotification();

            if (this.password === '' || this.passwordAgain === '' || this.email === '') {
                this.error.show = true;
                this.error.type = 'error';
                this.error.text = {
                    cs: [this.$t('ERRORS.fill-required')],
                    sk: [this.$t('ERRORS.fill-required')]
                }
                return false;
            }

            if (this.password !== this.passwordAgain) {
                this.error.show = true;
                this.error.type = 'error';
                this.error.text = {
                    cs: [this.$t('ERRORS.different-passwords')],
                    sk: [this.$t('ERRORS.different-passwords')]
                }
                return false;
            }

            const status = await this.api.updateUser(this.email, this.recovery_token, { password: this.password });

            if (status) {
                this.error.show = true;
                this.error.type = 'success';
                this.error.text = {
                    cs: [this.$t('STATUSES.password-successfully-changed')],
                    sk: [this.$t('STATUSES.password-successfully-changed')],
                }

                this.email = '';
                this.password = '';
                this.passwordAgain = '';
                this.passwordUpdated = true;
                return true;
            } else {
                this.error.show = true;
                this.error.type = 'error';
                this.error.text = {
                    cs: [this.$t('ERRORS.cannot-change-password')],
                    sk: [this.$t('ERRORS.cannot-change-password')]
                }

                return false;
            }
        },
        closeNotification() {
            this.error.show = false;
            this.error.type = '';
            this.error.text = {
                cs: [],
                sk: []
            }
        }
    },
    async mounted() {
        if (this.$store.user.isLogged) {
            this.$router.push('/uzivatel/');
            return;
        }
    },
    computed: {
        
    },
    watch: {
        
    }
};
</script>