<template>
    <Head>
        <title v-if='type === "yacht"'>
            YACHTA'S
        </title>
        <title v-else>
           COSTA Cruise's
        </title>
    </Head>
    <header class='header-detail'>
        <Main-nav :type='type'></Main-nav>
    </header>
    <main class='main order-status-page order-status-page' :class='type'>
        <section class='section section--pay-info'>
            <h2 class='section__heading'>
                {{ renderByStatus(
                    $t('TEXTS.order-success-status-title'),
                    $t('TEXTS.order-cancel-status-title'),
                    $t('TEXTS.order-unsuccess-status-title'))
                }}
            </h2>
            <p class='section__description'>
                {{ $t('TEXTS.pay-with-order-id') }} <span>{{ order_id }}</span>
                {{ renderByStatus(
                    $t('TEXTS.order-success-status-desc'),
                    $t('TEXTS.order-cancel-status-desc'),
                    $t('TEXTS.order-unsuccess-status-desc'))
                }}
            </p>
            <p class='section__description'>
                {{ $t('TEXTS.information-about-order') }}
                <a href='/uzivatel'>
                    {{ $t('TEXTS.order-completed-part-6') }}
                </a>
            </p>
            <a href='/' class='btn btn--standalone'>
                {{ $t('LABELS.to-trip-search') }}
            </a>
        </section>
    </main>
    <Footer></Footer>
</template>

<script>
import { Api } from '../modules/Api';

import { TYPE, LANGUAGE } from '@/env';

import Footer from '../components/Footer';
import MainNav from '../components/MainNav';

import { Head } from '@vueuse/head'

export default {
    props: ['order_id'],
    components: {
        Footer,
        MainNav,
        Head
    },
    data() {
        return {
            api: new Api(this.$t),
            LANGUAGE: LANGUAGE,
            type: TYPE === 'yachtas' ? 'yacht' : 'cruiser'
        };
    },
    methods: {
        renderByStatus(successValue, cancelValue, unsuccessValue) {
            const status = this.$route.query.status;
            return status === "SUCCESSFUL" ? successValue : status === "CANCEL" ? cancelValue : unsuccessValue;
        }
    }
};
</script>