<template>
    <section class="modal" :class="{'modal--show': isOpened, 'modal--just-mobile': justMobile, 'modal--just-tablet': justTablet}">
        <header :class="`modal__header modal__header--${ siteType }`">
            <button class="modal__return-btn" @click="closed">
                <svg class="modal__return-icon"><use href="/assets/imgs/icons/sprite.svg#simple-arrow"></use></svg>
                <span class="modal__return-text">{{ returnText }}</span>
            </button>
        </header>
        <main :class="`modal__content${contentClass ? ` ${contentClass}` : ''}`">
            <slot></slot>
        </main>
    </section>
</template>

<script>
export default {
    props: [ 'siteType', 'returnText', 'isOpened', 'contentClass', 'justMobile', 'justTablet' ],
    emits: [ 'closed' ],
    methods: {
        closed() {
            this.$emit("closed");
        }
    }
}
</script>