<template>
    <footer class="footer">
        <section class="footer__section footer__section--contact">
            <div class="footer__section-inner footer__section-inner--left">
                <h2 class="footer__heading-2">
                    {{ $t('TEXTS.call-and-hours') }}
                </h2>
                <h3 class="footer__heading-3">
                    <a
                        :href="`tel:${$globalContact[TYPE][LANGUAGE].phone}`"
                        class="footer__tel"
                        >{{ $globalContact[TYPE][LANGUAGE].phone }}</a
                    >
                </h3>
                <a
                    :href="`mailto:${$globalContact[TYPE][LANGUAGE].email}`"
                    class="footer__link footer__link--darker"
                    >{{ $globalContact[TYPE][LANGUAGE].email }}</a
                >
            </div>
        </section>
        <section class="footer__section footer__section--contact">
            <div class="footer__section-inner">
                <h2 class="footer__heading-2">
                    {{
                        TYPE === 'cruises'
                            ? $t('TEXTS.follow-cruises-on-socials')
                            : $t('TEXTS.follow-on-socials')
                    }}
                </h2>
                <div class="footer__socials">
                    <a
                        :href="$globalContact[TYPE][LANGUAGE].facebook"
                        class="footer__social"
                        target="_blank"
                    >
                        <svg class="footer__social-icon">
                            <use
                                href="/assets/imgs/icons/sprite.svg#facebook"
                            ></use>
                        </svg>
                    </a>
                    <a
                        :href="$globalContact[TYPE][LANGUAGE].instagram"
                        class="footer__social"
                        target="_blank"
                    >
                        <svg class="footer__social-icon">
                            <use
                                href="/assets/imgs/icons/sprite.svg#instagram"
                            ></use>
                        </svg>
                    </a>
                </div>
            </div>
        </section>
        <section class="footer__section footer__section--other-app">
            <div class="footer__section-inner">
                <other-app-dash></other-app-dash>
            </div>
        </section>
        <section
            class="footer__section footer__section--banner"
            v-if="existsBanner"
        >
            <Banner
                class="footer__banner"
                :type="banner.desktop.type"
                :type-mobile="banner.mobile.type"
                :source="banner.desktop.source"
                :source-mobile="banner.mobile.source"
                :url="banner.url"
            >
            </Banner>
        </section>
        <section class="footer__section footer__section--links">
            <div class="footer__links-container">
                <a
                    v-for="(item, i) in footerNav"
                    :key="i"
                    :href="item.url"
                    class="footer__link"
                    >{{ item.name[LANGUAGE] }}</a
                >
            </div>
        </section>
        <section class="footer__section footer__section--copyright">
            <p class="footer__copyright">
                © <span class="js-footer-copyright">2024</span>.
                <router-link to="/" class="footer__link"
                    >{{ TYPE === 'yachtas' ? 'yachtas' : 'nautyy' }}.{{
                        LANGUAGE === 'cs' ? 'cz' : 'sk'
                    }}</router-link
                >, - {{ $t('TEXTS.footer-note') }}.
            </p>
        </section>
    </footer>
</template>

<script>
import { TYPE, LANGUAGE } from '@/env';
import footerCruises from '@/storage/footer-cruises.json';

import Banner from '../components/Banner';
import OtherAppDash from '../components/OtherAppDash';
import { Api } from '../modules/Api';

export default {
    data() {
        return {
            api: new Api(this.$t),
            LANGUAGE: LANGUAGE,
            TYPE: TYPE,
            domain: location.hostname,
            banner: { url: '', mobile: {}, desktop: {} },
            footerNav: [],
            existsBanner: false,
        };
    },
    components: {
        Banner,
        OtherAppDash,
    },
    methods: {
        async loadBanner() {
            this.banner =
                (await this.api.getBanner(TYPE, LANGUAGE, 'footer')) ||
                this.banner;
            this.existsBanner =
                (this.banner.desktop.type || this.banner.mobile.type) &&
                (this.banner.desktop.source || this.banner.mobile.source);
        },
        async loadFooter() {
            if (TYPE === 'cruises') {
                this.footerNav = footerCruises;
            } else {
                this.footerNav = await this.api.getMenu(
                    TYPE,
                    LANGUAGE,
                    'footer'
                );
            }
        },
    },
    computed: {
        otherApp() {
            //! TMP change
            // return TYPE === 'cruises' ? 'yachtas' : 'cruises';
            return TYPE === 'cruises' ? 'cruises' : 'yachtas';
        },
    },
    mounted() {
        this.loadBanner();
        this.loadFooter();
    },
};
</script>
