<template>
    <div class="content-under-hero">
        <p v-html="text" class="content-under-hero__text"></p>
        <svg class="content-under-hero__arrow" v-if="showArrow">
            <use href="/assets/imgs/icons/sprite.svg#simple-arrow"></use>
        </svg>
    </div>
</template>
<script>
export default {
    props: {
        text:String,
        showArrow: {
            default: true,
            type: Boolean
        }
    }
}
</script>