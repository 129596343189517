import templatesRouter from './templates-router';

import CruisesPage from './pages/cruises/MainPage.vue';
import CruisesListPage from './pages/cruises/ListPage.vue';
import CruisesDetailPage from './pages/cruises/DetailPage.vue';
import CruisesReservationDonePage from './pages/cruises/ReservationDonePage.vue';
import CruisesReservationCabinPage from './pages/cruises/ReservationCabinPage.vue';
// import CruisesReservationOffersPage from './pages/cruises/ReservationOffersPage.vue'
import CruisesReservationSummaryPage from './pages/cruises/ReservationSummaryPage.vue';
import CruisesReservationPaymentPage from './pages/cruises/ReservationPaymentPage.vue';
import CruisesReservationPackagePage from './pages/cruises/ReservationPackagePage.vue';
import CruisesReservationTransportPage from './pages/cruises/ReservationTransportPage.vue';
import CruisesReservationNotFoundPage from './pages/cruises/ReservationNotFoundPage.vue';
import CruisesReservationNoCabinsPage from './pages/cruises/ReservationNoCabinsPage.vue';
import CruisesReservationNotLoggedPage from './pages/cruises/ReservationNotLoggedPage.vue';
import CruiserPage from './pages/cruises/CruiserPage.vue';
import CruisesForFirms from './pages/content/CruisesForFirms.vue';
import CruisesForGroups from './pages/content/CruisesForGroups.vue';
import CruisesContact from './pages/content/CruisesContact.vue';
import CruisesHowItWorksCosta from './pages/content/CruisesHowItWorksCosta.vue';
import CruisesHowItWorksMSC from './pages/content/CruisesHowItWorksMSC.vue';
import CruisesYacht from './pages/content/CruisesYacht.vue';
import CruisesCosta from './pages/content/CruisesCosta.vue';
import CruisesMSC from './pages/content/CruisesMSC.vue';
import CruisesYachtasCoffee from './pages/content/CruisesYachtasCoffee.vue';

// MSC
import CruisesMSCReservationPage from './pages/cruises-msc/ReservationPage.vue';

// Plavby s delegátem
import CruisesDelegateListPage from './pages/cruises-delegate/ListPage.vue';
import CruisesDelegateDetailPage from './pages/cruises-delegate/DetailPage.vue';
import CruisesDelegateOrderFormPage from './pages/cruises-delegate/OrderFormPage.vue';
import CruisesDelegateNotFoundPage from './pages/cruises-delegate/NotFoundPage.vue';
import CruisesDelegateOrderFormDone from './pages/cruises-delegate/OrderFormDone.vue';

// Yachtas
import YachtsPage from './pages/yachts/MainPage.vue';
import YachtsListPage from './pages/yachts/ListPage.vue';
import YachtsDetail from './pages/yachts/DetailPage.vue';
import YachtsReservationPaymentPage from './pages/yachts/ReservationPaymentPage.vue';
import YachtsReservationSinglePage from './pages/yachts/ReservationSinglePage.vue';
import YachtsReservationCabinsPage from './pages/yachts/ReservationCabinsPage.vue';
import YachtsReservationYachtsPage from './pages/yachts/ReservationYachtsPage.vue';
import YachtsReservationSummary from './pages/yachts/ReservationSummaryPage.vue';
import YachtsReservationDone from './pages/yachts/ReservationDonePage.vue';
import YachtsReservationNotFoundPage from './pages/yachts/ReservationNotFoundPage.vue';
import YachtsReservationNotLoggedPage from './pages/yachts/ReservationNotLoggedPage.vue';

import LoginPage from './pages/LoginPage.vue';
import RegistrationPage from './pages/RegistrationPage.vue';
import ForgottenPasswordPage from './pages/ForgottenPasswordPage.vue';
import RecoverPasswordPage from './pages/RecoverPasswordPage.vue';
import UserPage from './pages/UserPage.vue';
import AccountSettingsPage from './pages/AccountSettingsPage.vue';
import ContentPage from './pages/ContentPage.vue';
import OrderStatusPage from './pages/OrderStatusPage.vue';

const yachtasRoutes = [
  {
    path: '/',
    component: YachtsPage,
  },
  {
    path: '/order-status/:order_id',
    component: OrderStatusPage,
    props: true,
  },
  {
    path: '/vyhledat/',
    component: YachtsListPage,
  },
  {
    path: '/detail/:cruise_code/',
    component: YachtsDetail,
    props: true,
  },
  {
    path: '/rezervace/:cruise_code/platba/',
    component: YachtsReservationPaymentPage,
    props: true,
  },
  {
    path: '/rezervace/:cruise_code/rezervace-mist/',
    component: YachtsReservationSinglePage,
    props: true,
  },
  {
    path: '/rezervace/:cruise_code/rezervace-kajut/',
    component: YachtsReservationCabinsPage,
    props: true,
  },
  {
    path: '/rezervace/:cruise_code/rezervace-lodi/',
    component: YachtsReservationYachtsPage,
    props: true,
  },
  {
    path: '/rezervace/:cruise_code/shrnuti/',
    component: YachtsReservationSummary,
    props: true,
  },
  {
    path: '/rezervace/:cruise_code/dokoncena/',
    component: YachtsReservationDone,
  },
  {
    path: '/rezervace/:cruise_code/nenalezeno/',
    component: YachtsReservationNotFoundPage,
  },
  {
    path: '/rezervace/:cruise_code/neprihlaseny-uzivatel/',
    component: YachtsReservationNotLoggedPage,
    props: true,
  },
  {
    path: '/prihlaseni/',
    component: LoginPage,
  },
  {
    path: '/registrace/',
    component: RegistrationPage,
  },
  {
    path: '/zapomenute-heslo/',
    component: ForgottenPasswordPage,
  },
  {
    path: '/obnovit-heslo/:recovery_token/',
    component: RecoverPasswordPage,
    props: true,
  },
  {
    path: '/uzivatel/',
    component: UserPage,
  },
  {
    path: '/nastaveni-uctu/',
    component: AccountSettingsPage,
  },
  {
    path: '/kontent/:contentPageName/',
    component: ContentPage,
    props: true,
  },
  //TODO: Nezapomenout přidat do statických cest /src/storage/static-routes-%%.json
  {
    path: '/:notFound(.*)*',
    redirect: '/',
  },
  ...templatesRouter,
];

const cruisesRoutes = [
  {
    path: '/',
    component: CruisesPage,
  },
  {
    path: '/order-status/:order_id',
    component: OrderStatusPage,
    props: true,
  },
  {
    path: '/vyhledat/',
    component: CruisesListPage,
  },
  {
    path: '/detail/:cruise_code/',
    component: CruisesDetailPage,
    props: true,
  },
  {
    path: '/rezervace/:cruise_code/dokoncena/',
    component: CruisesReservationDonePage,
  },
  {
    path: '/rezervace/:cruise_code/kajuta/',
    component: CruisesReservationCabinPage,
  },
  // {
  //     path: '/rezervace/:cruise_code/dalsi-nabidky/',
  //     component: CruisesReservationOffersPage
  // },
  {
    path: '/rezervace/:cruise_code/shrnuti/',
    component: CruisesReservationSummaryPage,
    props: true,
  },
  {
    path: '/rezervace/:cruise_code/platba/',
    component: CruisesReservationPaymentPage,
  },
  {
    path: '/rezervace/:cruise_code/kajuta-a-ubytovaci-balicek/',
    component: CruisesReservationPackagePage,
  },
  {
    path: '/rezervace/:cruise_code/doprava/',
    component: CruisesReservationTransportPage,
  },
  {
    path: '/rezervace/:cruise_code/nenalezeno/',
    component: CruisesReservationNotFoundPage,
  },
  {
    path: '/rezervace/:cruise_code/bez-kajut/',
    component: CruisesReservationNoCabinsPage,
  },
  {
    path: '/rezervace/:cruise_code/neprihlaseny-uzivatel/',
    component: CruisesReservationNotLoggedPage,
    props: true,
  },
  {
    path: '/msc/rezervace/:cruise_code/',
    component: CruisesMSCReservationPage,
  },
  {
    path: '/prihlaseni/',
    component: LoginPage,
  },
  {
    path: '/registrace/',
    component: RegistrationPage,
  },
  {
    path: '/zapomenute-heslo/',
    component: ForgottenPasswordPage,
  },
  {
    path: '/obnovit-heslo/:recovery_token/',
    component: RecoverPasswordPage,
    props: true,
  },
  {
    path: '/uzivatel/',
    component: UserPage,
  },
  {
    path: '/nastaveni-uctu/',
    component: AccountSettingsPage,
  },
  {
    path: '/kontent/:contentPageName/',
    component: ContentPage,
    props: true,
  },
  {
    path: '/lod/:shipCode',
    component: CruiserPage,
    props: true,
  },
  {
    path: '/pro-firmy/',
    component: CruisesForFirms,
  },
  {
    path: '/pro-skupiny/',
    component: CruisesForGroups,
  },
  {
    path: '/kontakt/',
    component: CruisesContact,
  },
  {
    path: '/jak-to-funguje-costa/',
    component: CruisesHowItWorksCosta,
  },
  {
    path: '/jak-to-funguje-msc/',
    component: CruisesHowItWorksMSC,
  },
  {
    path: '/plavba-na-jachte/',
    component: CruisesYacht,
  },
  {
    path: '/costa-cruises/',
    component: CruisesCosta,
  },
  {
    path: '/msc-cruises/',
    component: CruisesMSC,
  },
  {
    path: '/yachtas-coffee/',
    component: CruisesYachtasCoffee,
  },
  // Plavby s delegátem
  {
    path: '/plavby-s-delegatem/',
    component: CruisesDelegateListPage,
  },
  {
    path: '/plavba-s-delegatem/:cruise_code/',
    component: CruisesDelegateDetailPage,
    props: true,
  },
  {
    path: '/plavba-s-delegatem-objednavka/:cruise_code/',
    component: CruisesDelegateOrderFormPage,
    props: true,
  },
  {
    path: '/plavba-s-delegatem/:cruise_code/nenalezeno/',
    component: CruisesDelegateNotFoundPage,
  },
  {
    path: '/plavba-s-delegatem-odeslano/',
    component: CruisesDelegateOrderFormDone,
  },
  //TODO: Nezapomenout přidat do statických cest /src/storage/static-routes-%%.json
  {
    path: '/:notFound(.*)*',
    redirect: '/',
  },
  ...templatesRouter,
];

export { cruisesRoutes, yachtasRoutes };
