<template>
    <div
        class="reservation-dash"
        :class="{
            'reservation-dash--wysiwyg': wysiwyg,
            'reservation-dash--w-yachtas': wysiwyg && TYPE === 'yachtas',
            'reservation-dash--w-cruises': wysiwyg && TYPE === 'cruises',
        }"
    >
        <slot></slot>
    </div>
</template>
<script>

import { TYPE } from '@/env';

export default {
    props: [ 'wysiwyg' ],
    data() {
        return {
            TYPE: TYPE,
        }
    }
}
</script>