<template>
    <router-view></router-view>

    <!-- COOKIES BAR -->
    <CookiesBar/>

</template>

<script>
    import CookiesBar from './components/CookiesBar';
    export default {
        components: {
            CookiesBar,
        },
    };

    window.addEventListener('load', () => {
        const preloadedElems = Array.from(document.querySelectorAll('.preload'));
        preloadedElems.forEach(elem => elem.classList.remove('preload'));
    });

</script>

<style lang="scss">
    @import './assets/sass/main.scss';
</style>