<template>
    <main class='main-search__main'>
        <div class='main-search__input-wrapper main-search__input-wrapper--yacht'>
            <svg
                class='main-search__input-icon main-search__input-icon--pinpoint main-search__input-icon--yacht'>
                <use href='/assets/imgs/icons/sprite.svg#pinpoint'></use>
            </svg>
            <label for='country' class='main-search__label'>{{ $t('LABELS.where-to') }}</label>
            <v-select
                class='main-search__select main-search__select--yacht'
                :options='langCountries'
                :reduce='country => country.code'
                label='langName'
                v-model='country'
            ></v-select>
        </div>
        <div class='main-search__input-wrapper main-search__input-wrapper--yacht'>
            <svg
                class='main-search__input-icon main-search__input-icon--calendar main-search__input-icon--yacht'>
                <use href='/assets/imgs/icons/sprite.svg#calendar'></use>
            </svg>
            <label for='start-date' class='main-search__label'>{{ $t('LABELS.date-from') }}</label>
            <input
                type='date'
                id='start-date'
                class='main-search__input main-search__input--clickable'
                v-model='startDate'
            >
        </div>
        <div class='main-search__input-wrapper main-search__input-wrapper--yacht'>
            <svg
                class='main-search__input-icon main-search__input-icon--calendar main-search__input-icon--yacht'>
                <use href='/assets/imgs/icons/sprite.svg#calendar'></use>
            </svg>
            <label for='end-date' class='main-search__label'>{{ $t('LABELS.date-to') }}</label>
            <input
                type='date'
                id='end-date'
                class='main-search__input main-search__input--clickable'
                v-model='endDate'
            >
        </div>
        <div class='main-search__input-wrapper main-search__input-wrapper--yacht'>
            <svg
                class='main-search__input-icon main-search__input-icon--sun main-search__input-icon--yacht'>
                <use href='/assets/imgs/icons/sprite.svg#sun'></use>
            </svg>
            <label for='crew-members' class='main-search__label'>{{ $t('LABELS.cruise-theme') }}</label>
            <v-select
                class='main-search__select main-search__select--yacht'
                :options='langYachtThemes'
                :reduce='theme => theme.code'
                label='langName'
                v-model='yachtTheme'
            ></v-select>
        </div>
        <button
            class='main-search__search-btn main-search__search-btn--yacht btn btn--standalone-small btn--blue'
            @click='search'>
            <svg class='main-search__search-icon'>
                <use href='/assets/imgs/icons/sprite.svg#search'></use>
            </svg>
            <span class='main-search__search-btn-label'>{{ $t('LABELS.search-cruise') }}</span>
        </button>
    </main>
</template>
<script>
import moment from 'moment';
import { LANGUAGE, TYPE } from '@/env';
import vSelect from 'vue-select';
import { Api } from '@/modules/Api';

export default {
    data() {
        return {
            api: new Api(this.$t),
            LANGUAGE: LANGUAGE,
            TYPE: TYPE,
            countries: [],
            country: this.$t('LABELS.anywhere'),
            transportIncluded: false,
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment(new Date(new Date().setYear(new Date().getFullYear() + 2))).format('YYYY-MM-DD'),
            yachtTheme: this.$t('LABELS.all-themes'),
            yachtThemes: [
                {
                    code: this.$t('LABELS.all-themes'),
                    added: true,
                    name: {
                        cs: this.$t('LABELS.all-themes'),
                        sk: this.$t('LABELS.all-themes')
                    }
                },
                {
                    code: 'exotic',
                    name: {
                        cs: this.$t('LABELS.exotic'),
                        sk: this.$t('LABELS.exotic')
                    }
                },
                {
                    code: 'fitness',
                    name: {
                        cs: this.$t('LABELS.fitness'),
                        sk: this.$t('LABELS.fitness')
                    }
                },
                {
                    code: 'lamour',
                    name: {
                        cs: this.$t('LABELS.lamour'),
                        sk: this.$t('LABELS.lamour')
                    }
                },
                {
                    code: 'adventurous',
                    name: {
                        cs: this.$t('LABELS.adventurous'),
                        sk: this.$t('LABELS.adventurous')
                    }
                },
                {
                    code: 'family-friendly',
                    name: {
                        cs: this.$t('LABELS.family-friendly'),
                        sk: this.$t('LABELS.family-friendly')
                    }
                },
                {
                    code: 'for-captains',
                    name: {
                        cs: this.$t('LABELS.for-captains'),
                        sk: this.$t('LABELS.for-captains')
                    }
                }
            ]
        };
    },
    computed: {
        langYachtThemes() {
            const langThemes = this.yachtThemes.map(theme => {
                theme.langName = theme.name[LANGUAGE];
                return theme;
            });

            return langThemes;
        },
        langCountries() {
            const langCountries = this.countries.map(country => {
                country.langName = country.name[LANGUAGE];
                return country;
            });

            return langCountries;
        }
    },
    methods: {
        search() {
            let searchHash = '#';

            searchHash += `countryCode=${this.country}&`;
            searchHash += `startDate=${this.startDate}&`;
            searchHash += `endDate=${this.endDate}&`;
            searchHash += `themeCode=${this.yachtTheme}&`;
            searchHash += `transportIncluded=${this.transportIncluded}`;

            window.location.replace(`/vyhledat/${searchHash}`)
            if(window.location.pathname !== "/") window.location.reload()
        },
        async loadCountries() {
            this.countries = await this.api.getYachtCountries();
        }
    },
    async mounted() {
        await Promise.all([
            this.loadCountries()
        ]);
    },
    watch: {
        countries(newVal) {
            this.country = newVal[0].name[LANGUAGE];
        }
    },
    components: {
        vSelect
    }
};
</script>