<template>
    <div  class="other-app-dash">
        <h2 class="footer__heading-2">{{ $t('TEXTS.subscribe-text') }}</h2>
        <form method="post" action="https://nautyy.ecomailapp.cz/public/subscribe/5/ed07fd6b07ff0199fabc8509f995f058">
            <div class='other-app-dash__input-wrapper'>
                <input type='email' id='subscribe-email' name='email' class='other-app-dash__input' :placeholder="$t('LABELS.email')" required>
            </div>
            <div class='other-app-dash__checkbox-agree'>
                <span class='other-app-dash__checkbox-agree__span'>{{ $t('TEXTS.agree-subscribe-form-with-gdpr') }} 
                    <router-link
                        :to='`/kontent/gdpr`'
                        class='other-app-dash__checkbox-agree__link'
                        target='_blank'
                    >
                    {{ $t('TEXTS.GDPR') }}
                    </router-link>
                </span>
            </div>
            <div class="other-app-dash__subscribe-buttons">
                <button class="btn--blue other-app-dash__subscribe-btn">
                    {{ $t('LABELS.subscribe') }}
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import { TYPE, LANGUAGE } from '@/env';

export default {
  data() {
    return {
      LANGUAGE: LANGUAGE,
      TYPE: TYPE,
    };
  }
};
</script>
