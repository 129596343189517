<template>
    <div class='yacht-reservation__part-section yacht-reservation__part-section--larger'>
        <p class='yacht-reservation__text yacht-reservation__text--large'>{{ title }}</p>
        <Counter :min='1' :max='freeUnits' :initial='bookedUnitsInit' class='yacht-reservation__counter'
                 @changed='onBookedUnitsChange' :new-val='forcedUnits'></Counter>
        <p class='yacht-reservation__empty-units'>{{ counterNote }}: <span>{{ freeUnits }}</span></p>

        <div class='person-form person-form--full person-form--larger-space' v-for='(passenger, index) of passengers'
             :key='passenger'>
            <h3 class='person-form__heading person-form__heading--yacht'>
                {{ passenger.type === 'responsible' ? $t('LABELS.responsible-person') : index + '. ' + $t('LABELS.passenger')
                }}
            </h3>
            <div class='person-form__inputs'>
                <div class='person-form__input-wrapper'>
                    <label for='first-name-2' class='person-form__label'>{{ $t('LABELS.first-name') }}</label>
                    <input type='text' id='first-name-2' name='first-name' class='person-form__input'
                           v-model='passenger.firstName' required>
                </div>
                <div class='person-form__input-wrapper'>
                    <label for='last-name-2' class='person-form__label'>{{ $t('LABELS.last-name') }}</label>
                    <input type='text' id='last-name-2' name='surname' class='person-form__input'
                           v-model='passenger.lastName' required>
                </div>
                <div class='person-form__input-wrapper' v-if='isPropertyExist("email", passenger)'>
                    <label for='passenger-email' class='person-form__label'>{{ $t('LABELS.email-address') }}</label>
                    <input type='text' id='passenger-email' name='email' class='person-form__input'
                           v-model='passenger.email' required>
                </div>
                <div class='person-form__phone-part' v-if='isPropertyExist("prefix", passenger)'>
                    <div class='person-form__input-wrapper'>
                        <label for='phone-prefix-1' class='person-form__label'>{{ $t('LABELS.phone-prefix') }}</label>
                        <input type='tel' id='phone-prefix-1' class='person-form__input person-form__input--small'
                               v-model='passenger.prefix' required maxlength='4' >
                    </div>
                    <div class='person-form__input-wrapper'>
                        <label for='phone-1' class='person-form__label'>{{ $t('LABELS.phone-number') }}</label>
                        <input type='tel' id='phone-1' class='person-form__input' v-model='passenger.phone' required>
                    </div>
                </div>
            </div>
            <div class='person-form__transport' v-if='transportIncluded'>
                <h4 class='person-form__transport__heading'>
                    {{ $t('TEXTS.non-binding-transport-calculation') }}
                </h4>
                <p class='person-form__transport__description'>
                    {{ $t('TEXTS.tour-with-transport') }}
                </p>
                <div class='person-form__transport__inputs'>
                    <label class='person-form__transport__input-option' :for='`transport-1_${index}`'
                           v-if='transports?.plane?.[LANGUAGE]'>
                        <input type='checkbox' class='person-form__transport__input' :name='`transport_${index}`'
                               :id='`transport-1_${index}`' v-model='passenger.transport.plane'>
                        <svg class='person-form__transport__icon person-form__transport__icon--small'>
                            <use href='/assets/imgs/icons/sprite.svg#plane'></use>
                        </svg>
                        {{ transports?.plane?.[LANGUAGE] }}
                    </label>
                    <label class='person-form__transport__input-option' :for='`transport-2_${index}`'
                           v-if='transports?.car?.[LANGUAGE]'>
                        <input type='checkbox' class='person-form__transport__input' :name='`transport_${index}`'
                               :id='`transport-2_${index}`' value='car' v-model='passenger.transport.car'>
                        <svg class='person-form__transport__icon'>
                            <use href='/assets/imgs/icons/sprite.svg#car-2'></use>
                        </svg>
                        {{ transports?.car?.[LANGUAGE] }}
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Counter from '../components/Counter';
import * as _ from 'lodash';
import { yachtasStore } from '@/utilities/store/yachtas';
import { getPhonePrefix } from '@/utilities/location';
import { isEmailValid, isPropertyInObject } from '@/utilities';

import { LANGUAGE } from '@/env';

export default {
    props: ['title', 'counterNote', 'freeUnits', 'transports', 'cruise_code', 'type'],
    emits: ['unitsChanged', 'passengersChanged', 'error'],
    data() {
        return {
            LANGUAGE: LANGUAGE,
            passengers: [],
            bookedUnits: this.parsedBookedUnits,
            forcedUnits: this.bookedUnits,
            phonePrefix: getPhonePrefix()
        };
    },
    computed: {
        transportIncluded() {
            return this.transports?.car?.[LANGUAGE] || this.transports?.plane?.[LANGUAGE];
        },
        bookedUnitsInit() {
            return this.parsedBookedUnits;
        },
        parsedBookedUnits() {
            return this.freeUnits === 0 ? 0 : (this.$route.query.amount && !isNaN(this.$route.query.amount) ? Number(this.$route.query.amount) : (this.$route.query.adultsNum && !isNaN(this.$route.query.adultsNum) ? Number(this.$route.query.adultsNum) : 1));
        }
    },
    methods: {
        loadLoggedUser() {
            if (!this.$store.user.isLogged) return;
            const { details } = this.$store.user;
            const phone = details.phone

            if(details.phone.includes("+")) {
                this.phonePrefix = phone.slice(0, 4)
                details.phone = phone.slice(4, phone.length)
            }

            const data = {
                firstName: details.firstName,
                lastName: details.lastName,
                phone: details.phone,
                email: details.email
            };

            this.passengers = yachtasStore.passengers.updateWithLoggedUser(this.passengers, data);
        },
        updatePassengers(units) {
            const passengersFromStore = yachtasStore.passengers.get(this.cruise_code);
            const multiplier = this.type === "cabin" ? 2 : 1

            if (units * multiplier === _.size(passengersFromStore)) {
                this.passengers = passengersFromStore;
                return this.loadLoggedUser();
            }

            this.passengers = _.times(units * multiplier, (index) => {
                if (index === 0) return yachtasStore.passengers.create('responsible');
                return yachtasStore.passengers.create();
            });
            this.loadLoggedUser();
        },
        onBookedUnitsChange(num) {
            this.bookedUnits = num;
            this.$emit('unitsChanged', num);

            this.updatePassengers(num);
            this.$router.replace({ query: { ...this.$route.query, amount: num } });
        },
        isPropertyExist(propertyName, targetObject) {
            return isPropertyInObject(propertyName, targetObject);
        },
        validateForm() {
            let errorMessage = '';
            let isValid = true;

            const irresponsibleReqFields = ['firstName', 'lastName'];
            const responsibleReqFields = [...irresponsibleReqFields, 'email', 'phone'];

            const validatePassengerInput = (passenger) => {
                const requiredFields = passenger.type === 'responsible' ? responsibleReqFields : irresponsibleReqFields;

                _.forEach(requiredFields, requiredField => {
                    const field = _.trim(passenger[requiredField]);

                    if (!field) {
                        isValid = false;
                        errorMessage = this.$t('ERRORS.fill-required');
                    }

                    if (requiredField === 'phone' && _.size(field) !== 9) {
                        isValid = false;
                        errorMessage = this.$t('ERRORS.wrong-phone-format');
                    }

                    if (requiredField === 'email' && !isEmailValid(field)) {
                        isValid = false;
                        errorMessage = this.$t('ERRORS.invalid-email-format');
                    }
                });
            };

            _.forEach(this.passengers, (passenger) => validatePassengerInput(passenger));

            return { isValid, errorMessage };
        }
    },
    watch: {
        freeUnits() {
            if (this.parsedBookedUnits > this.freeUnits) {
                this.onBookedUnitsChange(this.freeUnits);
                this.forcedUnits = this.freeUnits;
            }
        },
        passengers: {
            deep: true,
            handler() {
                yachtasStore.passengers.set(this.cruise_code, this.passengers);
                this.$emit('passengersChanged', this.validateForm());
            }
        }
    },
    mounted() {
        const units = this.parsedBookedUnits;
        this.updatePassengers(units);
        this.loadLoggedUser();
    },
    components: {
        Counter
    }
};
</script>