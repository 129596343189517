<template>
  <Head>
    <title>
      {{
        $t && $t('TITLES.cruise-detail', {
          cruiseName: details.departure_port_name?.[LANGUAGE],
        })
      }}
    </title>
    <meta
      name="description"
      :content="
        $t && $t('META-DESCRIPTIONS.cruise-detail', {
          cruiseName: details.departure_port_name?.[LANGUAGE],
        })
      "
    />
  </Head>

  <header class="header-detail" ref="header-detail">
    <Main-nav></Main-nav>
    <div class="breadcrumbs breadcrumbs--cruiser">
      <ul class="breadcrumbs__inner">
        <li class="breadcrumbs__item">
          <router-link to="/" class="breadcrumbs__link">
            nautyy .{{
              LANGUAGE === 'cs' ? 'cz' : LANGUAGE === 'sk' ? 'sk' : ''
            }}
          </router-link>
        </li>
        <li class="breadcrumbs__item">
          <router-link to="/vyhledat/" class="breadcrumbs__link"
            >{{ details.destination_name?.[LANGUAGE] }}
          </router-link>
        </li>
      </ul>
    </div>
  </header>
  <main
    class="main main--cruiser cruiser-detail-page"
    :style="`
            background-image: url(${details.image});
            background-attachment: fixed;
            background-size: 100%;
            background-repeat: no-repeat;
        `"
  >
    <StickyHeaderCruises :details="details" :url-crew-search="urlCrewSearch">
    </StickyHeaderCruises>
    <main class="cruiser-detail-page__main cruiser-detail">
      <div class="cruiser-detail__inner cruiser-detail-page__main-inner">
        <div class="cruiser-detail__column">
          <img
            :src="details.image"
            alt=""
            class="cruiser-detail__thumbnail-img"
          />
          <header class="cruiser-detail__header" ref="sticky-header-trigger">
            <div
              class="
                cruiser-detail__header-part cruiser-detail__header-part--wide
              "
            >
              <h1 class="cruiser-detail__heading-1">
                <span class="cruiser-detail__subheading-1">{{
                  details.destination_name?.[LANGUAGE]
                }}</span
                >{{ $t('LABELS.departure-from') }}
                {{ details.departure_port_name?.[LANGUAGE] }}
              </h1>
              <div class="cruiser-detail__cruiser-name-container">
                <svg class="cruiser-detail__cruiser-name-icon">
                  <use href="/assets/imgs/icons/sprite.svg#cruiser"></use>
                </svg>
                <p class="cruiser-detail__cruiser-type">
                  {{ $t('LABELS.costa-cruises') }}
                </p>
                <p class="cruiser-detail__cruiser-name">
                  {{ details.ship_name?.[LANGUAGE] }}
                </p>
              </div>
            </div>
            <div class="cruiser-detail__header-part">
              <h3 class="cruiser-detail__header-heading">
                {{ $t('LABELS.sail-date') }}
              </h3>
              <p class="cruiser-detail__header-text">
                <span class="cruiser-detail-page__arrow">→</span
                >{{ formatDate(details.departure_date) }}
              </p>
              <p class="cruiser-detail__header-text">
                <span class="cruiser-detail-page__arrow">←</span
                >{{ formatDate(details.arrival_date) }}
              </p>
            </div>
            <div class="cruiser-detail__header-part">
              <h3 class="cruiser-detail__header-heading">
                {{ $t('LABELS.total-time') }}
              </h3>
              <p class="cruiser-detail__header-text">
                {{ details.days }} {{ daysInflected(details.days) }}
              </p>
            </div>
            <!-- <div class="cruiser-detail__header-part">
                            <p class="cruiser-detail__header-text" v-if="details.transport_included">{{ $t('TEXTS.transport-posibility) }}<wbr> {{ $t('TEXTS.to-port-and-back) }}</p>
                            <p class="cruiser-detail__header-text" v-else>{{ $t('TEXTS.transport-to-port) }}<wbr> {{ $t('TEXTS.and-back-not-managed) }}</p>
                        </div> -->
            <div
              class="
                cruiser-detail__header-part cruiser-detail__header-part--price
              "
            >
              <router-link
                :to="`/rezervace/${details.cruise_code}/kajuta-a-ubytovaci-balicek/${urlCrewSearch}`"
                class="cruiser-detail__header-btn btn btn--orange"
                >{{ $t('LABELS.choose-cabin') }}
              </router-link>
              <div class="cruiser-detail__price-container">
                <p class="cruiser-detail__price-box">
                  <span class="cruiser-detail__price-label"
                    >{{ $t('TEXTS.price-for-single-with') }}<br />{{
                      $t('TEXTS.occupied-two-seats')
                    }}</span
                  >
                  <span class="cruiser-detail__price">{{ priceText }}</span>
                </p>
                <p class="cruiser-detail__second-price-text">
                  {{ $t('TEXTS.price-for-two-from') }} {{ secondPriceText }}
                </p>
              </div>
            </div>
          </header>
          <main class="cruiser-detail__main">
            <!-- <section class="cruiser-detail__section" v-if="details.transport_included">
                            <div class="cruiser-detail__note">
                                <svg class="cruiser-detail__note-icon cruiser-detail__note-icon--car">
                                    <use href="/assets/imgs/icons/sprite.svg#car"></use>
                                </svg>
                                <p class="cruiser-detail__note-text">{{ $t('TEXTS.transport-posibility-long) }}<strong> {{ $t('TEXTS.comfy-tranport-to-port) }}</strong></p>
                            </div>
                        </section> -->
            <section
              class="
                cruiser-detail__section cruiser-detail__itinerary-container
              "
            >
              <img
                :src="details.itineraryImgUrl"
                :alt="$t('LABELS.itinerary')"
                class="cruiser-detail__itinerary-img"
              />
              <h2 class="cruiser-detail__heading-2">
                {{ $t('LABELS.itinerary') }}
              </h2>
              <div class="cruiser-detail__itinerary">
                <div
                  class="cruiser-detail__itinerary-item"
                  v-for="(day, i) in this.details.itinerary_detail_steps"
                  :key="i"
                >
                  <div v-if="day.day_at_sea">
                    <h4 class="cruiser-detail__itinerary-day">
                      {{ `${day.first_day}. ` }}
                      {{
                        day.last_day !== undefined && day.first_day !== undefined && day.last_day !== day.first_day
                          ? ` - ${day.last_day}. `
                          : ''
                      }}
                      {{ $t('LABELS.day').toUpperCase() }}
                      {{ day.date }}
                    </h4>
                    <h3 class="cruiser-detail__itinerary-place">
                      {{ $t('LABELS.day-at-sea') }}
                    </h3>
                    <p class="cruiser-detail__day-at-sea-p">
                      <img src="/assets/imgs/day-at-sea.jpg" class="cruiser-detail__dat-at-sea-image">
                    </p>
                  </div>
                  <div v-else>
                    <h4 class="cruiser-detail__itinerary-day">
                      {{ day.arrival_day === undefined ? `${day.departure_day}. ` : `${day.arrival_day}. ` }}
                      {{
                        day.departure_day !== undefined && day.arrival_day !== undefined && day.departure_day !== day.arrival_day
                          ? ` - ${day.departure_day}.`
                          : ''
                      }}
                      {{ $t('LABELS.day').toUpperCase() }}
                      {{ ` (${day.date})` }}
                    </h4>
                    <h3 class="cruiser-detail__itinerary-place">
                      {{ day.departure_port_name === undefined ? day.arrival_port_name?.[LANGUAGE] : day.departure_port_name?.[LANGUAGE] }}
                    </h3>
                    <h5 class="cruiser-detail__itinerary-time">
                      {{ day.arrival_time !== undefined ? `${this.$t('LABELS.itinerary_arrival')}: ${day.arrival_time.slice(0, -3)}` : '' }}
                      {{ day.arrival_time !== undefined && day.departure_time !== undefined ? ` / ` : ''  }}
                      {{ day.departure_time !== undefined ? `${this.$t('LABELS.itinerary_departure')}: ${day.departure_time.slice(0, -3)}` : ''  }}
                    </h5>
                    <p
                      class="cruiser-detail__itinerary-text"
                      v-html="(day.departure_port_description === undefined ? day.arrival_port_description?.[LANGUAGE] : day.departure_port_description?.[LANGUAGE])"
                    ></p>
                  </div>
                </div>
              </div>
              <router-link
                :to="`/rezervace/${details.cruise_code}/kajuta-a-ubytovaci-balicek/${urlCrewSearch}`"
                class="cruiser-detail__btn btn btn--orange btn--standalone"
                >{{ $t('LABELS.choose-cabin') }}
              </router-link>
            </section>

            <section class="cruiser-detail__section cruiser-detail__video">
              <Banner
                class="cruiser-detail__banner"
                :type="banner.desktop.type"
                :source="banner.desktop.source"
                :url="banner.url"
              >
              </Banner>
              <h2 class="cruiser-detail__heading-2">
                {{ $t('LABELS.life-on-cruiser') }}
              </h2>
              <VideoPlayer
                video-url="/assets/videos/cruisers.mov"
                poster-url="/assets/imgs/posters/cruiser-tour.jpg"
              ></VideoPlayer>
              <router-link
                :to="`/rezervace/${details.cruise_code}/kajuta-a-ubytovaci-balicek/${urlCrewSearch}`"
                class="cruiser-detail__btn btn btn--orange btn--standalone"
                >{{ $t('LABELS.choose-cabin') }}
              </router-link>
            </section>
            <section
              class="cruiser-detail__section cruiser-detail__features-container"
            >
              <h2 class="cruiser-detail__heading-2">
                {{ $t('LABELS.travel-on-ship') }}
                {{ details.ship_name?.[LANGUAGE] }}
              </h2>
              <div class="cruiser-detail__description">
                <img
                  :src="shipInfo.img_url"
                  alt=""
                  class="cruiser-detail__description-img"
                  @click="showGallery(shipGalleryLightbox.data[0]?.id)"
                />
                <p
                  class="cruiser-detail__description-text"
                  v-html="shipInfo.description?.[LANGUAGE]"
                ></p>
                <div class="cruiser-detail__description-gallery">
                  <img
                    v-for="img in shipGalleryLightbox.data.slice(1)"
                    :key="img.id"
                    :src="img.src"
                    alt=""
                    class="cruiser-detail__description-img"
                    @click="showGallery(img.id)"
                  />
                </div>
                <Fancybox
                  :selected-id="shipGalleryLightbox.selectedId"
                  :data="shipGalleryLightbox.data"
                  :visible="shipGalleryLightbox.show"
                  @closed="() => (shipGalleryLightbox.show = false)"
                />
              </div>
              <router-link
                :to="`/lod/${shipInfo.code}/`"
                class="cruiser-detail__btn btn btn--orange btn--standalone"
              >
                {{ $t('LABELS.more-about-ship') }}
              </router-link>
            </section>
          </main>
        </div>
        <router-link
          :to="`/vyhledat/${urlCrewHash}`"
          class="
            cruiser-detail-page__back-container
            cruiser-detail-page__back-container--cruiser
          "
        >
          <svg
            class="
              cruiser-detail-page__back-icon
              cruiser-detail-page__back-icon--cruiser
            "
          >
            <use href="/assets/imgs/icons/sprite.svg#back-arrow"></use>
          </svg>
          {{ $t('LABELS.back-to-search') }}
        </router-link>
      </div>
    </main>
    <footer class="other-offers other-offers--cruiser">
      <div class="other-offers__inner">
        <h2 class="other-offers__heading other-offers__heading--cruiser">
          {{ $t('LABELS.other-cruises') }}
        </h2>
        <showcase-item-wide
          class="other-offers__item other-offers__item--wide"
          v-for="cruise in recommendedCruises"
          :key="cruise.cruise_id"
          type="cruiser"
          :duration="cruise.days"
          :transport-included="cruise.transport_included"
          :image-url="cruise.image"
          :departure-city="cruise.departure_port_name[LANGUAGE]"
          :destination="cruise.destination_name[LANGUAGE]"
          :itinerary="cruise.itinerary[LANGUAGE]"
          :departure-date="cruise.departure_date"
          :arrival-date="cruise.arrival_date"
          :price="cruise.prices.single[$store.currency]"
          :price-full="cruise.full_price_single[$store.currency]"
          :second-price="cruise.prices.cabin[$store.currency]"
          :second-price-label="$t('TEXTS.price-for-two-from')"
          :code="cruise.cruise_code"
          :cruiser-name="cruise.ship_name[LANGUAGE]"
          :force-html-link="true"
          :url-suffix="urlCrewSearch.slice(1)"
          :promo-name="cruise.promo_name"
        ></showcase-item-wide>
        <showcase-item
          class="other-offers__item"
          v-for="cruise in recommendedCruises"
          :key="cruise.cruise_id"
          type="cruiser"
          :duration="cruise.days"
          :transport-included="cruise.transport_included"
          :image-url="cruise.image"
          :departure-city="cruise.departure_port_name[LANGUAGE]"
          :destination="cruise.destination_name[LANGUAGE]"
          :itinerary="cruise.itinerary[LANGUAGE]"
          :departure-date="cruise.departure_date"
          :arrival-date="cruise.arrival_date"
          :price="cruise.prices.single[$store.currency]"
          :price-full="cruise.full_price_single[$store.currency]"
          :second-price="cruise.prices.cabin[$store.currency]"
          :second-price-label="$t('TEXTS.price-for-two-from')"
          :code="cruise.cruise_code"
          :cruiser-name="cruise.ship_name[LANGUAGE]"
          :force-html-link="true"
          :url-suffix="urlCrewSearch.slice(1)"
          :promo-name="cruise.promo_name"
        ></showcase-item>
      </div>
    </footer>
  </main>
  <Footer></Footer>
  <Loading
    :is-loading="isLoading"
    :label="$t('STATUSES.loading-trip-info')"
  ></Loading>
</template>

<script>
import Footer from '../../components/Footer';
import MainNav from '../../components/MainNav';
import ShowcaseItemWide from '../../components/ShowcaseItemWide';
import ShowcaseItem from '../../components/ShowcaseItem';
import VideoPlayer from '../../components/VideoPlayer';
import { Api } from '../../modules/Api';
import { Dictionary } from '../../modules/Dictionary';
import formatDateUtil from '../../utilities/formatDate';
import formatPriceUtil from '../../utilities/formatPrice';
import Loading from '../../components/Loading.vue';
import StickyHeaderCruises from '../../components/StickyHeaderCruise.vue';

import { TYPE, LANGUAGE } from '@/env';
import Banner from '../../components/Banner.vue';

import { Head } from '@vueuse/head';
import Fancybox from '@/components/Fancybox';
import { withGeneratedIds } from '@/utilities';

const dict = new Dictionary();

export default {
  props: ['cruise_code'],
  components: {
    Fancybox,
    Footer,
    MainNav,
    ShowcaseItemWide,
    ShowcaseItem,
    VideoPlayer,
    Loading,
    Banner,
    StickyHeaderCruises,
    Head,
  },
  data() {
    return {
      isLoading: false,
      api: new Api(this.$t),
      LANGUAGE: LANGUAGE,
      details: {},
      recommendedCruises: [],
      showStickyHeader: false,
      shipGalleryLightbox: {
        selectedId: '',
        show: false,
        data: [],
      },
      shipInfo: {},
      banner: { url: '', mobile: {}, desktop: {} },
    };
  },
  methods: {
    daysInflected(days_num) {
      return dict.getWord('days', days_num, LANGUAGE);
    },
    formatDate(date) {
      return formatDateUtil(date);
    },
    formatPrice(price) {
      return formatPriceUtil(price);
    },
    showGallery(selectedId) {
      this.shipGalleryLightbox.selectedId = selectedId;
      this.shipGalleryLightbox.show = true;
    },
    processItinerarySteps(departureDate, fullItinerary) {
      let itinerary = {};
      for (let step in fullItinerary) {
        let key = parseInt(step);
        let nextKey = key+1;
        let itineraryData = fullItinerary[key];
        if (typeof itinerary[key] === 'undefined') { itinerary[key] = {}; }

        let stepDate = new Date(departureDate);
        stepDate.setDate(stepDate.getDate() + (itineraryData.departure_day - 1));

        itinerary[key].departure_day = itineraryData.departure_day;
        itinerary[key].departure_time = itineraryData.departure_time || undefined;
        itinerary[key].departure_port_name = itineraryData.departure_port_name;
        itinerary[key].departure_port_description = itineraryData.departure_port_description;
        itinerary[key].date = `${stepDate.getDate()}. ${stepDate.getMonth() + 1}. ${stepDate.getFullYear()}`;
        itinerary[key].original_date = departureDate;

        if (nextKey <= fullItinerary.length) {
          if (typeof itinerary[nextKey] === 'undefined') { itinerary[nextKey] = {}; }
          itinerary[nextKey].arrival_day = itineraryData.arrival_day;
          itinerary[nextKey].arrival_time = itineraryData.arrival_time || undefined;

          if (nextKey == fullItinerary.length) {
            let arrivalDate = new Date(departureDate);
            arrivalDate.setDate(arrivalDate.getDate() + (itineraryData.arrival_day - 1));
            itinerary[nextKey].arrival_port_name = itineraryData.arrival_port_name;
            itinerary[nextKey].arrival_port_description = itineraryData.arrival_port_description;
            itinerary[nextKey].date = `${arrivalDate.getDate()}. ${arrivalDate.getMonth() + 1}. ${arrivalDate.getFullYear()}`;
            itinerary[nextKey].original_date = departureDate;
          }
        }
      }
      
      let itineraryFull = [];
      for (let x in itinerary) {
        let currentStep = itinerary[x];

        if (x > 0) {
          let previousStep = itinerary[x-1];
          if (
            typeof previousStep.departure_day !== 'undefined'
            && typeof currentStep.arrival_day !== 'undefined'
            && (currentStep.arrival_day - previousStep.departure_day) > 1 
          ) {
            let firstDay = previousStep.departure_day+1;
            let lastDay = currentStep.arrival_day-1
            let firstDate = new Date(previousStep.original_date);
            firstDate.setDate(firstDate.getDate() + (firstDay - 1));
            let lastDate = new Date(currentStep.original_date);
            lastDate.setDate(lastDate.getDate() + (lastDay - 1));
            itineraryFull.push({
              day_at_sea: true,
              first_day: firstDay,
              last_day: lastDay,
              date: firstDay === lastDay
                ? `(${firstDate.getDate()}. ${firstDate.getMonth() + 1}. ${firstDate.getFullYear()})`
                : `(${firstDate.getDate()}. ${firstDate.getMonth() + 1}. ${firstDate.getFullYear()} - ${lastDate.getDate()}. ${lastDate.getMonth() + 1}. ${lastDate.getFullYear()})`
            })
          }
        }
        itineraryFull.push(currentStep);
      }

      return itineraryFull;
    },
    async getshipInfo() {
      const ships = await this.api.getShips();

      const currentShip = ships.find(
        (ship) => ship.name[LANGUAGE] === this.details.ship_name[LANGUAGE]
      );
      console.log(currentShip);
      return currentShip ? currentShip : {};
    },
    setGalleryImages() {
      this.shipGalleryLightbox.data = withGeneratedIds([
        { src: this.shipInfo.img_url, type: 'image' },
        ...this.shipInfo.gallery.map((img) => ({
          src: img.url,
          type: 'image',
        })),
      ]).slice(0, 5);
    },
    async loadBanner() {
      this.banner =
        (await this.api.getBanner(TYPE, LANGUAGE, 'cruise')) || this.banner;
    },
    handleShownPrice() {
      this.details.singleForTwoPrice = JSON.parse(
        JSON.stringify(this.details.priceDouble)
      );
      Object.keys(this.details.singleForTwoPrice).forEach((currency) => {
        this.details.singleForTwoPrice[currency].amount_decimal /= 2;
        this.details.singleForTwoPrice[currency].amount_int /= 2;
      });
    },
  },
  async mounted() {
    this.isLoading = true;
    this.loadBanner();
    this.details = await this.api.getCruiseDetail(this.cruise_code);
    if (!this.details) {
      this.isLoading = false;
      this.$router.push(`/rezervace/${this.cruise_code}/nenalezeno/`);
    } else {
      this.details.itinerary_detail_steps =  this.processItinerarySteps(this.details.departure_date, this.details.full_itinerary);
    }
    this.shipInfo = await this.getshipInfo();
    this.recommendedCruises = await this.api.getShipCruises(this.shipInfo.code);
    console.log(this.recommendedCruises);
    this.setGalleryImages();
    this.isLoading = false;
    this.handleShownPrice();
  },
  computed: {
    priceText() {
      return `${this.formatPrice(
        this.details?.singleForTwoPrice?.[this.$store.currency].amount_decimal
      )} ${this.details?.singleForTwoPrice?.[this.$store.currency].currency}`;
    },
    secondPriceText() {
      return `${this.formatPrice(
        this.details?.priceDouble?.[this.$store.currency].amount_decimal
      )} ${this.details?.priceDouble?.[this.$store.currency].currency}`;
    },
    cruisePriceText() {
      return `${this.formatPrice(
        this.details.prices
          ? this.details.prices.single[this.$store.currency].amount_decimal
          : undefined
      )} ${
        this.details.prices
          ? this.details.prices.single[this.$store.currency].currency
          : undefined
      }`;
    },
    urlCrewSearch() {
      const query = this.$route.query;
      return `?adultsNum=${query.adultsNum || 2}&childrenNum=${
        query.childrenNum || 0
      }`;
    },
    urlCrewHash() {
      const query = this.$route.query;
      return `#adultsNum=${query.adultsNum || 2}&childrenNum=${
        query.childrenNum || 0
      }`;
    },
  },
  watch: {
    async $route(newRoute) {
      this.details = await this.api.getCruiseDetail(
        newRoute.params.cruise_code
      );

      if (!this.details && this.$route.path.startsWith('/detail/')) {
        this.$router.push(`/rezervace/${this.cruise_code}/nenalezeno/`);
      }
    },
  },
};
</script>
