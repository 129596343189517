<template>
    <section class="history-item">
        <header class="history-item__header" @click="toggleExtend">
            <div class="history-item__type">
                <svg
                    class="history-item__icon"
                    :class="{
                        'history-item__icon--yacht': type === 'yacht',
                        'history-item__icon--cruiser': type === 'cruiser'
                    }"
                >
                    <use :href="`/assets/imgs/icons/sprite.svg#${type}`"></use>
                </svg>
                {{ type === 'cruiser' ? $t('LABELS.cruiser') : (type === 'yacht' ? $t('LABELS.yacht') : '')}}
            </div>
            <div class="history-item__header-item history-item__header-item--mobile-hide">
                <h4 class="history-item__header-heading">{{ $t('LABELS.departure-from') }}</h4>
                <p class="history-item__header-text">{{ departureFrom }}</p>
            </div>
            <div class="history-item__header-item history-item__header-item--mobile-hide">
                <h4 class="history-item__header-heading" v-if="type === 'yacht'">{{ $t('LABELS.reservation-type') }}</h4>
                <h4 class="history-item__header-heading" v-else-if="type === 'cruiser'">{{ $t('LABELS.people-count') }}</h4>
                <p class="history-item__header-text">{{ amount ? `${amount}x ` : '' }}{{ reservationForText }}</p>
            </div>
            <div class="history-item__header-item">
                <h4 class="history-item__header-heading">{{ $t('LABELS.sail-date') }}</h4>
                <p class="history-item__header-text"><span class="nowrap"><span class="history-item__text-note">{{ $t('LABELS.from') }}</span> {{ formatDate(departureDate) }}</span> <span class="nowrap"><span class="history-item__text-note">{{ $t('LABELS.to') }}</span> {{ formatDate(arrivalDate) }}</span></p>
            </div>
            <div class="history-item__header-item">
                <h4 class="history-item__header-heading">{{ $t('LABELS.overall-price') }}</h4>
                <p class="history-item__header-text">{{ formatPrice(price.amount_decimal) }} {{ price.currency }}</p>
            </div>
            <div class="history-item__header-item">
                <h4 class="history-item__header-heading">{{ $t('LABELS.state') }}</h4>
                <p class="history-item__header-text history-item__header-text--deposit">{{ stateName }}</p>
            </div>
            <button class="btn btn--blue btn--standalone history-item__show-more-btn">{{ $t('LABELS.more-information') }}</button>
            <svg
                class="history-item__icon-show"
                :class="{'history-item__icon-show--opened': extend}"
            >
                <use href="/assets/imgs/icons/sprite.svg#simple-arrow"></use>
            </svg>
        </header>
        <main class="history-item__main" v-show="extend">
            <HistoryItemDetail
                :type="type"
                :passengers="passengers"
                :files="files"
                :dials="dials"
                :state="state"
                :paid="paid"
                :to-be-paid="toBePaid"
                :to-be-paid-date="toBePaidDate"
                :ticket-remaining='ticketRemaining'
                :ticket-deposit='ticketDeposit'
                :ticket-full='ticketFull'
            ></HistoryItemDetail>
        </main>
            <Modal
                :return-text="$t('LABELS.back-to-orders')"
                content-class="history-item__mobile-detail"
                :site-type="type"
                :is-opened="extend"
                :just-mobile="true"
                @closed="toggleExtend"
            >
                <HistoryItemDetail
                    :type="type"
                    :passengers="passengers"
                    :files="files"
                    :dials="dials"
                    :state="state"
                    :paid="paid"
                    :to-be-paid="toBePaid"
                    :to-be-paid-date="toBePaidDate"
                >
                    <div class="history-item__header">
                        <div class="history-item__type">
                            <svg
                                class="history-item__icon"
                                :class="{
                                    'history-item__icon--yacht': type === 'yacht',
                                    'history-item__icon--cruiser': type === 'cruiser'
                                }"
                            >
                                <use :href="`/assets/imgs/icons/sprite.svg#${type}`"></use>
                            </svg>
                            {{ type === 'cruiser' ? $t('LABELS.cruiser') : (type === 'yacht' ? $t('LABELS.yacht') : '')}}
                        </div>
                        <div class="history-item__header-item history-item__header-item--mobile-hide">
                            <h4 class="history-item__header-heading">{{ $t('LABELS.departure-from') }}</h4>
                            <p class="history-item__header-text">{{ departureFrom }}</p>
                        </div>
                        <div class="history-item__header-item history-item__header-item--mobile-hide">
                            <h4 class="history-item__header-heading" v-if="type === 'yacht'">{{ $t('LABELS.reservation-type') }}</h4>
                            <h4 class="history-item__header-heading" v-else-if="type === 'cruiser'">{{ $t('LABELS.people-count') }}</h4>
                            <p class="history-item__header-text">{{ amount ? `${amount}x ` : '' }}{{ reservationForText }}</p>
                        </div>
                        <div class="history-item__header-item">
                            <h4 class="history-item__header-heading">{{ $t('LABELS.sail-date') }}</h4>
                            <p class="history-item__header-text"><span class="nowrap"><span class="history-item__text-note">{{ $t('LABELS.from') }}</span> {{ formatDate(departureDate) }}</span> <span class="nowrap"><span class="history-item__text-note">{{ $t('LABELS.to') }}</span> {{ formatDate(arrivalDate) }}</span></p>
                        </div>
                        <div class="history-item__header-item">
                            <h4 class="history-item__header-heading">{{ $t('LABELS.overall-price') }}</h4>
                            <p class="history-item__header-text">{{ formatPrice(price.amount_decimal) }} {{ price.currency }}</p>
                        </div>
                        <div class="history-item__header-item">
                            <h4 class="history-item__header-heading">{{ $t('LABELS.state') }}</h4>
                            <p class="history-item__header-text history-item__header-text--deposit">{{ stateName }}</p>
                        </div>
                        <svg
                            class="history-item__icon-show"
                            :class="{'history-item__icon-show--opened': extend}"
                        >
                            <use href="/assets/imgs/icons/sprite.svg#simple-arrow"></use>
                        </svg>
                    </div>
                </HistoryItemDetail>
            </Modal>
    </section>
</template>

<script>
import Modal from './Modal.vue'
import HistoryItemDetail from './HistoryItemDetail.vue'
import { Dictionary } from '../modules/Dictionary';
import formatDateUtil from '../utilities/formatDate'
import formatPriceUtil from '../utilities/formatPrice'
import { LANGUAGE } from '@/env'

const dict = new Dictionary;

export default {
    props: [
        'type',
        'adults',
        'children',
        'departureDate',
        'price',
        'amount',
        'state',
        'stateName',
        'reservationType',
        'departureFrom',
        'arrivalDate',
        'passengers',
        'files',
        'dials',
        'paid',
        'toBePaid',
        'toBePaidDate',
        'ticketDeposit',
        'ticketFull',
        'ticketRemaining'
    ],
    components: {
        Modal,
        HistoryItemDetail
    },
    data() {
        return {
            extend: false,
        }
    },
    computed: {
        reservationForText() {
            if (this.type === 'cruiser') {
                return `${this.adults} ${dict.getWord('adults', this.adults, LANGUAGE)}, ${this.children} ${dict.getWord('children', this.children, LANGUAGE)}`
            } else if (this.type === 'yacht') {
                return this.reservationType
            }
            return ''
        }
    },
    methods: {
        toggleExtend() {
            this.extend = !this.extend;
        },
        formatPrice(price) {
            return formatPriceUtil(price)
        },
        formatDate(date) {
            return formatDateUtil(date)
        },
    },
    mounted() {
        
    },
    watch: {
        
    }
};
</script>